import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { ResolveResourcePipe } from "./resolve-resource.pipe";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ResolveResourcePipe],
  exports: [ResolveResourcePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ResolveResourcePipeModule {}
