import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as MeterActions from "./actions";

export const meterReducer = createReducer(
  initialState,

  on(
    MeterActions.create,
    MeterActions.loadAll,
    MeterActions.loadOne,
    MeterActions.update,
    MeterActions.updateCurrentValue,
    MeterActions.remove,
    state => ({ ...state, loading: true }),
  ),

  on(
    MeterActions.loadAllFailure,
    MeterActions.loadOneFailure,
    MeterActions.createFailure,
    MeterActions.updateFailure,
    MeterActions.updateCurrentValueFailure,
    MeterActions.removeFailure,
    state => ({ ...state, loading: false }),
  ),

  on(MeterActions.loadAllSuccess, (state, { meters }) => {
    const newMeters = {};
    meters.forEach(meter => (newMeters[meter._id] = meter));
    return {
      ...state,
      meters: newMeters,
      loading: false,
    };
  }),

  on(MeterActions.removeSuccess, (state, { id }) => {
    const meters = Object.values(state.meters).filter(meter => meter._id !== id);
    const newMeters = {};
    meters.forEach(meter => (newMeters[meter._id] = meter));

    return {
      ...state,
      meters: newMeters,
      loading: false,
    };
  }),

  on(MeterActions.createSuccess, MeterActions.loadOneSuccess, MeterActions.updateSuccess, (state, { meter }) => ({
    ...state,
    meters: {
      ...state.meters,
      [meter._id]: meter,
    },
    loading: false,
  })),

  on(MeterActions.updateCurrentValueSuccess, (state, { id, currentValue, lastReadDate }) => ({
    ...state,
    meters: {
      ...state.meters,
      [id]: { ...state.meters[id], currentValue, lastReadDate },
    },
    loading: false,
  })),
);
