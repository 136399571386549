import { Environment } from "src/app/interfaces/global.interface";

export const environment: Environment = {
  production: true,
  name: "production",
  api: "https://api.planm8.io",
  firebase: {
    apiKey: "AIzaSyDRkIyX2Ga7KYPd1NKYmrMZnHNAf0COJI4",
    authDomain: "planm8-app.firebaseapp.com",
    projectId: "planm8-app",
    storageBucket: "planm8-app.appspot.com",
    messagingSenderId: "93292559159",
    appId: "1:93292559159:web:e0b8c20421ca7d335e38b2",
    measurementId: "G-P0HQBDWJNZ",
  },
};
