import { createAction, props } from "@ngrx/store";
import { TaskEventDto, TaskHistoryControllerFilterByBoatRequestParams } from "src/app/services/api";

export interface TaskHistoryControllerFilterByBoatRequestParamsExtended
  extends Omit<TaskHistoryControllerFilterByBoatRequestParams, "boat"> {
  boat?: string;
}

export const loadHistory = createAction("[TaskEvent] Load Boat History", props<TaskHistoryControllerFilterByBoatRequestParamsExtended>());

export const loadHistorySuccess = createAction(
  "[TaskEvent] Load Boat History Success",
  props<{ taskEvents: TaskEventDto[]; hasNextPage: boolean; totalDocs: number }>(),
);

export const loadHistoryFailure = createAction("[TaskEvent] Load Boat History Failure");

export const emptyHistory = createAction("[TaskEvent] Empty History");

export const loadHistoryByComponent = createAction(
  "[TaskEvent] Load History By Component",
  props<{ componentId: string; page?: number }>(),
);

export const loadHistoryByComponentSuccess = createAction(
  "[TaskEvent] Load History By Component Success",
  props<{ taskEvents: TaskEventDto[] }>(),
);

export const loadHistoryByComponentFailure = createAction("[TaskEvent] Load History By Component Failure");

export const loadHistoryByTask = createAction("[TaskEvent] Load Task History", props<{ componentId: string; taskId: string }>());

export const loadHistoryByTaskSuccess = createAction("[TaskEvent] Load Task History Success", props<{ taskEvents: TaskEventDto[] }>());

export const loadHistoryByTaskFailure = createAction("[TaskEvent] Load Task History Failure");

export const setLoading = createAction("[TaskEvent] Set Loading", props<{ loading: boolean }>());
