import { createSelector } from "@ngrx/store";
import { ComponentDto } from "src/app/services/api";
import { RootState } from "..";
import { selectBoatId } from "../boat/selectors";
import { Components } from "./state";

export const selectLoading = (state: RootState) => state.component.loading;

export const selectSearchTerm = (state: RootState) => state.component.searchTerm;

export const selectFeature = (state: RootState) => state.component.components;

export const selectFeatureSearchComponents = (state: RootState) => state.component.searchComponents;

export const selectForkError = (state: RootState) => state.component.forkError;

export const selectAll = createSelector(selectFeature, selectBoatId, (components: Components, boatId: string) =>
  Object.values(components).filter(component => boatId === component.boat),
);

export const selectSearchComponents = createSelector(selectFeatureSearchComponents, (components: Components) => Object.values(components));

export const selectBySystems = createSelector(selectAll, (components: ComponentDto[], systems: string[]) =>
  components.filter(component => systems.includes(component.system)),
);

export const selectBySearch = createSelector(selectAll, selectSearchTerm, (components: ComponentDto[], searchTerm: string) => {
  const search = searchTerm.toLowerCase();
  return components.filter(
    component =>
      search === "" ||
      (component.name && component.name.toLowerCase().includes(search)) ||
      (component.description && component.description.toLowerCase().includes(search)) ||
      (component.manufacturer && component.manufacturer.toLowerCase().includes(search)) ||
      (component.model && component.model.toLowerCase().includes(search)),
  );
});

export const selectBySystemAndComponent = createSelector(
  selectAll,
  (components: ComponentDto[], params: { system: ComponentDto.SystemEnum; selectedComponentId: string }) =>
    components.filter(component => component.system === params.system && component._id !== params.selectedComponentId),
);

export const selectOne = createSelector(selectAll, (components: ComponentDto[], id: string) =>
  components.find(component => component._id === id),
);

export const selectOneSearchComponent = createSelector(selectSearchComponents, (components: ComponentDto[], id: string) =>
  components.find(component => component._id === id),
);

export const selectSearchHasNextPage = (state: RootState) => state.component.searchHasNextPage;

export const selectSearchTotalDocs = (state: RootState) => state.component.searchTotalDocs;

export const selectSearchPage = (state: RootState) => state.component.searchPage;
