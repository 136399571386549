import { createAction, props } from "@ngrx/store";
import {
  BoatDto,
  BoatUserDto,
  BoatUserRoleDto,
  BoatsControllerForkRequestParams,
  CreateBoatDto,
  InvitationDto,
  PortalDto,
  ReportFilters,
  SubscriptionDto,
  SubscriptionPreviewDto,
} from "src/app/services/api";

export interface BoatSubscription extends SubscriptionDto, PortalDto {}

export const create = createAction("[Boat] Create", props<{ boat: CreateBoatDto }>());

export const createSuccess = createAction("[Boat] Create Success", props<{ boat: BoatDto }>());

export const createFailure = createAction("[Boat] Create Failure");

export const createSuccessReset = createAction("[Boat] Create Success Reset");

export const loadAll = createAction("[Boat] Load All");

export const loadAllSuccess = createAction("[Boat] Load All Success", props<{ boats: BoatDto[] }>());

export const loadAllFailure = createAction("[Boat] Load All Failure");

export const loadOne = createAction("[Boat] Load One", props<{ id: string }>());

export const loadOneSuccess = createAction("[Boat] Load One Success", props<{ boat: BoatDto }>());

export const loadOneFailure = createAction("[Boat] Load One Failure");

export const seeMyNewBoat = createAction("[Boat] See My New Boat");

export const update = createAction("[Boat] Update", props<{ id: string; boat: BoatDto }>());

export const updateSuccess = createAction("[Boat] Update Success", props<{ boat: BoatDto }>());

export const updateFailure = createAction("[Boat] Update Failure");

export const remove = createAction("[Boat] Remove", props<{ boat: BoatDto }>());

export const removeSuccess = createAction("[Boat] Remove Success", props<{ id: string }>());

export const removeFailure = createAction("[Boat] Remove Failure", props<{ error: string }>());

export const setBoatId = createAction("[Boat] Set Boat ID", props<{ boatId: string }>());

export const refreshBoats = createAction("[Boat] Refresh Boats");

export const refreshBoatsFailure = createAction("[Boat] Refresh Boats Failure");

export const loadUsers = createAction("[Boat] Load Users");

export const loadUsersSuccess = createAction("[Boat] Load Users Success", props<{ users: BoatUserDto[] }>());

export const loadUsersFailure = createAction("[Boat] Load Users Failure");

export const inviteUser = createAction("[Boat] Invite User", props<{ invitationDto: InvitationDto }>());

export const inviteUserSuccess = createAction("[Boat] Invite User Success");

export const inviteUserFailure = createAction("[Boat] Invite User Failure", props<{ error: string }>());

export const removeUser = createAction("[Boat] Remove User", props<{ userId: string }>());

export const removeUserSuccess = createAction("[Boat] Remove User Success", props<{ userId: string }>());

export const removeUserFailure = createAction("[Boat] Remove User Failure", props<{ error: string }>());

export const updateUserRole = createAction("[Boat] Update User Role", props<{ userId: string; boatUserRoleDto: BoatUserRoleDto }>());

export const updateUserRoleSuccess = createAction("[Boat] Update User Role Success", props<{ user: BoatUserDto }>());

export const updateUserRoleFailure = createAction("[Boat] Update User Role Failure", props<{ error: string }>());

export const cleanInviteUserError = createAction("[Boat] Clean Invite User Error");

export const cleanRemoveUserError = createAction("[Boat] Clean Remove User Error");

export const setBoatIdWithRefresh = createAction("[Boat] Set Boat ID With Refresh", props<{ boatId: string }>());

export const setBoatIdWithRefreshSuccess = createAction("[Boat] Set Boat ID With Refresh Success", props<{ boatId: string }>());

export const setBoatIdWithRefreshFailure = createAction("[Boat] Set Boat ID With Refresh Failure");

export const getBoatSubscription = createAction("[Boat] Check Boat Subscription", props<{ event?: any }>());

export const getBoatSubscriptionSuccess = createAction(
  "[Boat] Check Boat Subscription Success",
  props<{ subscription: BoatSubscription; boatId: string; event?: any }>(),
);

export const getBoatSubscriptionFailure = createAction("[Boat] Check Boat Subscription Failure", props<{ error: string }>());

export const createBoatReport = createAction("[Boat] Create Boat Report", props<ReportFilters>());

export const createBoatReportSuccess = createAction("[Boat] Create Boat Report Success");

export const createBoatReportFailure = createAction("[Boat] Create Boat Report Failure");

export const forkBoat = createAction("[Boat] Fork Boat", props<BoatsControllerForkRequestParams>());

export const forkBoatSuccess = createAction("[Boat] Fork Boat Success", props<{ boat: BoatDto }>());

export const forkBoatSuccessReset = createAction("[Boat] Fork Boat Success Reset");

export const forkBoatFailure = createAction("[Boat] Fork Boat Failure");

export const fetchAvailableSubscriptions = createAction("[Boat] Fech Pricing Available", props<{ length: number }>());

export const fetchAvailableSubscriptionsSuccess = createAction(
  "[Boat] Fech Pricing Available Success",
  props<{ availableSubscriptions: SubscriptionPreviewDto[] }>(),
);

export const fetchAvailableSubscriptionsFailure = createAction("[Boat] Fech Pricing Available Failure");
