/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MeterDto } from './meter-dto';


export interface TriggerDto { 
    /**
     * Next trigger execution
     */
    nextExecution?: string | number;
    /**
     * Trigger execution recurrence.
     */
    recurrence?: string | number;
    /**
     * Meter ID. Required only for trigger wih type == METER
     */
    meter?: string | MeterDto;
    /**
     * Trigger ID.
     */
    _id?: string;
    /**
     * Boat ID
     */
    boat: string;
    /**
     * Trigger type.
     */
    type: TriggerDto.TypeEnum;
}
export namespace TriggerDto {
    export type TypeEnum = 'DATE' | 'METER';
    export const TypeEnum = {
        Date: 'DATE' as TypeEnum,
        Meter: 'METER' as TypeEnum
    };
}


