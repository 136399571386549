import { Pipe, PipeTransform } from "@angular/core";
import { ResourceDto } from "src/app/services/api";
import { STORAGE } from "src/app/constants";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import * as ResourceActions from "src/app/store/resource/actions";
import { Directory, Filesystem } from "@capacitor/filesystem";

@Pipe({ name: "resolveResource" })
export class ResolveResourcePipe implements PipeTransform {
  constructor(private store: Store<RootState>) {}

  public async transform(resource: ResourceDto): Promise<string> {
    return Filesystem.readFile({ path: `${STORAGE.DATA_FOLDER}/${resource._id}`, directory: Directory.Data })
      .then(file => `data:${resource.format};base64,${file.data}`)
      .catch(() => {
        this.store.dispatch(ResourceActions.cacheOne({ resource }));
        return resource.value;
      });
  }
}
