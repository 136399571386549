/* eslint-disable @typescript-eslint/indent */
import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { mergeMap, withLatestFrom } from "rxjs/operators";
import * as AppActions from "./actions";
import * as UserActions from "src/app/store/user/actions";
import localeEn from "@angular/common/locales/en-GB";
import localeEnNZ from "@angular/common/locales/en-NZ";
import localeEnUS from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import { DOCUMENT, registerLocaleData } from "@angular/common";
import { ThemeColorSchemeEnum } from "src/app/constants";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { RootState } from "..";
import * as PeriodicTaskActions from "src/app/store/periodic-task/actions";
import * as ComponentActions from "src/app/store/component/actions";
import * as MeterActions from "src/app/store/meter/actions";
import * as FromUser from "src/app/store/user/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { UserDto } from "src/app/services/api";
import { Platform } from "@ionic/angular";
import { StatusBar, Style } from "@capacitor/status-bar";
import { CrispService } from "src/app/services/crisp/crisp.service";

@Injectable()
export class AppEffects {
  public changeLanguage$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.changeLanguage),
      withLatestFrom(this.store.select(FromUser.selectUser)),
      withLatestFrom(this.store.select(FromApp.selectLanguage)),
      mergeMap(([[action, user], language]) => {
        const shouldReload = language !== action.language;

        switch (action.language) {
          case UserDto.LanguageEnum.En:
            registerLocaleData(localeEn);
            break;
          case UserDto.LanguageEnum.EnNz:
            registerLocaleData(localeEnNZ);
            break;
          case UserDto.LanguageEnum.EnUs:
            registerLocaleData(localeEnUS);
            break;
          case UserDto.LanguageEnum.Es:
            registerLocaleData(localeEs);
            break;
        }

        this.translateService.use(action.language);
        if (shouldReload) this.document.location.reload();

        return user
          ? [
              AppActions.changeLanguageSuccess({ language: action.language }),
              UserActions.updateUser({ user: { ...user, language: action.language, name: user.name || user.email } }),
            ]
          : [AppActions.changeLanguageSuccess({ language: action.language })];
      }),
    ),
  );

  public fetchOfflineData$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.fetchOfflineData),
      switchMap(() => [PeriodicTaskActions.loadAll(), ComponentActions.loadAll(), MeterActions.loadAll()]),
    ),
  );

  public setTheme$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.setTheme),
      map(({ theme }) => {
        switch (theme) {
          case ThemeColorSchemeEnum.auto:
            const preferColorScheme = this.document.defaultView.matchMedia("(prefers-color-scheme: dark)");
            this.toggleDarkTheme(preferColorScheme.matches);
            if (preferColorScheme?.addEventListener) {
              preferColorScheme?.addEventListener("change", e => this.toggleDarkTheme(!!e.matches));
            } else {
              this.toggleDarkTheme(false);
              this.store.dispatch(AppActions.setDarkModeCompatibility({ isCompatible: false }));
            }
            break;
          case ThemeColorSchemeEnum.dark:
            this.toggleDarkTheme();
            break;
          case ThemeColorSchemeEnum.light:
            this.toggleDarkTheme(false);
            break;
        }
        return AppActions.setThemeSuccess({ theme });
      }),
    ),
  );

  public openCrispChat$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.openCrispChat),
      map(() => {
        this.crispService.open();
        return AppActions.openCrispChatSuccess();
      }),
    ),
  );

  public openCrispChatSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.openCrispChatSuccess),
        tap(() => {
          this.store.dispatch(AppActions.setunreadCrispChatMesssages({ unreadCrispChatMesssages: 0 }));
          this.crispService.markAsRead();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly translateService: TranslateService,
    private readonly store: Store<RootState>,
    private readonly platform: Platform,
    private readonly crispService: CrispService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  private toggleDarkTheme(enable: boolean = true): void {
    if (enable) {
      this.document.body.classList.add("dark");
      if (this.platform.is("capacitor")) {
        if (this.platform.is("android")) StatusBar.setBackgroundColor({ color: "#383a3e" });
        StatusBar.setStyle({ style: Style.Dark });
      }
    } else {
      this.document.body.classList.remove("dark");
      if (this.platform.is("capacitor")) {
        if (this.platform.is("android")) StatusBar.setBackgroundColor({ color: "#fafafa" });
        StatusBar.setStyle({ style: Style.Light });
      }
    }
  }
}
