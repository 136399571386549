<ion-content class="help-slider__content">
  <swiper-container
    #swiper
    class="help-slider__content__slider"
    [pagination]="{ enabled: true }"
    (swiperslidechange)="handleSlideChange()"
    [style.backgroundPositionX.%]="(currentSlide +1) * 40 / (slideContents[slidesToShow]?.length || 1)"
  >
    <swiper-slide
      *ngFor="let slideContent of slideContents[slidesToShow]; let i = index;"
      class="help-slider__content__slider__slide"
      [class.--showed]="i === currentSlide"
    >
      <h3 class="help-slider__content__slider__slide__title" [innerText]="slideContent.title | translate"></h3>
      <div class="help-slider__content__slider__slide__image">
        <img [src]="slideContent.imageSrc" />
      </div>
      <p class="help-slider__content__slider__slide__description" [innerText]="slideContent.description  | translate"></p>
      <ion-button
        class="help-slider__content__slider__slide__button"
        *ngIf="slideContent.button"
        [innerHTML]="slideContent.button.text | translate"
        (click)="slideContent.button.handler()"
      ></ion-button>
    </swiper-slide>
  </swiper-container>
</ion-content>
<ion-button
  class="help-slider__content__float-button --skip"
  [innerHTML]="'HELP_SLIDER.skip' | translate"
  (click)="dismissModal()"
></ion-button>
<ion-button
  *ngIf="currentSlide + 1 < slideContents[slidesToShow]?.length"
  class="help-slider__content__float-button --next"
  [innerHTML]="'HELP_SLIDER.next' | translate"
  (click)="nextSlide()"
></ion-button>
