import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { PricingCardComponent } from "./pricing-card.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule],
  declarations: [PricingCardComponent],
  exports: [PricingCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PricingCardModule {}
