export const persistedKeys = ["taskNotes"];

export interface TaskNote {
  notes: string;
}

export interface TaskNotes {
  [id: string]: TaskNote;
}

export interface TaskNoteState {
  taskNotes: TaskNotes;
}

export const initialState: TaskNoteState = {
  taskNotes: {},
};
