import { Directive, OnInit, ElementRef, Renderer2, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subscription } from "rxjs";
import { autoUnsubscribe } from "src/app/decorators/unsubscribe/unsubscribe.decorator";
import { BoatUserDto, CurrentUserDto } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as FromBoat from "src/app/store/boat/selectors";
import * as FromUser from "src/app/store/user/selectors";

@autoUnsubscribe()
@Directive({ selector: "[appHideForRolesBelow]" })
export class HideForRolesBelowDirective implements OnInit {
  @Input("appHideForRolesBelow") public role: BoatUserDto.RolesEnum;

  protected $subscription: Subscription;

  private readonly userBoat$: Observable<BoatUserDto> = this.store.select(FromBoat.selectLoggedUser);
  private readonly user$: Observable<CurrentUserDto> = this.store.select(FromUser.selectUser);

  constructor(private renderer: Renderer2, private el: ElementRef, private store: Store<RootState>) {}

  public ngOnInit(): void {
    this.$subscription = combineLatest([this.userBoat$, this.user$]).subscribe(([userBoat, user]) => {
      if ((user?.adminRoles || []).includes(CurrentUserDto.AdminRolesEnum.Global)) {
        this.renderer.removeClass(this.el.nativeElement, "--role-hide");
      } else {
        if (!userBoat || this.isRoleBelow(userBoat.roles[0], this.role)) this.renderer.addClass(this.el.nativeElement, "--role-hide");
        else this.renderer.removeClass(this.el.nativeElement, "--role-hide");
      }
    });
  }

  private isRoleBelow(userRole: BoatUserDto.RolesEnum, inputRole: BoatUserDto.RolesEnum): boolean {
    if (inputRole === BoatUserDto.RolesEnum.Owner) return userRole !== BoatUserDto.RolesEnum.Owner;
    if (inputRole === BoatUserDto.RolesEnum.Operator) return userRole === BoatUserDto.RolesEnum.Viewer;
    if (inputRole === BoatUserDto.RolesEnum.Viewer) return userRole !== BoatUserDto.RolesEnum.Viewer;
  }
}
