import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { ResourceDto, ResourceService } from "src/app/services/api";
import { RootState } from "..";
import * as ResourceActions from "./actions";
import * as AppActions from "src/app/store/app/actions";
import * as FromResource from "./selectors";
import * as FromBoat from "src/app/store/boat/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { catchError, first, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { UtilsService } from "src/app/services/utils/utils.service";
import { HttpClient } from "@angular/common/http";
import { STORAGE, RESOURCES_TO_FETCH_PARALLEL } from "src/app/constants";
import { EnvService } from "src/app/services/env/env.service";
import { PosthogService } from "src/app/services/posthog/posthog.service";

@Injectable()
export class ResourceEffects {
  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.loadAll),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([, boatId]) =>
        this.resourceService.resourceControllerFindAll({ boat: boatId }).pipe(
          map(resources => ResourceActions.loadAllSuccess({ resources })),
          catchError(() => of(ResourceActions.loadAllFailure())),
        ),
      ),
    ),
  );

  public loadByComponent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.loadByComponent),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ componentId }, boatId]) =>
        this.resourceService.resourceControllerFindAll({ boat: boatId, reference: componentId }).pipe(
          map(resources => ResourceActions.loadByComponentSuccess({ resources })),
          catchError(() => of(ResourceActions.loadByComponentFailure())),
        ),
      ),
    ),
  );

  public loadByTaskExecution$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.loadByTaskExecution),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ eventTaskId }, boatId]) =>
        this.resourceService.resourceControllerFindAll({ boat: boatId, reference: eventTaskId }).pipe(
          map(resources => ResourceActions.loadByTaskExecutionSuccess({ resources })),
          catchError(() => of(ResourceActions.loadByTaskExecutionFailure())),
        ),
      ),
    ),
  );

  public loadBoatLogos$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.loadBoatLogos),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([, boatId]) =>
        this.resourceService.resourceControllerFindAll({ boat: boatId, tags: [ResourceDto.TagsEnum.Logo] }).pipe(
          map(resources => ResourceActions.loadBoatLogosSuccess({ resources })),
          catchError(() => of(ResourceActions.loadBoatLogosFailure())),
        ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.create),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.resourceService.resourceControllerCreate({ ...action.resource, boat: boatId }).pipe(
          map(resource => ResourceActions.createSuccess({ resource })),
          catchError(() => of(ResourceActions.createFailure())),
        ),
      ),
    ),
  );

  public createSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResourceActions.createSuccess),
        tap(({ resource: { resourceType } }) => this.posthogService.sendEvent("createResource", { type: "resource", resourceType })),
      ),
    { dispatch: false },
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.update),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.resourceService.resourceControllerUpdate({ ...action.resource, boat: boatId }).pipe(
          map(resource => ResourceActions.updateSuccess({ resource })),
          catchError(() => of(ResourceActions.updateFailure())),
        ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.remove),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ resource }, boatId]) =>
        this.resourceService.resourceControllerRemove({ boat: boatId, resource: resource._id }).pipe(
          map(() => ResourceActions.removeSuccess({ id: resource._id })),
          catchError(() => of(ResourceActions.removeFailure())),
        ),
      ),
    ),
  );

  public cacheAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.cacheAll),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      withLatestFrom(this.store.select(FromResource.selectAllByBoat)),
      mergeMap(([[, boatId], resources]) =>
        this.utilsService.createFolderIfNotExists().pipe(
          map(() => {
            this.fetchResources([...resources]);
            return AppActions.enableOfflineSupport({ boatId });
          }),
        ),
      ),
    ),
  );

  public cacheOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.cacheOne),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      withLatestFrom(this.envService.get()),
      mergeMap(([[{ resource }, boatId], environment]) =>
        this.http.get(`${environment.api}/api/v1/boats/${boatId}/resource/${resource._id}/resolve`, { responseType: "arraybuffer" }).pipe(
          mergeMap(arrayBuffer =>
            this.utilsService.blobToB64(new Blob([arrayBuffer], { type: resource.format })).pipe(
              mergeMap(data =>
                this.utilsService.writeFileToDisk(data, `${STORAGE.DATA_FOLDER}/${resource._id}`).pipe(
                  map(() =>
                    ResourceActions.cacheOneSuccess({
                      resource: {
                        _id: resource._id,
                        name: resource.name,
                        format: resource.format,
                      },
                    }),
                  ),
                  catchError(() => of(ResourceActions.cacheOneFailure())),
                ),
              ),
              catchError(() => of(ResourceActions.cacheOneFailure())),
            ),
          ),
          catchError(() => of(ResourceActions.cacheOneFailure())),
        ),
      ),
      catchError(() => of(ResourceActions.cacheOneFailure())),
    ),
  );

  public clearCache$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.clearCache),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      withLatestFrom(this.store.select(FromResource.selectAllCachedByBoat)),
      mergeMap(([[, boatId], resources]) => [
        ...resources.map(resource => ResourceActions.clearOne({ resource })),
        AppActions.disableOfflineSupport({ boatId }),
      ]),
    ),
  );

  public clearOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResourceActions.clearOne),
      mergeMap(({ resource }) =>
        this.utilsService.removeFileFromDisk(`${STORAGE.DATA_FOLDER}/${resource._id}`).pipe(
          map(() => ResourceActions.clearOneSuccess({ resource })),
          catchError(() => of(ResourceActions.clearOneFailure({ resource }))),
        ),
      ),
    ),
  );

  private resourcesToFetch: ResourceDto[] = [];

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly resourceService: ResourceService,
    private readonly utilsService: UtilsService,
    private readonly envService: EnvService,
    private readonly http: HttpClient,
    private readonly posthogService: PosthogService,
  ) {}

  private fetchResources(resources: ResourceDto[]): void {
    if (!!resources?.length) this.resourcesToFetch = resources;
    return this.resourcesToFetch.splice(0, RESOURCES_TO_FETCH_PARALLEL).forEach(this.fetchResource.bind(this));
  }

  private fetchResource({ _id, boat, format, name }: ResourceDto): void {
    this.envService
      .get()
      .pipe(first())
      .subscribe(environment =>
        this.http
          .get(`${environment.api}/api/v1/boats/${boat}/resource/${_id}/resolve`, { responseType: "arraybuffer" })
          .pipe(
            withLatestFrom(this.store.select(FromApp.selectOfflineSupportEnabled)),
            mergeMap(([arrayBuffer, offlineSupportEnabled]) => {
              if (offlineSupportEnabled.includes(boat)) {
                return this.utilsService.blobToB64(new Blob([arrayBuffer], { type: format })).pipe(
                  mergeMap(data =>
                    this.utilsService.writeFileToDisk(data, `${STORAGE.DATA_FOLDER}/${_id}`).pipe(
                      map(() => {
                        const nextResource = this.resourcesToFetch.pop();
                        if (nextResource) this.fetchResource(nextResource);
                        this.store.dispatch(
                          ResourceActions.cacheOneSuccess({
                            resource: {
                              _id,
                              name,
                              format,
                            },
                          }),
                        );
                      }),
                      catchError(() => of(ResourceActions.cacheOneFailure())),
                    ),
                  ),
                  catchError(() => of(ResourceActions.cacheOneFailure())),
                );
              }
              return of();
            }),
            catchError(() => of(ResourceActions.cacheOneFailure())),
          )
          .subscribe(),
      );
  }
}
