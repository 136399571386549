<div class="side-menu">
  <div class="side-menu__user">
    <div class="side-menu__user__avatar" [innerHTML]="avatar$ | async"></div>
    <div class="side-menu__user__info">
      <span class="side-menu__user__info__name" [innerHTML]="(user$ | async)?.name"></span>
      <span class="side-menu__user__info__mail" [innerHTML]="(user$ | async)?.email"></span>
      <ion-button
        appOfflineHide
        class="side-menu__user__info__edit"
        [innerHTML]="'SETTINGS.editProfile' | translate"
        [routerLink]="[routes.profile]"
      ></ion-button>
    </div>
  </div>

  <div class="side-menu__main">
    <app-side-menu-item
      icon="layers-outline"
      [text]="'COMPONENTS.title' | translate"
      [routerLink]="[routes.components]"
      [routerDirection]="'root'"
      [compressed]="true"
    >
    </app-side-menu-item>

    <app-side-menu-item
      icon="clipboard-outline"
      [text]="'PLANNING.title' | translate"
      [routerLink]="[routes.planning]"
      [routerDirection]="'root'"
      [compressed]="true"
    >
    </app-side-menu-item>
  </div>

  <div class="side-menu__links">
    <app-settings-item
      textColor="regular"
      buttonText="{{ 'SETTINGS.meters' | translate }}"
      iconName="speedometer-outline"
      [arrow]="false"
      [routerLink]="[routes.meters]"
      [compressed]="true"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      textColor="regular"
      buttonText="{{ 'SETTINGS.generalDocuments' | translate }}"
      iconName="document-attach-outline"
      [arrow]="false"
      [compressed]="true"
      [routerLink]="[routes.generalDocuments]"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      textColor="regular"
      buttonText="{{ 'SETTINGS.history' | translate }}"
      iconName="time-outline"
      [arrow]="false"
      [compressed]="true"
      [routerLink]="[routes.history]"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      textColor="regular"
      buttonText="{{ 'SETTINGS.offlineConfig' | translate }}"
      iconName="cloud-offline-outline"
      [arrow]="false"
      [compressed]="true"
      [routerLink]="[routes.offlineConfig]"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      textColor="regular"
      buttonText="{{ 'SETTINGS.userManagement' | translate }}"
      iconName="people-outline"
      [arrow]="false"
      [compressed]="true"
      appHideForRolesBelow="OPERATOR"
      [detail]="false"
      [routerLink]="[routes.userManagement]"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      textColor="regular"
      buttonText="{{ 'SETTINGS.generalConfig' | translate }}"
      iconName="cog-outline"
      [arrow]="false"
      [compressed]="true"
      [routerLink]="[routes.generalConfiguration]"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      appHideForRolesBelow="OWNER"
      [routerLink]="[routes.subscription]"
      textColor="regular"
      buttonText="{{ 'SETTINGS.subscription' | translate }}"
      iconName="card-outline"
      [arrow]="false"
      [compressed]="true"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      [routerLink]="[routes.report]"
      textColor="regular"
      buttonText="{{ 'SETTINGS.reports' | translate }}"
      iconName="document-text-outline"
      [arrow]="false"
      [compressed]="true"
      [routerDirection]="'root'"
    ></app-settings-item>

    <app-settings-item
      class="--spacer"
      (clickEvent)="selectBoat.emit()"
      textColor="regular"
      buttonText="{{ 'SETTINGS.switchYacht' | translate }}"
      iconName="swap-horizontal-outline"
      detailText="{{ (boatSelected$ | async)?.name }}"
      [disabled]="loadingBoats$ | async"
      [arrow]="false"
      [compressed]="true"
    >
    </app-settings-item>

    <app-settings-item
      *ngIf="user$ | async | isGobalAdminRole"
      (clickEvent)="deleteBoat.emit()"
      textColor="danger"
      buttonText="{{ 'SETTINGS.deleteYacht' | translate }}"
      iconName="remove-circle-outline"
      [arrow]="false"
      [compressed]="true"
    ></app-settings-item>

    <app-settings-item
      (clickEvent)="forkBoat.emit()"
      textColor="gray"
      buttonText="{{ 'SETTINGS.forkYacht' | translate }}"
      iconName="copy-outline"
      [arrow]="false"
      [compressed]="true"
    ></app-settings-item>

    <app-settings-item
      (clickEvent)="createBoat.emit()"
      textColor="gray"
      buttonText="{{ 'SETTINGS.newYacht' | translate }}"
      iconName="add-circle-outline"
      [arrow]="false"
      [compressed]="true"
    ></app-settings-item>

    <app-settings-item
      (clickEvent)="openSupport.emit()"
      class="--spacer"
      textColor="regular"
      buttonText="{{ 'SETTINGS.supportDocs' | translate }}"
      [detailText]="'SETTINGS.accessSupport' | translate"
      iconName="book-outline"
      [badgeNumber]="unreadMessages$ | async"
    ></app-settings-item>
    <app-settings-item
      (clickEvent)="openChat.emit()"
      textColor="regular"
      buttonText="{{ 'SETTINGS.chatWithUs' | translate }}"
      detailText="{{ 'SETTINGS.chatWithUsLive' | translate }}"
      [badgeNumber]="unreadMessages$ | async"
      iconName="chatbubble-outline"
    ></app-settings-item>

    <app-settings-item
      class="--spacer"
      (click)="logout.emit()"
      textColor="warning"
      buttonText="{{ 'SETTINGS.logout' | translate }}"
      [detailText]="environment$ | async | releasify"
      iconName="log-out-outline"
      [compressed]="true"
      [arrow]="false"
    ></app-settings-item>

    <img *ngIf="(logo$ | async)?.value" class="side-menu__links__logo" [src]="(logo$ | async)?.value" />
  </div>
</div>
