import { createAction, props } from "@ngrx/store";
import { ResourceControllerCreateRequestParams, ResourceControllerUpdateRequestParams, ResourceDto } from "src/app/services/api";
import { CachedResource, ResourceLoading } from "./state";

export const loadAll = createAction("[Resource] Load All");

export const loadAllSuccess = createAction("[Resource] Load All Success", props<{ resources: ResourceDto[] }>());

export const loadAllFailure = createAction("[Resource] Load All Failure");

export const loadByComponent = createAction("[Resource] Load By Component", props<{ componentId: string }>());

export const loadByComponentSuccess = createAction("[Resource] Load By Component Success", props<{ resources: ResourceDto[] }>());

export const loadByComponentFailure = createAction("[Resource] Load By Component Failure");

export const loadByTaskExecution = createAction("[Resource] Load By Task Execution", props<{ eventTaskId: string }>());

export const loadByTaskExecutionSuccess = createAction("[Resource] Load By Task Execution Success", props<{ resources: ResourceDto[] }>());

export const loadByTaskExecutionFailure = createAction("[Resource] Load By Task Execution Failure");

export const loadBoatLogos = createAction("[Resource] Load Boat Logos");

export const loadBoatLogosSuccess = createAction("[Resource] Load Boat Logos Success", props<{ resources: ResourceDto[] }>());

export const loadBoatLogosFailure = createAction("[Resource] Load Boat Logos Failure");

export const create = createAction("[Resource] Create", props<{ resource: ResourceControllerCreateRequestParams }>());

export const createSuccess = createAction("[Resource] Create Success", props<{ resource: ResourceDto }>());

export const createFailure = createAction("[Resource] Create Failure");

export const update = createAction("[Resource] Update", props<{ resource: ResourceControllerUpdateRequestParams }>());

export const updateSuccess = createAction("[Resource] Update Success", props<{ resource: ResourceDto }>());

export const updateFailure = createAction("[Resource] Update Failure");

export const bulkUpdateSuccess = createAction("[Resource] Bulk Update", props<{ resources: ResourceDto[] }>());

export const remove = createAction("[Resource] Delete", props<{ resource: ResourceDto }>());

export const removeSuccess = createAction("[Resource] Delete Success", props<{ id: string }>());

export const removeFailure = createAction("[Resource] Delete Failure");

export const setLoading = createAction("[Resource] Set Loading", props<Partial<ResourceLoading>>());

export const cacheAll = createAction("[Resource] Cache All");

export const cacheAllSuccess = createAction("[Resource] Cache All Success");

export const cacheAllFailure = createAction("[Resource] Cache All Failure");

export const cacheOne = createAction("[Resource] Cache One", props<{ resource: ResourceDto }>());

export const cacheOneSuccess = createAction("[Resource] Cache One Success", props<{ resource: CachedResource }>());

export const cacheOneFailure = createAction("[Resource] Cache One Failure");

export const clearCache = createAction("[Resource] Clear Cache");

export const clearOne = createAction("[Resource] Clear One", props<{ resource: CachedResource }>());

export const clearOneSuccess = createAction("[Resource] Clear One Success", props<{ resource: CachedResource }>());

export const clearOneFailure = createAction("[Resource] Clear One Failure", props<{ resource: CachedResource }>());
