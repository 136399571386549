import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { SharedRoutes } from "src/app/constants";
import { RootState } from "src/app/store";
import * as FromBoat from "src/app/store/boat/selectors";
import * as BoatActions from "src/app/store/boat/actions";
import { BoatDto, CreateBoatDto, SubscriptionPreviewDto } from "src/app/services/api";
import { SentryService } from "src/app/services/sentry/sentry.service";

@Component({
  selector: "app-fork-boat",
  templateUrl: "fork-boat.page.html",
  styleUrls: ["fork-boat.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForkBoatPage implements OnInit, OnDestroy {
  @Input() public boatToFork: BoatDto;

  public routes: typeof SharedRoutes = SharedRoutes;
  public boatForm: FormGroup;
  public loading$: Observable<boolean> = this.store.select(FromBoat.selectLoading);
  public success$: Observable<boolean> = this.store.select(FromBoat.selectForkBoatSuccess);
  public availableSubscriptions$: Observable<SubscriptionPreviewDto[]> = this.store.select(FromBoat.selectAvailableSubscriptions);
  public kindEnum: CreateBoatDto.KindEnum[] = Object.values(CreateBoatDto.KindEnum);
  public excludeEnum: ("IMAGE" | "FILE" | "URL" | "SERIALNO")[] = ["IMAGE", "FILE", "URL", "SERIALNO"];

  constructor(private store: Store<RootState>, private modalControler: ModalController, private sentry: SentryService) {}

  public ngOnInit(): void {
    this.boatForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      brand: new FormControl(null, [Validators.required]),
      model: new FormControl(null, [Validators.required]),
      kind: new FormControl(null, [Validators.required]),
      length: new FormControl(null, [Validators.required]),
      excludeResources: new FormControl([], [Validators.required]),
      ownerUserId: new FormControl(null, [Validators.required]),
      priceId: new FormControl("", [Validators.required]),
    });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(BoatActions.forkBoatSuccessReset());
  }

  public ionViewDidLeave(): void {
    this.boatForm.reset();
  }

  public forkBoat(): void {
    const { excludeResources, length, name, brand, model, ownerUserId, priceId, kind } = this.boatForm.value;
    this.store.dispatch(
      BoatActions.forkBoat({
        boat: this.boatToFork._id,
        forkBoatDto: { name, brand, model, ownerUserId, priceId, kind, length: Math.floor(length) },
        ignoreResourcesOfType: excludeResources,
      }),
    );
  }

  public fetchPricing(event: CustomEvent): void {
    const length = event.detail.value || 0;
    if (length > 0) this.store.dispatch(BoatActions.fetchAvailableSubscriptions({ length }));
  }

  public selectSubscription(priceId: string): void {
    this.boatForm.controls.priceId.patchValue(priceId);
  }

  public async dismissModal(): Promise<void> {
    try {
      this.boatForm.reset();
      const modal = await this.modalControler.getTop();
      if (!!modal) modal.dismiss();
    } catch (e) {
      this.sentry.handleError(e);
    }
  }
}
