import { MeterDto, PlannedTaskDto, TriggerDto } from "src/app/services/api";

export const persistedKeys = ["periodicTasks"];

export interface Pm8TriggerDto extends Omit<TriggerDto, "meter"> {
  meter?: MeterDto;
  task?: string;
}

export namespace Pm8TriggerDto {
  export type TypeEnum = TriggerDto.TypeEnum;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  export const TypeEnum = { ...TriggerDto.TypeEnum };
}

export interface Pm8PeriodicTaskDto extends Omit<PlannedTaskDto, "triggers"> {
  triggers: Pm8TriggerDto[];
}

export namespace Pm8PeriodicTaskDto {
  export type PriorityEnum = PlannedTaskDto.PriorityEnum;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  export const PriorityEnum = { ...PlannedTaskDto.PriorityEnum };
}

export interface PeriodicTasks {
  [id: string]: Pm8PeriodicTaskDto;
}

export interface PeriodicTaskState {
  periodicTasks: PeriodicTasks;
  getLoading: boolean;
  postLoading: boolean;
}

export const initialState: PeriodicTaskState = {
  periodicTasks: {},
  getLoading: false,
  postLoading: false,
};
