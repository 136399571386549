/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BoatDto { 
    _id?: string;
    /**
     * The boat name, must be unique
     */
    name: string;
    /**
     * Boat current timezone.
     */
    timezone?: string;
    /**
     * Flag to determine if this boat can be used as a template
     */
    template?: boolean;
    /**
     * The boat brand
     */
    brand?: string;
    /**
     * The boat model,
     */
    model?: string;
    /**
     * The boat type
     */
    kind?: BoatDto.KindEnum;
    /**
     * The boat length in meters
     */
    length: number;
    /**
     * Price ID from stripe
     */
    priceId?: string;
}
export namespace BoatDto {
    export type KindEnum = 'MOTOR_YACHT' | 'SAILING';
    export const KindEnum = {
        MotorYacht: 'MOTOR_YACHT' as KindEnum,
        Sailing: 'SAILING' as KindEnum
    };
}


