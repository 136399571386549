/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TaskDeclinedDto } from './task-declined-dto';
import { TaskDto } from './task-dto';
import { TaskCompletedDto } from './task-completed-dto';


export interface TaskEventDto { 
    /**
     * Event metadata
     */
    metadata?: TaskDto | TaskDeclinedDto | TaskCompletedDto;
    /**
     * Event ID
     */
    _id?: string;
    /**
     * Event type
     */
    eventType: TaskEventDto.EventTypeEnum;
    /**
     * Event creation date
     */
    createdAt: string;
    payload: TaskDto;
}
export namespace TaskEventDto {
    export type EventTypeEnum = 'TASK_CREATED' | 'TASK_FORKED' | 'TASK_UPDATED' | 'TASK_DELETED' | 'TASK_OVERDUE' | 'TASK_DUE' | 'TASK_COMPLETED' | 'TASK_DECLINED' | 'TASK_POSTPONED';
    export const EventTypeEnum = {
        Created: 'TASK_CREATED' as EventTypeEnum,
        Forked: 'TASK_FORKED' as EventTypeEnum,
        Updated: 'TASK_UPDATED' as EventTypeEnum,
        Deleted: 'TASK_DELETED' as EventTypeEnum,
        Overdue: 'TASK_OVERDUE' as EventTypeEnum,
        Due: 'TASK_DUE' as EventTypeEnum,
        Completed: 'TASK_COMPLETED' as EventTypeEnum,
        Declined: 'TASK_DECLINED' as EventTypeEnum,
        Postponed: 'TASK_POSTPONED' as EventTypeEnum
    };
}


