import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideMenuItemComponent } from "./side-menu-item.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [SideMenuItemComponent],
  imports: [CommonModule, IonicModule, TranslateModule, RouterModule],
  exports: [SideMenuItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SideMenuItemModule {}
