/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { TaskPageResult } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    TaskEventsServiceInterface,
    TaskHistoryControllerFilterRequestParams,
    TaskHistoryControllerFilterByBoatRequestParams,
    TaskHistoryControllerFilterByBoatComponentRequestParams,
    TaskHistoryControllerFilterByBoatComponentTaskRequestParams
} from './task-events.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class TaskEventsService implements TaskEventsServiceInterface {

    protected basePath = 'https://apistaging.planm8.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Browse and filter task events
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public taskHistoryControllerFilter(requestParameters: TaskHistoryControllerFilterRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TaskPageResult>;
    public taskHistoryControllerFilter(requestParameters: TaskHistoryControllerFilterRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TaskPageResult>>;
    public taskHistoryControllerFilter(requestParameters: TaskHistoryControllerFilterRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TaskPageResult>>;
    public taskHistoryControllerFilter(requestParameters: TaskHistoryControllerFilterRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const body = requestParameters.body;
        const sortByMostRecent = requestParameters.sortByMostRecent;
        const page = requestParameters.page;
        const limit = requestParameters.limit;
        const taskType = requestParameters.taskType;
        const system = requestParameters.system;
        const eventType = requestParameters.eventType;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sortByMostRecent !== undefined && sortByMostRecent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortByMostRecent, 'sortByMostRecent');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (taskType) {
            taskType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'taskType');
            })
        }
        if (system) {
            system.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'system');
            })
        }
        if (eventType) {
            eventType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'eventType');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TaskPageResult>(`${this.configuration.basePath}/api/v1/events/tasks`,
            body,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Browse and filter task events, grouped by boat
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public taskHistoryControllerFilterByBoat(requestParameters: TaskHistoryControllerFilterByBoatRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TaskPageResult>;
    public taskHistoryControllerFilterByBoat(requestParameters: TaskHistoryControllerFilterByBoatRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TaskPageResult>>;
    public taskHistoryControllerFilterByBoat(requestParameters: TaskHistoryControllerFilterByBoatRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TaskPageResult>>;
    public taskHistoryControllerFilterByBoat(requestParameters: TaskHistoryControllerFilterByBoatRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling taskHistoryControllerFilterByBoat.');
        }
        const sortByMostRecent = requestParameters.sortByMostRecent;
        const page = requestParameters.page;
        const limit = requestParameters.limit;
        const afterAt = requestParameters.afterAt;
        const beforeAt = requestParameters.beforeAt;
        const system = requestParameters.system;
        const taskType = requestParameters.taskType;
        const eventType = requestParameters.eventType;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sortByMostRecent !== undefined && sortByMostRecent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortByMostRecent, 'sortByMostRecent');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (afterAt !== undefined && afterAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>afterAt, 'afterAt');
        }
        if (beforeAt !== undefined && beforeAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>beforeAt, 'beforeAt');
        }
        if (system) {
            system.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'system');
            })
        }
        if (taskType) {
            taskType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'taskType');
            })
        }
        if (eventType) {
            eventType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'eventType');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TaskPageResult>(`${this.configuration.basePath}/api/v1/events/boats/${encodeURIComponent(String(boat))}/tasks`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Browse and filter task events, grouped by boat and component
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public taskHistoryControllerFilterByBoatComponent(requestParameters: TaskHistoryControllerFilterByBoatComponentRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TaskPageResult>;
    public taskHistoryControllerFilterByBoatComponent(requestParameters: TaskHistoryControllerFilterByBoatComponentRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TaskPageResult>>;
    public taskHistoryControllerFilterByBoatComponent(requestParameters: TaskHistoryControllerFilterByBoatComponentRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TaskPageResult>>;
    public taskHistoryControllerFilterByBoatComponent(requestParameters: TaskHistoryControllerFilterByBoatComponentRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling taskHistoryControllerFilterByBoatComponent.');
        }
        const component = requestParameters.component;
        if (component === null || component === undefined) {
            throw new Error('Required parameter component was null or undefined when calling taskHistoryControllerFilterByBoatComponent.');
        }
        const sortByMostRecent = requestParameters.sortByMostRecent;
        const page = requestParameters.page;
        const limit = requestParameters.limit;
        const eventType = requestParameters.eventType;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sortByMostRecent !== undefined && sortByMostRecent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortByMostRecent, 'sortByMostRecent');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (eventType) {
            eventType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'eventType');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TaskPageResult>(`${this.configuration.basePath}/api/v1/events/boats/${encodeURIComponent(String(boat))}/components/${encodeURIComponent(String(component))}/tasks`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Browse and filter task events, grouped by boat, component and task
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public taskHistoryControllerFilterByBoatComponentTask(requestParameters: TaskHistoryControllerFilterByBoatComponentTaskRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<TaskPageResult>;
    public taskHistoryControllerFilterByBoatComponentTask(requestParameters: TaskHistoryControllerFilterByBoatComponentTaskRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<TaskPageResult>>;
    public taskHistoryControllerFilterByBoatComponentTask(requestParameters: TaskHistoryControllerFilterByBoatComponentTaskRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<TaskPageResult>>;
    public taskHistoryControllerFilterByBoatComponentTask(requestParameters: TaskHistoryControllerFilterByBoatComponentTaskRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling taskHistoryControllerFilterByBoatComponentTask.');
        }
        const component = requestParameters.component;
        if (component === null || component === undefined) {
            throw new Error('Required parameter component was null or undefined when calling taskHistoryControllerFilterByBoatComponentTask.');
        }
        const task = requestParameters.task;
        if (task === null || task === undefined) {
            throw new Error('Required parameter task was null or undefined when calling taskHistoryControllerFilterByBoatComponentTask.');
        }
        const sortByMostRecent = requestParameters.sortByMostRecent;
        const page = requestParameters.page;
        const limit = requestParameters.limit;
        const eventType = requestParameters.eventType;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (sortByMostRecent !== undefined && sortByMostRecent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>sortByMostRecent, 'sortByMostRecent');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (eventType) {
            eventType.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'eventType');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<TaskPageResult>(`${this.configuration.basePath}/api/v1/events/boats/${encodeURIComponent(String(boat))}/components/${encodeURIComponent(String(component))}/tasks/${encodeURIComponent(String(task))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
