import { ResourceDto } from "src/app/services/api";

export const persistedKeys = ["resources", "cachedResources"];

export interface Resources {
  [id: string]: ResourceDto;
}

export interface CachedResource {
  _id: string;
  name: string;
  format: string;
}

export interface CachedResources {
  [id: string]: CachedResource;
}

export interface ResourceLoading {
  getDocuments: boolean;
  getImages: boolean;
  getLocation: boolean;
  postDocuments: boolean;
  postImages: boolean;
  postLocation: boolean;
}

export interface ResourceState {
  resources: Resources;
  loading: ResourceLoading;
  cachedResources: CachedResources;
}

export const initialState: ResourceState = {
  resources: {},
  loading: {
    getDocuments: false,
    getImages: false,
    getLocation: false,
    postDocuments: false,
    postImages: false,
    postLocation: false,
  },
  cachedResources: {},
};
