import { TaskCompletedDto, TaskEventDto } from "src/app/services/api";

export const persistedKeys = [];

export interface TaskEventCompletedDto extends Omit<TaskEventDto, "metadata"> {
  metadata: TaskCompletedDto;
}

export interface TaskEvents {
  [id: string]: TaskEventCompletedDto;
}

export interface TaskEventState {
  taskEvents: TaskEvents;
  page: number;
  totalDocs: number;
  hasNextPage: boolean;
  loading: boolean;
}

export const initialState: TaskEventState = {
  taskEvents: {},
  page: 1,
  totalDocs: undefined,
  hasNextPage: true,
  loading: false,
};
