import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "matchSearch",
})
export class MatchSearchPipe implements PipeTransform {
  public transform(value: string, searchTerm: string): string {
    if (searchTerm && searchTerm !== "") {
      return value ? value.toLowerCase().split(searchTerm.toLowerCase()).join(`<mark>${searchTerm}</mark>`) : value;
    } else {
      return value;
    }
  }
}
