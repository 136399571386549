import { CurrentUserDto } from "src/app/services/api";

export const persistedKeys = ["adminRoles", "user", "accessToken", "refreshToken", "error"];

export interface UserState {
  user: CurrentUserDto;
  accessToken: string;
  refreshToken: string;
  loading: boolean;
  error: string;
}

export const initialState: UserState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  loading: false,
  error: null,
};
