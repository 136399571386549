/* eslint-disable no-shadow */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, first, mergeMap, switchMap } from "rxjs/operators";
import { RootState } from "src/app/store";
import * as UserActions from "src/app/store/user/actions";
import * as FromUser from "src/app/store/user/selectors";
import * as FromBoat from "src/app/store/boat/selectors";
import { NoInterceptorRequests, NoInterceptorRoutes, SharedRoutes } from "src/app/constants";
import { AuthService, BoatUserDto } from "src/app/services/api";
import { SentryService } from "src/app/services/sentry/sentry.service";
import { NavController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  private refreshToken: string;

  constructor(
    private store: Store<RootState>,
    private authService: AuthService,
    private navController: NavController,
    private sentry: SentryService,
    private toastController: ToastController,
    private injector: Injector,
  ) {
    this.store.select(FromUser.selectRefreshToken).subscribe(token => (this.refreshToken = token));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(FromUser.selectAccessToken).pipe(
      first(),
      switchMap(token => {
        if (token) req = req.clone({ setHeaders: { authorization: `Bearer ${token}` } });
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 402) {
              this.handleSubscription(error);
            } else if (error.status !== 401) {
              this.showToast(error);
            } else if (
              error.status === 401 &&
              !NoInterceptorRoutes.some(route => req.url.includes(route)) &&
              !NoInterceptorRequests.some(request => req.url.includes(request))
            ) {
              if (!!this.refreshToken) {
                return this.authService.authControllerRefresh({ refreshDto: { refreshToken: this.refreshToken } }).pipe(
                  mergeMap(tokens => {
                    this.store.dispatch(UserActions.refreshSuccess({ tokens }));
                    this.store.dispatch(UserActions.loadUser());
                    return next.handle(req.clone({ setHeaders: { authorization: `Bearer ${tokens.accessToken}` } }));
                  }),
                  catchError((e: any) => {
                    this.sentry.handleError(e);
                    return throwError(e);
                  }),
                );
              }
              return throwError(error);
            }
            return throwError(error);
          }),
        );
      }),
      catchError(error => {
        if (error.status === 401 && !req.url.endsWith(SharedRoutes.login)) {
          this.store.dispatch(UserActions.logout({ shouldNavigate: true }));
        }
        return throwError(error);
      }),
    );
  }

  private handleSubscription(error: HttpErrorResponse): void {
    this.store.select(FromBoat.selectLoggedUser).pipe(first()).subscribe(user => {
      if (user.roles.includes(BoatUserDto.RolesEnum.Owner)) this.navController.navigateRoot(SharedRoutes.subscription);
      else this.showToast(error);
    });
  }

  private async showToast(error: HttpErrorResponse): Promise<void> {
    const t = this.injector.get(TranslateService);
    if (error.status >= 500) this.sentry.handleError(error);
    const toast = await this.toastController.create({
      position: "top",
      color: "light",
      translucent: true,
      header: t.instant("WARNING"),
      message:
        error.status >= 500
          ? t.instant("SERVER_ERROR")
          : t.instant(error.error?.i18n?.toString() || error.error?.message?.toString() || "SOMETHING_WENT_WRONG"),
      duration: 5000,
      buttons: [
        {
          side: "end",
          text: t.instant("ok"),
        },
      ],
    });
    return toast.present();
  }
}
