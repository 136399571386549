import { createSelector } from "@ngrx/store";
import { ResourceDto } from "src/app/services/api";
import { RootState } from "..";
import { CachedResource, CachedResources, Resources } from "./state";
import { selectBoatId } from "src/app/store/boat/selectors";
import { selectOfflineSupportEnabledByBoat } from "src/app/store/app/selectors";
import { ThemeColorSchemeEnum } from "src/app/constants";

export const DARK_MODE = "darkMode";

function sortById(a: ResourceDto, b: ResourceDto): number {
  if (a._id < b._id) return 1;
  if (a._id > b._id) return -1;
  return 0;
}

export const selectLoading = (state: RootState) => state.resource.loading;

export const selectFeature = (state: RootState) => state.resource.resources;

export const selectCached = (state: RootState) => state.resource.cachedResources;

export const selectAllByBoat = createSelector(selectFeature, selectBoatId, (resources: Resources, boatId: string) =>
  Object.values(resources).filter(resource => resource.boat === boatId),
);

export const selectAllCachedByBoat = createSelector(selectAllByBoat, selectCached, (resources: ResourceDto[], cached: CachedResources) =>
  resources.map(resource => cached[resource._id]).filter(Boolean),
);

export const selectPercent = createSelector(
  selectAllByBoat,
  selectAllCachedByBoat,
  (resources: ResourceDto[], cached: CachedResource[]) =>
    +((Object.values(cached).length * 100) / Object.values(resources).length).toFixed() || 0,
);

export const selectCaching = createSelector(
  selectAllByBoat,
  selectAllCachedByBoat,
  selectOfflineSupportEnabledByBoat,
  (resources: ResourceDto[], cached: CachedResource[], offlineEnabled: boolean) =>
    offlineEnabled && Object.values(cached).length !== Object.values(resources).length,
);

export const selectBoatGeneralDocuments = createSelector(selectAllByBoat, selectBoatId, (resources: ResourceDto[], boatId: string) =>
  resources.filter(
    resource =>
      resource.reference === boatId &&
      resource.referenceType === ResourceDto.ReferenceTypeEnum.Boat &&
      resource.tags.includes(ResourceDto.TagsEnum.Attachment),
  ),
);

export const selectLocationImagesByComponent = createSelector(selectAllByBoat, (resources: ResourceDto[], componentId: string) =>
  resources
    .filter(
      resource =>
        resource.reference === componentId &&
        resource.resourceType === ResourceDto.ResourceTypeEnum.Image &&
        resource.referenceType === ResourceDto.ReferenceTypeEnum.Component &&
        resource.tags.includes(ResourceDto.TagsEnum.Location),
    )
    .sort(sortById),
);

export const selectImagesByComponent = createSelector(selectAllByBoat, (resources: ResourceDto[], componentId: string) =>
  resources
    .filter(
      resource =>
        resource.reference === componentId &&
        resource.resourceType === ResourceDto.ResourceTypeEnum.Image &&
        resource.referenceType === ResourceDto.ReferenceTypeEnum.Component &&
        resource.tags.includes(ResourceDto.TagsEnum.Attachment),
    )
    .sort(sortById),
);

export const selectDocumentsByComponent = createSelector(selectAllByBoat, (resources: ResourceDto[], componentId: string) =>
  resources
    .filter(
      resource =>
        resource.reference === componentId &&
        resource.resourceType === ResourceDto.ResourceTypeEnum.File &&
        resource.referenceType === ResourceDto.ReferenceTypeEnum.Component &&
        resource.tags.includes(ResourceDto.TagsEnum.Attachment),
    )
    .sort(sortById),
);

export const selectDocumentsByTaskExecutionPending = createSelector(selectAllByBoat, (resources: ResourceDto[], taskId: string) =>
  resources
    .filter(
      resource =>
        resource.reference === taskId &&
        resource.resourceType === ResourceDto.ResourceTypeEnum.File &&
        resource.referenceType === ResourceDto.ReferenceTypeEnum.Task &&
        resource.tags.includes(ResourceDto.TagsEnum.Async) &&
        resource.tags.includes(ResourceDto.TagsEnum.Attachment),
    )
    .sort(sortById),
);

export const selectDocumentsByUPM = createSelector(selectAllByBoat, (resources: ResourceDto[], componentId: string) =>
  resources
    .filter(
      resource =>
        resource.reference === componentId &&
        resource.resourceType === ResourceDto.ResourceTypeEnum.File &&
        resource.referenceType === ResourceDto.ReferenceTypeEnum.Component &&
        resource.tags.includes(ResourceDto.TagsEnum.Async) &&
        resource.tags.includes(ResourceDto.TagsEnum.Attachment),
    )
    .sort(sortById),
);

export const selectDocumentsByTaskExecution = createSelector(selectAllByBoat, (resources: ResourceDto[], eventId: string) =>
  resources
    .filter(
      resource =>
        resource.reference === eventId &&
        resource.resourceType === ResourceDto.ResourceTypeEnum.File &&
        resource.tags.includes(ResourceDto.TagsEnum.Attachment),
    )
    .sort(sortById),
);

export const selectResourcesByTaskExecution = createSelector(selectAllByBoat, (resources: ResourceDto[], eventId: string) =>
  resources.filter(resource => resource.reference === eventId).sort(sortById),
);

export const selectLogo = createSelector(selectAllByBoat, (resources: ResourceDto[], theme: ThemeColorSchemeEnum) => {
  let logo: ResourceDto;

  if (theme === ThemeColorSchemeEnum.dark) {
    logo = resources.find(r => r.tags.some(t => t === ResourceDto.TagsEnum.Logo) && r.metadata && r.metadata[DARK_MODE]);
  } else {
    logo = resources.find(r => r.tags.some(t => t === ResourceDto.TagsEnum.Logo) && r.metadata && !r.metadata[DARK_MODE]);
  }

  return logo || resources.find(r => r.tags.some(t => t === ResourceDto.TagsEnum.Logo));
});
