import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "areArrayEquals",
})
export class AreArrayEqualsPipe implements PipeTransform {
  public transform(a: string[], b: string[]): boolean {
    return !!a && !!b && a.length === b.length && a.every(v => b.includes(v));
  }
}
