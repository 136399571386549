import { createReducer, on } from "@ngrx/store";
import { BoatState, initialState } from "./state";
import * as BoatActions from "./actions";

export const boatReducer = createReducer(
  initialState,

  on(
    BoatActions.create,
    BoatActions.loadAll,
    BoatActions.loadOne,
    BoatActions.update,
    BoatActions.remove,
    BoatActions.refreshBoats,
    BoatActions.loadUsers,
    BoatActions.inviteUser,
    BoatActions.removeUser,
    BoatActions.updateUserRole,
    BoatActions.setBoatIdWithRefresh,
    BoatActions.getBoatSubscription,
    BoatActions.createBoatReport,
    BoatActions.fetchAvailableSubscriptions,
    state => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    BoatActions.loadAllFailure,
    BoatActions.loadOneFailure,
    BoatActions.updateFailure,
    BoatActions.createFailure,
    BoatActions.removeFailure,
    BoatActions.refreshBoatsFailure,
    BoatActions.loadUsersFailure,
    BoatActions.inviteUserSuccess,
    BoatActions.setBoatIdWithRefreshFailure,
    BoatActions.getBoatSubscriptionFailure,
    BoatActions.createBoatReportSuccess,
    BoatActions.createBoatReportFailure,
    BoatActions.fetchAvailableSubscriptionsFailure,
    state => ({ ...state, loading: false }),
  ),

  on(BoatActions.inviteUserFailure, (state, { error }) => ({ ...state, loading: false, inviteUserError: error })),

  on(BoatActions.updateUserRoleFailure, (state, { error }) => ({ ...state, loading: false, updateUserRoleError: error })),

  on(BoatActions.loadAllSuccess, (state, { boats }) => {
    const newBoats = {};
    boats.forEach(boat => (newBoats[boat._id] = boat));
    return {
      ...state,
      boats: newBoats,
      loading: false,
    };
  }),

  on(BoatActions.removeSuccess, (state: BoatState, { id }) => {
    const boats = Object.values(state.boats).filter(boat => boat._id !== id);
    const newBoats = {};
    boats.forEach(boat => (newBoats[boat._id] = boat));

    return {
      ...state,
      boats: newBoats,
      loading: false,
    };
  }),

  on(BoatActions.loadOneSuccess, BoatActions.updateSuccess, (state, { boat }) => ({
    ...state,
    boats: {
      ...state.boats,
      [boat._id]: boat,
    },
    loading: false,
  })),

  on(BoatActions.createSuccess, (state, { boat }) => ({
    ...state,
    boats: {
      ...state.boats,
      [boat._id]: boat,
    },
    createBoatSuccess: true,
    loading: false,
  })),

  on(BoatActions.createSuccessReset, state => ({
    ...state,
    createBoatSuccess: false,
    loading: false,
  })),

  on(BoatActions.forkBoatSuccess, (state, { boat }) => ({
    ...state,
    boats: {
      ...state.boats,
      [boat._id]: boat,
    },
    forkBoatSuccess: true,
    loading: false,
  })),

  on(BoatActions.forkBoatSuccessReset, state => ({
    ...state,
    forkBoatSuccess: false,
    loading: false,
  })),

  on(BoatActions.setBoatId, BoatActions.setBoatIdWithRefreshSuccess, (state, { boatId }) => ({
    ...state,
    boatId,
  })),

  on(BoatActions.loadUsersSuccess, (state, { users }) => {
    const newUsers = {};
    users.forEach(user => (newUsers[user._id] = user));
    return {
      ...state,
      users: newUsers,
      loading: false,
    };
  }),

  on(BoatActions.updateUserRoleSuccess, (state, { user }) => ({
    ...state,
    users: {
      ...state.users,
      [user._id]: user,
    },
    loading: false,
  })),

  on(BoatActions.cleanInviteUserError, state => ({
    ...state,
    inviteUserError: null,
  })),

  on(BoatActions.removeUserSuccess, (state: BoatState, { userId }) => {
    const users = Object.values(state.users).filter(user => user._id !== userId);
    const newUsers = {};
    users.forEach(user => (newUsers[user._id] = user));

    return {
      ...state,
      users: newUsers,
      loading: false,
    };
  }),

  on(BoatActions.removeUserFailure, (state, { error }) => ({ ...state, loading: false, removeUserError: error })),

  on(BoatActions.cleanRemoveUserError, state => ({
    ...state,
    removeUserError: null,
  })),

  on(BoatActions.getBoatSubscriptionSuccess, (state, { subscription, boatId }) => ({
    ...state,
    subscriptions: {
      ...state.subscriptions,
      [boatId]: subscription,
    },
    loading: false,
  })),

  on(BoatActions.fetchAvailableSubscriptionsSuccess, (state, { availableSubscriptions }) => ({
    ...state,
    availableSubscriptions,
    loading: false,
  })),
);
