<div class="pricing-card" (click)="selectSubscription.emit(subscription)" [class.--selected]="isSelected">
  <div class="pricing-card__left">
    <p class="pricing-card__left__title" [innerHTML]="'PRICING_CARD.' + subscription?.interval | translate"></p>
    <div class="pricing-card__left__middle">
      <p class="pricing-card__left__middle__price">
        {{ subscription?.price / 100 | currency: "EUR" }}
      </p>
      <p class="pricing-card__left__middle__sub-price" [innerHTML]="'PRICING_CARD.vatApplicable' | translate"></p>
    </div>
    <div class="pricing-card__left__footer">
      <span class="pricing-card__left__footer__tag">
        <p
          class="pricing-card__left__footer__tag__text"
          [innerHTML]="'PRICING_CARD.trialDays' | translate: { days: subscription?.freeTrialDays }"
        ></p>
      </span>
      <p
        class="pricing-card__left__footer__text"
        [innerHTML]="'PRICING_CARD.pricing.' + subscription?.interval | translate: { price: subscription?.price / 100 | currency: 'EUR' }"
      ></p>
    </div>
  </div>
  <ion-checkbox class="pricing-card__checkbox" [checked]="isSelected"></ion-checkbox>
</div>
