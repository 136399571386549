<span class="empty-animation__animation" [class]="classModifier">
  <ng-lottie [options]="options" [width]="animationWidth" [height]="animationHeight"></ng-lottie>
</span>
<p *ngIf="text" class="empty-animation__text" [innerHTML]="text" [class]="classModifier"></p>
<ion-button
  class="empty-animation__button {{ classModifier }}"
  color="tertiary"
  *ngIf="button"
  [innerHTML]="button"
  (click)="clickEvent.emit()"
></ion-button>
