import { Subscription } from "rxjs";

const isFunction = (fn: any) => typeof fn === "function";

function doUnsubscribe(subscription: any): void {
  if (subscription && isFunction(subscription.unsubscribe) && subscription instanceof Subscription) {
    subscription.unsubscribe();
  }
}

export function autoUnsubscribe(): any {
  return (constructor: Function): void => {
    ["ngOnDestroy", "ionViewDidLeave", "ionViewWillLeave"].forEach(e => {
      constructor.prototype[e] = function (): void {
        for (const propName in this) {
          if (Object.prototype.hasOwnProperty.call(this, propName)) {
            try {
              doUnsubscribe(this[propName]);
            } catch (error) {}
          }
        }
      };
    });
  };
}
