import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BoatDto } from "src/app/services/api";

export interface SwitchYachtPageInputs {
  boats: BoatDto[];
  boatId: string;
}

@Component({
  selector: "app-switch-yacht",
  templateUrl: "./switch-yacht.page.html",
  styleUrls: ["./switch-yacht.page.scss"],
})
export class SwitchYachtPage implements OnInit {
  @Input() public boats: BoatDto[];
  @Input() public boatId: string;

  public searchFocus: boolean;
  public searchTerm: string;

  constructor(public modalController: ModalController) {}

  public ngOnInit(): void {
    this.searchTerm = "";
  }

  public updateSearchTerm(searchTerm: string): void {
    this.searchTerm = searchTerm;
  }

  public updateFocus(focus: boolean): void {
    this.searchFocus = focus;
  }

  public selectBoat(boat: BoatDto): void {
    this.modalController.dismiss(boat._id);
  }
}
