<ion-app>
  <ion-split-pane
    contentId="main"
    when="sm"
    [disabled]="hideSideMenu"
    (ionSplitPaneVisible)="splitPaneVisibleChanged($event.detail.visible)"
  >
    <ion-menu contentId="main" type="overlay" [swipeGesture]="false">
      <app-side-menu
        [avatar$]="avatar$"
        [user$]="user$"
        [boatSelected$]="boatSelected$"
        [environment$]="environment$"
        [loadingBoats$]="loadingBoats$"
        [logo$]="logo$"
        [unreadMessages$]="unreadMessages$"
        (selectBoat)="selectBoat()"
        (createBoat)="createBoat()"
        (forkBoat)="forkBoat()"
        (deleteBoat)="deleteBoat()"
        (logout)="logout()"
        (openSupport)="openSupport()"
        (openChat)="openChat()"
      ></app-side-menu>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
  <app-offline-indicator></app-offline-indicator>
</ion-app>
