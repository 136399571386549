/* eslint-disable no-shadow */
import { Systems } from "src/app/constants";
import { Merge } from "src/app/interfaces/global.interface";
import { ComponentDto } from "src/app/services/api";

export type PagesWithFilters = "calendar" | "components" | "history";

export enum Sort {
  alphabetic = "alphabetic",
  system = "system",
}

export enum Type {
  recurringTasks = "recurringTasks",
  oneTimeTasks = "oneTimeTasks",
}

export const DEFAULT_FILTERS: Filters = {
  calendar: { systems: Systems, types: Object.keys(Type) as Type[], filtersChanged: false },
  components: { systems: Systems, sort: Sort.system, filtersChanged: false },
  history: { systems: Systems, filtersChanged: false },
};

export interface Filter {
  systems: ComponentDto.SystemEnum[];
  types?: Type[];
  sort?: Sort;
}

export interface Filters {
  [id: string]: Merge<Filter, { filtersChanged: boolean }>;
}

export interface FilterState {
  filters: Filters;
}

export const initialState: FilterState = {
  filters: DEFAULT_FILTERS,
};
