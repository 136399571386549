import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

export interface SuccessQueryParams {
  headerTitle: string;
  title?: string;
  text?: string;
  buttonText: string;
  backButtonText?: string;
}

@Component({
  selector: "app-success",
  templateUrl: "success.page.html",
  styleUrls: ["success.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessPage {
  @Input() public headerTitle: string;
  @Input() public title?: string;
  @Input() public text?: string;
  @Input() public buttonText: string;
  @Input() public backButtonText?: string;

  constructor(public readonly modalController: ModalController) {}
}
