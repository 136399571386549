<ion-header [translucent]="false">
    <ion-toolbar class="--no-border">
        <ion-title [innerHTML]="'SWITCH_YACHT.title' | translate"></ion-title>
        <ion-buttons slot="end">
            <ion-button [innerHTML]="'SWITCH_YACHT.dismiss' | translate" (click)="modalController.dismiss()"></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="main-content" [fullscreen]="true" [scrollY]="!searchFocus || searchTerm.length">
    <ion-header class="ion-no-border" collapse="condense" [translucent]="false">
        <ion-toolbar>
            <ion-title innerHTML="{{ 'SWITCH_YACHT.title' | translate }}" size="large"></ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-header class="sticky" [translucent]="false">
        <ion-toolbar class="--padding">
            <ion-searchbar
                class="main-searchbar"
                enterkeyhint="search"
                showCancelButton="focus"
                [cancelButtonText]="'SWITCH_YACHT.cancel' | translate"
                [value]="searchTerm"
                (ionBlur)="updateFocus(false)"
                (ionFocus)="updateFocus(true)"
                (ionInput)="updateSearchTerm($event.target.value)"
            ></ion-searchbar>
        </ion-toolbar>
    </ion-header>
    <app-boat-list
        *ngIf="!searchTerm.length"
        [boats]="boats"
        [class.--veil]="searchFocus"
        (selectBoat)="selectBoat($event)"
    ></app-boat-list>
    <app-boat-list-search
        *ngIf="searchTerm.length"
        [boats]="boats"
        [searchTerm]="searchTerm"
        (selectBoat)="selectBoat($event)"
    ></app-boat-list-search>
</ion-content>
