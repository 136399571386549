import { createReducer, on } from "@ngrx/store";
import { initialState, ResourceState } from "./state";
import * as ResourceActions from "./actions";
import { ResourceDto } from "src/app/services/api";

export const resourceReducer = createReducer(
  initialState,

  on(
    ResourceActions.loadAll,
    ResourceActions.loadByComponent,
    ResourceActions.loadByTaskExecution,
    ResourceActions.loadBoatLogos,
    state => ({
      ...state,
      loading: {
        ...state.loading,
        getDocuments: true,
        getImages: true,
        getLocation: true,
      },
    }),
  ),

  on(ResourceActions.create, ResourceActions.remove, (state, { resource }) => ({
    ...state,
    loading: {
      ...state.loading,
      postLocation: resource.tags.includes(ResourceDto.TagsEnum.Location),
      postImages: resource.resourceType === ResourceDto.ResourceTypeEnum.Image && resource.tags.includes(ResourceDto.TagsEnum.Attachment),
      postDocuments: resource.resourceType === ResourceDto.ResourceTypeEnum.File && resource.tags.includes(ResourceDto.TagsEnum.Attachment),
    },
  })),

  on(ResourceActions.update, (state, { resource }) => ({
    ...state,
    loading: {
      ...state.loading,
      postLocation: resource.updateResourceDto.tags.includes(ResourceDto.TagsEnum.Location),
      postImages:
        resource.updateResourceDto.resourceType === ResourceDto.ResourceTypeEnum.Image &&
        resource.updateResourceDto.tags.includes(ResourceDto.TagsEnum.Attachment),
      postDocuments:
        resource.updateResourceDto.resourceType === ResourceDto.ResourceTypeEnum.File &&
        resource.updateResourceDto.tags.includes(ResourceDto.TagsEnum.Attachment),
    },
  })),

  on(ResourceActions.createFailure, ResourceActions.updateFailure, ResourceActions.removeFailure, state => ({
    ...state,
    loading: {
      ...state.loading,
      postDocuments: false,
      postImages: false,
      postLocation: false,
    },
  })),

  on(
    ResourceActions.loadAllFailure,
    ResourceActions.loadByComponentFailure,
    ResourceActions.loadByTaskExecutionFailure,
    ResourceActions.loadBoatLogosFailure,
    state => ({
      ...state,
      loading: {
        ...state.loading,
        getDocuments: false,
        getImages: false,
        getLocation: false,
      },
    }),
  ),

  on(ResourceActions.loadAllSuccess, (state, { resources }) => {
    const newResources = {};
    resources.forEach(resource => (newResources[resource._id] = resource));
    return {
      ...state,
      resources: newResources,
      loading: {
        ...state.loading,
        getDocuments: false,
        getImages: false,
        getLocation: false,
      },
    };
  }),

  on(
    ResourceActions.loadByComponentSuccess,
    ResourceActions.loadByTaskExecutionSuccess,
    ResourceActions.loadBoatLogosSuccess,
    (state: ResourceState, { resources }) => {
      const newResources = {};
      resources.forEach(resource => (newResources[resource._id] = resource));
      return {
        ...state,
        resources: {
          ...state.resources,
          ...newResources,
        },
        loading: {
          ...state.loading,
          getDocuments: false,
          getImages: false,
          getLocation: false,
        },
      };
    },
  ),

  on(ResourceActions.createSuccess, ResourceActions.updateSuccess, (state, { resource }) => ({
    ...state,
    resources: {
      ...state.resources,
      [resource._id]: { ...(state.resources[resource._id] || {}), ...resource },
    },
    loading: {
      ...state.loading,
      postDocuments: false,
      postImages: false,
      postLocation: false,
    },
  })),

  on(ResourceActions.removeSuccess, (state: ResourceState, { id }) => {
    const resources = Object.values(state.resources).filter(resource => resource._id !== id);
    const newResources = {};
    resources.forEach(resource => (newResources[resource._id] = resource));

    return {
      ...state,
      resources: newResources,
      loading: {
        ...state.loading,
        postDocuments: false,
        postImages: false,
        postLocation: false,
      },
    };
  }),

  on(ResourceActions.setLoading, (state, { getDocuments, getImages, getLocation, postDocuments, postImages, postLocation }) => ({
    ...state,
    loading: {
      getDocuments: getDocuments || state.loading.getDocuments,
      getImages: getImages || state.loading.getImages,
      getLocation: getLocation || state.loading.getLocation,
      postDocuments: postDocuments || state.loading.postDocuments,
      postImages: postImages || state.loading.postImages,
      postLocation: postLocation || state.loading.postLocation,
    },
  })),

  on(ResourceActions.cacheOneSuccess, (state, { resource }) => ({
    ...state,
    cachedResources: {
      ...state.cachedResources,
      [resource._id]: resource,
    },
  })),

  on(ResourceActions.clearOneSuccess, ResourceActions.clearOneFailure, (state, { resource: { _id } }) => {
    const cachedResources = Object.values(state.cachedResources).filter(resource => resource._id !== _id);
    const newCachedResources = {};
    cachedResources.forEach(resource => (newCachedResources[resource._id] = resource));

    return {
      ...state,
      cachedResources: newCachedResources,
    };
  }),

  on(ResourceActions.bulkUpdateSuccess, (state, { resources }) => {
    const newResources = {};
    resources.forEach(resource => (newResources[resource._id] = resource));

    return {
      ...state,
      resources: {
        ...state.resources,
        ...newResources,
      },
    };
  }),
);
