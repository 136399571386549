/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelError { 
    /**
     * Error message.
     */
    message: string;
    /**
     * Error code.
     */
    i18n: ModelError.I18nEnum;
}
export namespace ModelError {
    export type I18nEnum = 'AUTH@NOT_FOUND' | 'AUTH@NOT_EXISTS_OR_INVALID_PASSWORD' | 'AUTH@NOT_ACTIVE_OR_BLOCKED' | 'AUTH@EXPIRED_TOKEN' | 'AUTH@EMAIL_ALREADY_EXISTS' | 'AUTH@INVALID_PASSWORD' | 'AUTH@AUTH_EXPIRED_REFRESH_TOKEN' | 'AUTH_SCOPE@NOT_FOUND' | 'INVITATION@ACCEPTED_ALREADY' | 'BOAT@NOT_FOUND' | 'BOAT@NAME_ALREADY_EXISTS' | 'BOAT@NOT_A_TEMPLATE' | 'BOAT@BOAT_INVALID_PRICE' | 'BOAT@BOAT_FORK_FORBID_CUSTOM_OWNER' | 'BOAT@BOAT_FORK_INVALID_OWNER' | 'SUBSCRIPTION@NOT_FOUND' | 'SUBSCRIPTION@SUBSCRIPTION_EXPIRED_OR_MISSING' | 'CODE@NOT_FOUND' | 'CODE@EXPIRED' | 'COMPONENT@NOT_FOUND' | 'COMPONENT@NAME_ALREADY_EXISTS' | 'COMPONENT@ID_ALREADY_EXISTS' | 'METER@NOT_FOUND' | 'METER@NAME_ALREADY_EXISTS' | 'TASK@NOT_FOUND' | 'TASK@TITLE_ALREADY_EXISTS' | 'TASK@INVALID_EXEC_DATE' | 'TASK@INVALID_EXEC_METERS' | 'TASK@INVALID_DECLINE_DATE' | 'TASK@INVALID_POSTPONE_DATE' | 'TASK@INVALID_POSTPONE_METER_VALUE' | 'TASK@NOT_DUE_OR_OVERDUE' | 'UPM@INVALID_EXEC_DATE' | 'UPM@UPM_INVALID_TYPE' | 'TRIGGER@NOT_FOUND' | 'TRIGGER@REQUIRED_RECURRENCE_OR_NEXT_EXEC' | 'TRIGGER@INVALID_DATE_RECURRENCE_FORMAT' | 'TRIGGER@INVALID_DATE_NEXT_EXECUTION' | 'TRIGGER@INVALID_DATE_RECURRENCE_RULE' | 'TRIGGER@NON_EXECUTION_DEFINED' | 'TRIGGER@INVALID_TIMEZONE' | 'TRIGGER@METER_REQUIRED' | 'TRIGGER@METER_NOT_FOUND' | 'TRIGGER@INVALID_METER_NEXT_EXECUTION' | 'TRIGGER@INVALID_METER_RECURRENCE' | 'RESOURCE@NOT_FOUND' | 'RESOURCE@DELETED' | 'RESOURCE@INVALID_ID' | 'RESOURCE@INVALID_RESOLVER' | 'RESOURCE@MISSING_FILE' | 'RESOURCE@FILE_TOO_LARGE' | 'USER@NOT_FOUND' | 'USER@EMAIL_ALREADY_EXISTS' | 'USER@DELETE_OWN_ACCOUNT' | 'USER@USER_MISSING_STRIPE_ID' | 'SEARCH_INVALID_FILTER_KEYS' | 'SEARCH_INVALID_FILTER_STRUCTURE' | 'SEARCH_UNSUPPORTED_DOMAIN' | 'SEARCH_EXECUTION_ERROR' | 'INVALID_DATE_FORMAT_ERROR' | 'INVALID_DATE_RANGE_FILTER_ERROR';
    export const I18nEnum = {
        AuthnotFound: 'AUTH@NOT_FOUND' as I18nEnum,
        AuthnotExistsOrInvalidPassword: 'AUTH@NOT_EXISTS_OR_INVALID_PASSWORD' as I18nEnum,
        AuthnotActiveOrBlocked: 'AUTH@NOT_ACTIVE_OR_BLOCKED' as I18nEnum,
        AuthexpiredToken: 'AUTH@EXPIRED_TOKEN' as I18nEnum,
        AuthemailAlreadyExists: 'AUTH@EMAIL_ALREADY_EXISTS' as I18nEnum,
        AuthinvalidPassword: 'AUTH@INVALID_PASSWORD' as I18nEnum,
        AuthauthExpiredRefreshToken: 'AUTH@AUTH_EXPIRED_REFRESH_TOKEN' as I18nEnum,
        AuthScopenotFound: 'AUTH_SCOPE@NOT_FOUND' as I18nEnum,
        InvitationacceptedAlready: 'INVITATION@ACCEPTED_ALREADY' as I18nEnum,
        BoatnotFound: 'BOAT@NOT_FOUND' as I18nEnum,
        BoatnameAlreadyExists: 'BOAT@NAME_ALREADY_EXISTS' as I18nEnum,
        BoatnotATemplate: 'BOAT@NOT_A_TEMPLATE' as I18nEnum,
        BoatboatInvalidPrice: 'BOAT@BOAT_INVALID_PRICE' as I18nEnum,
        BoatboatForkForbidCustomOwner: 'BOAT@BOAT_FORK_FORBID_CUSTOM_OWNER' as I18nEnum,
        BoatboatForkInvalidOwner: 'BOAT@BOAT_FORK_INVALID_OWNER' as I18nEnum,
        SubscriptionnotFound: 'SUBSCRIPTION@NOT_FOUND' as I18nEnum,
        SubscriptionsubscriptionExpiredOrMissing: 'SUBSCRIPTION@SUBSCRIPTION_EXPIRED_OR_MISSING' as I18nEnum,
        CodenotFound: 'CODE@NOT_FOUND' as I18nEnum,
        Codeexpired: 'CODE@EXPIRED' as I18nEnum,
        ComponentnotFound: 'COMPONENT@NOT_FOUND' as I18nEnum,
        ComponentnameAlreadyExists: 'COMPONENT@NAME_ALREADY_EXISTS' as I18nEnum,
        ComponentidAlreadyExists: 'COMPONENT@ID_ALREADY_EXISTS' as I18nEnum,
        MeternotFound: 'METER@NOT_FOUND' as I18nEnum,
        MeternameAlreadyExists: 'METER@NAME_ALREADY_EXISTS' as I18nEnum,
        TasknotFound: 'TASK@NOT_FOUND' as I18nEnum,
        TasktitleAlreadyExists: 'TASK@TITLE_ALREADY_EXISTS' as I18nEnum,
        TaskinvalidExecDate: 'TASK@INVALID_EXEC_DATE' as I18nEnum,
        TaskinvalidExecMeters: 'TASK@INVALID_EXEC_METERS' as I18nEnum,
        TaskinvalidDeclineDate: 'TASK@INVALID_DECLINE_DATE' as I18nEnum,
        TaskinvalidPostponeDate: 'TASK@INVALID_POSTPONE_DATE' as I18nEnum,
        TaskinvalidPostponeMeterValue: 'TASK@INVALID_POSTPONE_METER_VALUE' as I18nEnum,
        TasknotDueOrOverdue: 'TASK@NOT_DUE_OR_OVERDUE' as I18nEnum,
        UpminvalidExecDate: 'UPM@INVALID_EXEC_DATE' as I18nEnum,
        UpmupmInvalidType: 'UPM@UPM_INVALID_TYPE' as I18nEnum,
        TriggernotFound: 'TRIGGER@NOT_FOUND' as I18nEnum,
        TriggerrequiredRecurrenceOrNextExec: 'TRIGGER@REQUIRED_RECURRENCE_OR_NEXT_EXEC' as I18nEnum,
        TriggerinvalidDateRecurrenceFormat: 'TRIGGER@INVALID_DATE_RECURRENCE_FORMAT' as I18nEnum,
        TriggerinvalidDateNextExecution: 'TRIGGER@INVALID_DATE_NEXT_EXECUTION' as I18nEnum,
        TriggerinvalidDateRecurrenceRule: 'TRIGGER@INVALID_DATE_RECURRENCE_RULE' as I18nEnum,
        TriggernonExecutionDefined: 'TRIGGER@NON_EXECUTION_DEFINED' as I18nEnum,
        TriggerinvalidTimezone: 'TRIGGER@INVALID_TIMEZONE' as I18nEnum,
        TriggermeterRequired: 'TRIGGER@METER_REQUIRED' as I18nEnum,
        TriggermeterNotFound: 'TRIGGER@METER_NOT_FOUND' as I18nEnum,
        TriggerinvalidMeterNextExecution: 'TRIGGER@INVALID_METER_NEXT_EXECUTION' as I18nEnum,
        TriggerinvalidMeterRecurrence: 'TRIGGER@INVALID_METER_RECURRENCE' as I18nEnum,
        ResourcenotFound: 'RESOURCE@NOT_FOUND' as I18nEnum,
        Resourcedeleted: 'RESOURCE@DELETED' as I18nEnum,
        ResourceinvalidId: 'RESOURCE@INVALID_ID' as I18nEnum,
        ResourceinvalidResolver: 'RESOURCE@INVALID_RESOLVER' as I18nEnum,
        ResourcemissingFile: 'RESOURCE@MISSING_FILE' as I18nEnum,
        ResourcefileTooLarge: 'RESOURCE@FILE_TOO_LARGE' as I18nEnum,
        UsernotFound: 'USER@NOT_FOUND' as I18nEnum,
        UseremailAlreadyExists: 'USER@EMAIL_ALREADY_EXISTS' as I18nEnum,
        UserdeleteOwnAccount: 'USER@DELETE_OWN_ACCOUNT' as I18nEnum,
        UseruserMissingStripeId: 'USER@USER_MISSING_STRIPE_ID' as I18nEnum,
        SearchInvalidFilterKeys: 'SEARCH_INVALID_FILTER_KEYS' as I18nEnum,
        SearchInvalidFilterStructure: 'SEARCH_INVALID_FILTER_STRUCTURE' as I18nEnum,
        SearchUnsupportedDomain: 'SEARCH_UNSUPPORTED_DOMAIN' as I18nEnum,
        SearchExecutionError: 'SEARCH_EXECUTION_ERROR' as I18nEnum,
        InvalidDateFormatError: 'INVALID_DATE_FORMAT_ERROR' as I18nEnum,
        InvalidDateRangeFilterError: 'INVALID_DATE_RANGE_FILTER_ERROR' as I18nEnum
    };
}


