import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import {
  AuthService,
  BoatReportService,
  BoatService,
  BoatSubscriptionService,
  TriggerService,
  SubscriptionService,
} from "src/app/services/api";
import { RootState } from "..";
import * as BoatActions from "./actions";
import * as FromBoat from "src/app/store/boat/selectors";
import * as FromUser from "src/app/store/user/selectors";
import { ModalController, NavController, LoadingController, AlertController } from "@ionic/angular";
import { SharedRoutes } from "src/app/constants";
import * as UserActions from "src/app/store/user/actions";
import * as FilterActions from "src/app/store/filter/actions";
import * as AppActions from "src/app/store/app/actions";
import { HttpErrorResponse } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { CreateBoatPage } from "src/app/pages/create-boat/create-boat.page";
import { SuccessPage, SuccessQueryParams } from "src/app/modals/success/success.page";
import { DOCUMENT } from "@angular/common";
import { PosthogService } from "src/app/services/posthog/posthog.service";
import { format } from "date-fns";
import { SwitchYachtPage, SwitchYachtPageInputs } from "src/app/modals/switch-yacht/switch-yacht.page";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";

@Injectable()
export class BoatEffects {
  public routes: typeof SharedRoutes = SharedRoutes;

  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.loadAll),
      mergeMap(() =>
        this.boatService.boatsControllerFindAll().pipe(
          withLatestFrom(this.store.select(FromBoat.selectBoatId)),
          mergeMap(([boats, selectedBoat]) => {
            if (!boats.length) this.showcreateBoatModal();
            return !!selectedBoat
              ? [BoatActions.loadAllSuccess({ boats })]
              : [BoatActions.setBoatId({ boatId: boats[0]._id }), BoatActions.loadAllSuccess({ boats })];
          }),
          catchError(() => of(BoatActions.loadAllFailure())),
        ),
      ),
    ),
  );

  public seeMyNewBoat$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.seeMyNewBoat),
      mergeMap(() =>
        this.boatService.boatsControllerFindAll().pipe(
          mergeMap(boats => [BoatActions.loadAllSuccess({ boats }), BoatActions.setBoatId({ boatId: boats.pop()._id })]),
          tap(() => this.navController.navigateRoot([this.routes.planning])),
          catchError(() => of(BoatActions.loadAllFailure())),
        ),
      ),
    ),
  );

  public loadOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.loadOne),
      mergeMap(({ id }) =>
        this.boatService.boatsControllerFindOne({ boat: id }).pipe(
          map(boat => BoatActions.loadOneSuccess({ boat })),
          catchError(() => of(BoatActions.loadOneFailure())),
        ),
      ),
    ),
  );

  public setBoatId$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoatActions.setBoatId),
        withLatestFrom(this.store.select(FromUser.selectUser)),
        filter(([, user]) => !!user),
        tap(([{ boatId }, { _id }]) => {
          this.analytics.setUserId(_id);
          this.analytics.setUserProperties({ boatId });
          this.store.dispatch(FilterActions.resetFilter({ page: "history" }));
          this.store.dispatch(FilterActions.resetFilter({ page: "components" }));
          this.store.dispatch(BoatActions.loadUsers());
        }),
      ),
    { dispatch: false },
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.create),
      mergeMap(({ boat: createBoatDto }) =>
        this.boatService.boatsControllerCreate({ createBoatDto }).pipe(
          mergeMap(boat => [BoatActions.createSuccess({ boat }), BoatActions.setBoatId({ boatId: boat._id })]),
          catchError(() => of(BoatActions.createFailure())),
        ),
      ),
    ),
  );

  public createSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoatActions.createSuccess),
        tap(({ boat }) => {
          this.triggersService.triggersControllerReset({
            boat: boat._id,
            resetTriggerDto: { nextExecutionFrom: format(new Date(), "yyyy-MM-dd") },
          });
          this.store.dispatch(AppActions.setunreadCrispChatMesssages({ unreadCrispChatMesssages: 1 }));
        }),
      ),
    { dispatch: false },
  );

  public forkBoat$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.forkBoat),
      mergeMap(({ boat: boatId, ignoreResourcesOfType, forkBoatDto }) =>
        this.boatService.boatsControllerFork({ boat: boatId, forkBoatDto, ignoreResourcesOfType }).pipe(
          mergeMap(boat => [BoatActions.forkBoatSuccess({ boat }), BoatActions.setBoatId({ boatId: boat._id })]),
          catchError(() => of(BoatActions.createFailure())),
        ),
      ),
    ),
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.update),
      mergeMap(action =>
        this.boatService.boatsControllerUpdate({ boat: action.id, updateBoatDto: action.boat }).pipe(
          map(boat => BoatActions.updateSuccess({ boat })),
          catchError(() => of(BoatActions.updateFailure())),
        ),
      ),
    ),
  );

  public remove$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoatActions.remove),
        mergeMap(async ({ boat }) => {
          const alert = await this.alertController.create({
            header: this.translate.instant("SETTINGS.deleteYachtAlertTitle", { boatName: boat.name }),
            message: this.translate.instant("SETTINGS.deleteYachtAlertDescription"),
            buttons: [
              {
                role: "cancel",
                text: this.translate.instant("SETTINGS.deleteYacthDismiss"),
              },
              {
                role: "destructive",
                cssClass: ["alert-danger-button"],
                text: this.translate.instant("SETTINGS.deleteYacthConfirm"),
                handler: () =>
                  this.boatService
                    .boatsControllerRemove({ boat: boat._id })
                    .pipe(
                      map(() => this.store.dispatch(BoatActions.removeSuccess({ id: boat._id }))),
                      catchError(error => {
                        this.store.dispatch(BoatActions.removeFailure(error?.error?.i18n));
                        return throwError(error);
                      }),
                    )
                    .subscribe(),
              },
            ],
          });
          alert.present();
        }),
      ),
    { dispatch: false },
  );

  public removeSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoatActions.removeSuccess),
        withLatestFrom(this.store.select(FromBoat.selectAll)),
        tap(([{ id }, boats]) => this.openChooseBoatModal({ boats, boatId: id })),
      ),
    { dispatch: false },
  );

  public refreshBoats$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.refreshBoats),
      withLatestFrom(this.store.select(FromUser.selectRefreshToken)),
      mergeMap(([, refreshToken]) =>
        this.authService.authControllerRefresh({ refreshDto: { refreshToken } }).pipe(
          mergeMap(tokens => [UserActions.refreshSuccess({ tokens }), BoatActions.loadAll()]),
          catchError(() => of(BoatActions.refreshBoatsFailure())),
        ),
      ),
    ),
  );

  public loadUsers$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.loadUsers),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([, boat]) =>
        this.boatService.boatsUsersControllerFindAll({ boat }).pipe(
          map(users => BoatActions.loadUsersSuccess({ users })),
          catchError(() => of(BoatActions.loadUsersFailure())),
        ),
      ),
    ),
  );

  public inviteUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.inviteUser),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ invitationDto }, boat]) =>
        this.authService.authControllerInvite({ boat, invitationDto }).pipe(
          map(() => {
            this.showInviteUserSuccess();
            return BoatActions.inviteUserSuccess();
          }),
          tap(() => this.modalController.dismiss()),
          catchError((error: HttpErrorResponse) => {
            setTimeout(() => this.store.dispatch(BoatActions.cleanInviteUserError()), 3000);
            return of(BoatActions.inviteUserFailure({ error: error?.error?.i18n }));
          }),
        ),
      ),
    ),
  );

  public inviteUserSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoatActions.inviteUserSuccess),
        tap(() => this.posthogService.sendEvent("inviteUser")),
      ),
    { dispatch: false },
  );

  public deleteUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.removeUser),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ userId }, boat]) =>
        this.boatService.boatsUsersControllerRemove({ boat, user: userId }).pipe(
          map(() => BoatActions.removeUserSuccess({ userId })),
          catchError((error: HttpErrorResponse) => {
            setTimeout(() => this.store.dispatch(BoatActions.cleanRemoveUserError()), 3000);
            return of(BoatActions.removeUserFailure({ error: error?.error?.i18n }));
          }),
        ),
      ),
    ),
  );

  public updateUserRole$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.updateUserRole),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ userId, boatUserRoleDto }, boat]) =>
        this.boatService.boatsUsersControllerUpdate({ boat, user: userId, boatUserRoleDto }).pipe(
          map(user => BoatActions.updateUserRoleSuccess({ user })),
          tap(() => this.modalController.dismiss()),
          catchError((error: HttpErrorResponse) => {
            setTimeout(() => this.store.dispatch(BoatActions.cleanInviteUserError()), 3000);
            return of(BoatActions.updateUserRoleFailure({ error: error?.error?.i18n }));
          }),
        ),
      ),
    ),
  );

  public setBoatIdWithRefresh$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.setBoatIdWithRefresh),
      mergeMap(({ boatId }) =>
        this.showLoadingController().pipe(
          withLatestFrom(this.store.select(FromUser.selectRefreshToken)),
          mergeMap(([loading, refreshToken]) =>
            this.authService.authControllerRefresh({ refreshDto: { refreshToken } }).pipe(
              mergeMap(tokens => {
                this.store.dispatch(UserActions.refreshSuccess({ tokens }));
                return this.boatService.boatsControllerFindAll().pipe(
                  withLatestFrom(this.store.select(FromUser.selectUser)),
                  mergeMap(([boats, user]) => {
                    this.store.dispatch(BoatActions.loadAllSuccess({ boats }));
                    loading.dismiss();
                    if (boats.find(boat => boat._id === boatId)) {
                      if (user) {
                        this.analytics.setUserId(user._id);
                        this.analytics.setUserProperties({ boatId });
                      }
                      this.store.dispatch(BoatActions.loadUsers());
                      return of(BoatActions.setBoatIdWithRefreshSuccess({ boatId }));
                    }
                    return of(BoatActions.setBoatIdWithRefreshFailure());
                  }),
                  catchError(() => {
                    loading.dismiss();
                    return of(BoatActions.loadAllFailure());
                  }),
                );
              }),
              catchError(() => {
                loading.dismiss();
                return of(UserActions.refreshFailure());
              }),
            ),
          ),
          catchError(() => of(BoatActions.setBoatIdWithRefreshFailure())),
        ),
      ),
      catchError(() => of(BoatActions.setBoatIdWithRefreshFailure())),
    ),
  );

  public getBoatSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.getBoatSubscription),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ event }, boatId]) =>
        this.boatSubscriptionService.boatSubscriptionControllerGet({ boat: boatId }).pipe(
          mergeMap(subscription =>
            this.boatSubscriptionService.boatSubscriptionControllerPortalSession({ boat: boatId }).pipe(
              map(({ url }) => BoatActions.getBoatSubscriptionSuccess({ subscription: { ...subscription, url }, boatId, event })),
              catchError((error: HttpErrorResponse) => of(BoatActions.getBoatSubscriptionFailure({ error: error?.error?.i18n }))),
            ),
          ),
        ),
      ),
    ),
  );

  public getBoatSubscriptionSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoatActions.getBoatSubscriptionSuccess),
        tap(({ event }) => event?.target.complete()),
      ),
    { dispatch: false },
  );

  public fetchAvailableSubscriptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.fetchAvailableSubscriptions),
      mergeMap(({ length }) =>
        this.subscriptionService.subscriptionControllerGetAvailableSubscriptions({ length }).pipe(
          map(availableSubscriptions => BoatActions.fetchAvailableSubscriptionsSuccess({ availableSubscriptions })),
          catchError((error: HttpErrorResponse) => of(BoatActions.fetchAvailableSubscriptionsFailure())),
        ),
      ),
    ),
  );

  public createBoatReport$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BoatActions.createBoatReport),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([reportFilters, boat]) =>
        this.boatReportService.reportControllerGenerateBoatReport({ boat, reportFilters }).pipe(
          map(() => BoatActions.createBoatReportSuccess()),
          catchError(() => of(BoatActions.createBoatReportFailure())),
        ),
      ),
    ),
  );

  public createBoatReportSuccess$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoatActions.createBoatReportSuccess),
        tap(async () => {
          const componentProps: SuccessQueryParams = {
            headerTitle: "REPORT.successHeaderTitle",
            title: "REPORT.successTitle",
            text: "REPORT.successText",
            buttonText: "REPORT.successButtonText",
            backButtonText: "REPORT.successBackButtonText",
          };

          const routerOutlets = this.document.querySelectorAll("ion-router-outlet");
          const presentingElement = routerOutlets[routerOutlets.length - 1];

          const modal = await this.modalController.create({
            component: SuccessPage,
            componentProps,
            presentingElement,
          });

          await modal.present();
          await modal.onDidDismiss();

          this.navController.navigateBack([this.routes.settings]);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly boatService: BoatService,
    private readonly boatReportService: BoatReportService,
    private readonly boatSubscriptionService: BoatSubscriptionService,
    private readonly subscriptionService: SubscriptionService,
    private readonly authService: AuthService,
    private readonly store: Store<RootState>,
    private readonly analytics: AngularFireAnalytics,
    private readonly navController: NavController,
    private readonly modalController: ModalController,
    private readonly loadingController: LoadingController,
    private readonly alertController: AlertController,
    private readonly translate: TranslateService,
    private readonly posthogService: PosthogService,
    private readonly triggersService: TriggerService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  private showLoadingController(): Observable<HTMLIonLoadingElement> {
    return new Observable(observer => {
      this.loadingController
        .create()
        .then(loading =>
          loading
            .present()
            .then(() => observer.next(loading))
            .catch(err => observer.error(err)),
        )
        .catch(err => observer.error(err));
    });
  }

  private async showInviteUserSuccess(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant("USER_MANAGEMENT.inviteUserSuccessHeader"),
      message: this.translate.instant("USER_MANAGEMENT.inviteUserSuccessMessage"),
      buttons: [{ text: this.translate.instant("USER_MANAGEMENT.understood") }],
      backdropDismiss: false,
    });

    await alert.present();
  }

  private async showcreateBoatModal(): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateBoatPage,
      backdropDismiss: false,
    });
    await modal.present();
  }

  private async openChooseBoatModal(componentProps: SwitchYachtPageInputs): Promise<void> {
    const modal = await this.modalController.create({
      component: SwitchYachtPage,
      backdropDismiss: false,
      componentProps,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) this.store.dispatch(BoatActions.setBoatId({ boatId: data }));
  }
}
