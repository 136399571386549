import { Pipe, PipeTransform } from "@angular/core";
import { CurrentUserDto } from "src/app/services/api";

@Pipe({
  name: "isGobalAdminRole",
})
export class IsGobalAdminRole implements PipeTransform {
  public transform(user: CurrentUserDto): boolean {
    return (user?.adminRoles || []).includes("GLOBAL");
  }
}
