import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "..";
import * as FilterActions from "./actions";
import * as TaskEventActions from "src/app/store/task-event/actions";
import * as FromBoat from "src/app/store/boat/selectors";
import { TaskEventDto } from "src/app/services/api";

@Injectable()
export class FilterEffects {
  public setFilter$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterActions.setFilter),
        withLatestFrom(this.store.select(FromBoat.selectBoatId)),
        filter(([{ page }]) => page === "history"),
        tap(([{ filter: f }, boat]) => {
          this.store.dispatch(TaskEventActions.emptyHistory());
          this.store.dispatch(
            TaskEventActions.loadHistory({
              boat,
              system: f.systems,
              sortByMostRecent: "true",
              limit: 100,
              eventType: [TaskEventDto.EventTypeEnum.Completed],
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private store: Store<RootState>) {}
}
