<ion-header class="fork-boat__header ion-no-border" [translucent]="false">
    <ion-toolbar class="ion-no-border">
        <ion-buttons slot="end">
            <ion-button [innerHTML]="'FORK_BOAT.dismiss' | translate" (click)="dismissModal()"></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="fork-boat__content">
    <div class="fork-boat__content__form-wrapper">
        <form
            *ngIf="!(success$ | async)"
            class="fork-boat__content__form-wrapper__form"
            [formGroup]="boatForm"
            (keyup.enter)="boatForm.valid && forkBoat()"
        >
            <ion-title class="big" [innerHTML]="'FORK_BOAT.newBoat' | translate: { boatName: boatToFork?.name }"></ion-title>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.name' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="next"
                    formControlName="name"
                    inputmode="text"
                    required="true"
                    type="text"
                ></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.kind' | translate"></ion-label>
                <ion-select
                    enterkeyhint="next"
                    formControlName="kind"
                    required="true"
                    [label]="'FORK_BOAT.kind' | translate"
                    [placeholder]="'FORK_BOAT.kindPlaceholder' | translate"
                >
                    <ion-select-option
                        *ngFor="let kind of kindEnum"
                        [innerHTML]="'FORK_BOAT.' + kind | translate"
                        [value]="kind"
                    ></ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.brand' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="next"
                    formControlName="brand"
                    inputmode="text"
                    required="true"
                    type="text"
                ></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.model' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="next"
                    formControlName="model"
                    inputmode="text"
                    required="true"
                    type="text"
                ></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.excludeResources' | translate"></ion-label>
                <ion-select
                    enterkeyhint="next"
                    formControlName="excludeResources"
                    multiple="true"
                    required="true"
                    [label]="'FORK_BOAT.excludeResources' | translate"
                    [placeholder]="'FORK_BOAT.excludePlaceholder' | translate"
                >
                    <ion-select-option
                        *ngFor="let resource of excludeEnum"
                        [innerHTML]="'FORK_BOAT.resource.' + resource | translate"
                        [value]="resource"
                    ></ion-select-option>
                </ion-select>
            </ion-item>
            <ion-title class="small" [innerHTML]="'FORK_BOAT.subscriptions' | translate"></ion-title>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.length' | translate"></ion-label>
                <ion-input
                    class="input"
                    debounce="500"
                    enterkeyhint="send"
                    formControlName="length"
                    inputmode="numeric"
                    required="true"
                    type="number"
                    [max]="100"
                    [min]="5"
                    (ionChange)="fetchPricing($event)"
                ></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'FORK_BOAT.ownerId' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="next"
                    formControlName="ownerUserId"
                    inputmode="numeric"
                    required="true"
                    type="text"
                ></ion-input>
            </ion-item>
            <div class="fork-boat__content__pricings">
                <ion-title class="small" [innerHTML]="'FORK_BOAT.chooseSubscription' | translate"></ion-title>
                <ion-spinner *ngIf="loading$ | async" class="fork-boat__content__pricings__spinner"></ion-spinner>
                <span
                    *ngIf="(availableSubscriptions$ | async).length === 0 && !(loading$ | async)"
                    class="fork-boat__content__pricings__empty-message"
                    [innerHTML]="'FORK_BOAT.chooseLenght' | translate"
                ></span>
                <app-pricing-card
                    *ngFor="let subscription of availableSubscriptions$ | async"
                    [isSelected]="subscription.priceId === boatForm.value.priceId"
                    [subscription]="subscription"
                    (selectSubscription)="selectSubscription($event.priceId)"
                ></app-pricing-card>
            </div>

            <ion-button
                class="fork-boat__content__form-wrapper__button"
                [disabled]="boatForm.invalid || (loading$ | async)"
                [innerHTML]="'FORK_BOAT.createButton' | translate"
                (click)="forkBoat()"
            ></ion-button>
        </form>
    </div>

    <div *ngIf="success$ | async" class="fork-boat__content__success">
        <app-empty-animation
            animationHeight="230px"
            animationWidth="230px"
            class="fork-boat__content__success__lottie"
            [options]="{ path: '/assets/lotties/success.json', loop: 0 }"
        ></app-empty-animation>
        <span class="fork-boat__content__success__text" [innerHTML]="'FORK_BOAT.messageSuccessAlert' | translate"></span>
        <ion-button
            class="fork-boat__content__success__button"
            [innerHTML]="'FORK_BOAT.buttonSuccessAlert' | translate"
            (click)="dismissModal()"
        ></ion-button>
    </div>
</ion-content>
