import { Inject, Injectable } from "@angular/core";
import { Observable, interval, pipe } from "rxjs";
import { BoatDto, UserDto } from "../api";
import * as FromUser from "src/app/store/user/selectors";
import * as FromBoat from "src/app/store/boat/selectors";
import * as AppActions from "src/app/store/app/actions";
import { RootState } from "src/app/store";
import { Store } from "@ngrx/store";
import { DOCUMENT } from "@angular/common";
import { distinctUntilKeyChanged, filter, map, take, timeout } from "rxjs/operators";
import { EnvService } from "../env/env.service";

@Injectable({
  providedIn: "root",
})
export class CrispService {
  public user$: Observable<UserDto> = this.store.select(FromUser.selectUser);
  public boatSelected$: Observable<BoatDto> = this.store.select(FromBoat.selectBoatSelected);

  constructor(
    public readonly store: Store<RootState>,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly environmentService: EnvService,
  ) {
    this.initialize();
  }

  public open(): void {
    this.document.defaultView["$crisp"].push(["do", "chat:show"]);
    this.document.defaultView["$crisp"].push(["do", "chat:open"]);
  }

  public markAsRead(): void {
    this.document.defaultView["$crisp"].push(["do", "message:read"]);
  }

  public logout(): void {
    this.document.defaultView["$crisp"].push(["do", "session:reset"]);
    this.document.defaultView["$crisp"].push(["config", "position:reverse", [true]]);
    this.document.defaultView["$crisp"].push(["do", "chat:hide"]);
  }

  private identifyYacht(): void {
    this.store
      .select(FromBoat.selectBoatSelected)
      .pipe(
        filter(boat => !!boat?._id),
        distinctUntilKeyChanged("_id"),
        map(({ name, _id, length }) => {
          this.document.defaultView["$crisp"].push([
            "set",
            "session:data",
            [
              [
                ["boatName", name],
                ["boatID", _id],
                ["boatLength", length ?? 0],
              ],
            ],
          ]);
        }),
      )
      .subscribe();
  }

  private identifyUser(): void {
    this.store
      .select(FromUser.selectUser)
      .pipe(
        filter(user => !!user?._id),
        map(({ email, _id, name }) => {
          this.document.defaultView["$crisp"].push(["set", "user:email", email, _id]);
          this.document.defaultView["$crisp"].push(["set", "user:nickname", name ?? email.split("@").shift()]);
        }),
      )
      .subscribe();
  }

  private initialize(): void {
    new Observable(observer => {
      interval(1000)
        .pipe(
          map(() => this.document.defaultView["$crisp"]),
          filter(crisp => !!crisp && typeof crisp.push === "function" && typeof crisp.get === "function"),
          take(1),
          timeout(10000),
        )
        .subscribe({
          next: () => {
            this.document.defaultView["$crisp"].push(["do", "chat:hide"]);
            this.document.defaultView["$crisp"].push([
              "on",
              "chat:closed",
              () => this.document.defaultView["$crisp"].push(["do", "chat:hide"]),
            ]);
            this.document.defaultView["$crisp"].push([
              "on",
              "message:received",
              () =>
                this.store.dispatch(
                  AppActions.setunreadCrispChatMesssages({
                    unreadCrispChatMesssages: this.document.defaultView["$crisp"].get("chat:unread:count"),
                  }),
                ),
            ]);
            this.store.dispatch(
              AppActions.setunreadCrispChatMesssages({
                unreadCrispChatMesssages: this.document.defaultView["$crisp"].get("chat:unread:count"),
              }),
            );
            this.identifyUser();
            this.identifyYacht();
            observer.next();
          },
          error: e => observer.error(e),
          complete: () => observer.complete(),
        });
    }).subscribe();
  }
}
