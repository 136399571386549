<ion-list class="boat-list-search">
  <ion-item
    *ngFor="let boat of boats | sortBySearch: searchTerm; let i = index; trackBy: trackBy"
    [button]="true"
    [detail]="false"
    (click)="selectBoat.emit(boat)"
    class="boat-list-search__item"
  >
    <div class="boat-list-search__item__content">
      <p class="boat-list-search__item__content__title" [innerHTML]="boat.name | matchSearch: searchTerm"></p>
      <p
        class="boat-list-search__item__content__text"
        [innerHTML]="
          'BOATS.boatModel'
            | translate
              : {
                  length: boat.length | defaultValue: 'BOATS.noLength' | translate,
                  brand: boat.brand | defaultValue: 'BOATS.noBrand' | translate,
                  _id: boat._id | defaultValue: 'BOATS.noId' | translate
                }
            | matchSearch: searchTerm
        "
      ></p>
    </div>
  </ion-item>
  <div
    *ngIf="!(boats | sortBySearch: searchTerm).length"
    class="boat-list-search__empty"
    color="primary"
    [innerHTML]="'BOATS.noBoats' | translate"
  ></div>
</ion-list>
