import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { RootState } from "src/app/store";
import * as AppActions from "src/app/store/app/actions";

@Injectable({ providedIn: "root" })
export class LoaderInterceptor implements HttpInterceptor {
  private requestsCounter: number;

  constructor(private store: Store<RootState>) {
    this.requestsCounter = 0;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(AppActions.showLoader());
    this.requestsCounter++;

    return next.handle(req).pipe(
      finalize(() => {
        this.requestsCounter--;
        if (!this.requestsCounter) {
          this.store.dispatch(AppActions.hideLoader());
        }
      }),
    );
  }
}
