<ion-list class="boat-list">
  <ng-container *ngFor="let boat of boats | sortBy; let i = index; trackBy: trackBy">
    <ion-item class="boat-list__item" [button]="true" [detail]="false" (click)="selectBoat.emit(boat)">
      <div class="boat-list__item__content">
        <p class="boat-list__item__content__title" [innerHTML]="boat.name"></p>
        <p
          class="boat-list__item__content__text"
          [innerHTML]="
            'BOATS.boatModel'
              | translate
                : {
                    length: boat.length | defaultValue: 'BOATS.noLength' | translate,
                    brand: boat.brand | defaultValue: 'BOATS.noBrand' | translate,
                    _id: boat._id | defaultValue: 'BOATS.noId' | translate
                  }
          "
        ></p>
      </div>
    </ion-item>
  </ng-container>
  <div color="primary" class="boat-list__empty" *ngIf="!boats?.length" [innerHTML]="'BOATS.noBoats' | translate"></div>
</ion-list>
