import { Pipe, PipeTransform } from "@angular/core";
import { BoatDto } from "src/app/services/api";

@Pipe({
  name: "sortBySearch",
})
export class SortBySearchPipe implements PipeTransform {
  public transform(boats: BoatDto[], searchTerm: string): BoatDto[] {
    const search = searchTerm ? searchTerm.toLowerCase() : "";

    const [nameMatch, lengthMatch, brandMatch, idMatch, noSearch] = (boats || []).reduce<BoatDto[][]>(
      (acum, boat) => {
        if (!search) acum[4].push(boat);
        else if (boat.name && boat.name.toLowerCase().includes(search)) acum[0].push(boat);
        else if (boat.length && boat.length.toString().includes(search)) acum[1].push(boat);
        else if (boat.brand && boat.brand.toLowerCase().includes(search)) acum[2].push(boat);
        else if (boat._id && boat._id.toLowerCase().includes(search)) acum[3].push(boat);
        return acum;
      },
      [[], [], [], [], []],
    );

    return [
      ...nameMatch.sort((a, b) => this.fasterMatch("name", search, a, b)),
      ...lengthMatch.sort((a, b) => this.fasterMatch("length", search, a, b)),
      ...brandMatch.sort((a, b) => this.fasterMatch("brand", search, a, b)),
      ...idMatch.sort((a, b) => this.fasterMatch("_id", search, a, b)),
      ...noSearch.sort((a, b) => this.idSort(a, b)),
    ];
  }

  private fasterMatch(boatProperty: "name" | "length" | "brand" | "_id", searchTerm: string, a: BoatDto, b: BoatDto): number {
    const charsBeforeMatchA = a[boatProperty].toString().toLowerCase().split(searchTerm)[0].length;
    const charsBeforeMatchB = b[boatProperty].toString().toLowerCase().split(searchTerm)[0].length;
    if (charsBeforeMatchA > charsBeforeMatchB) return 1;
    else if (charsBeforeMatchA < charsBeforeMatchB) return -1;
    else return this.idSort(a, b);
  }

  private idSort(a: BoatDto, b: BoatDto): number {
    if (a._id < b._id) return -1;
    else if (a._id > b._id) return 1;
    else return 0;
  }
}
