<ion-item
  (click)="!path && clickEvent.emit()"
  class="settings-item"
  [class]="'--' + textColor + '-color'"
  [class.--outlined]="outlined"
  [class.--compressed]="compressed"
  [disabled]="disabled"
  [routerLink]="path || undefined"
  [routerAnimation]="'none'"
  [routerLinkActive]="path ? '--is-active' : ''"
  [routerLinkActiveOptions]="{ exact: true }"
  [detail]="false"
>
  <div class="settings-item__content">
    <ion-icon class="settings-item__content__icon" [name]="iconName"></ion-icon>
    <div class="settings-item__content__text">
      <span class="settings-item__content__text__button" [innerHTML]="buttonText"></span>
      <span class="settings-item__content__text__detail" [innerHTML]="detailText" *ngIf="detailText"></span>
    </div>
    <ion-icon class="settings-item__content__arrow" name="chevron-forward-outline" *ngIf="arrow"></ion-icon>
  </div>
  <span class="settings-item__badge" *ngIf="badgeNumber > 0">{{ badgeNumber }}</span>
</ion-item>
