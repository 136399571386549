/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BoatUserDto { 
    _id?: string;
    /**
     * User email address.
     */
    email: string;
    /**
     * User full name.
     */
    name: string;
    /**
     * User preferred language.
     */
    language: BoatUserDto.LanguageEnum;
    /**
     * User roles
     */
    roles: Array<BoatUserDto.RolesEnum>;
}
export namespace BoatUserDto {
    export type LanguageEnum = 'en' | 'en-US' | 'en-NZ' | 'es';
    export const LanguageEnum = {
        En: 'en' as LanguageEnum,
        EnUs: 'en-US' as LanguageEnum,
        EnNz: 'en-NZ' as LanguageEnum,
        Es: 'es' as LanguageEnum
    };
    export type RolesEnum = 'OWNER' | 'OPERATOR' | 'VIEWER';
    export const RolesEnum = {
        Owner: 'OWNER' as RolesEnum,
        Operator: 'OPERATOR' as RolesEnum,
        Viewer: 'VIEWER' as RolesEnum
    };
}


