import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CreateBoatPage } from "./create-boat.page";
import { CreateBoatPageRoutingModule } from "./create-boat-routing.module";
import { TranslateModule } from "@ngx-translate/core";
import { InfoBannerComponentModule } from "src/app/components/info-banner/info-banner.component.module";
import { EmptyAnimationComponentModule } from "src/app/components/empty-animation/empty-animation.module";
import { PricingCardModule } from "src/app/components/pricing-card/pricing-card.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    CreateBoatPageRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    InfoBannerComponentModule,
    EmptyAnimationComponentModule,
    PricingCardModule,
  ],
  declarations: [CreateBoatPage],
})
export class CreateBoatPagePageModule {}
