import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as TaskNoteActions from "./actions";

export const taskNoteReducer = createReducer(
  initialState,

  on(TaskNoteActions.store, (state, { taskId, notes }) => ({
    ...state,
    taskNotes: {
      ...state.taskNotes,
      [taskId]: { notes },
    },
  })),

  on(TaskNoteActions.remove, (state, { taskId }) => ({
    ...state,
    taskNotes: {
      ...state.taskNotes,
      [taskId]: undefined,
    },
  })),
);
