import { MeterDto } from "src/app/services/api";

export const persistedKeys = ["meters"];

export interface Meters {
  [id: string]: MeterDto;
}

export interface MeterState {
  meters: Meters;
  loading: boolean;
}

export const initialState: MeterState = {
  meters: {},
  loading: false,
};
