import { createSelector } from "@ngrx/store";
import { BoatUserDto, UserDto } from "src/app/services/api";
import { RootState } from "..";
import { selectUser } from "../user/selectors";
import { Boats, BoatState } from "./state";

export const selectLoading = (state: RootState) => state.boat.loading;

export const selectBoatId = (state: RootState) => state.boat.boatId;

export const selectInviteUserError = (state: RootState) => state.boat.inviteUserError;

export const selectFeature = (state: RootState) => state.boat.boats;

export const selectcreateBoatSuccess = (state: RootState) => state.boat.createBoatSuccess;

export const selectAll = createSelector(selectFeature, boats => Object.values(boats));

export const selectOne = createSelector(selectFeature, (boats: Boats, id: string) => boats[id]);

export const selectBoatSelected = createSelector(
  (state: RootState) => state.boat,
  (boat: BoatState) => boat.boats[boat.boatId],
);

export const selectUsers = (state: RootState) => Object.values(state.boat.users);

export const selectLoggedUser = createSelector(selectUsers, selectUser, (users: BoatUserDto[], user: UserDto) =>
  users.find(u => u.email === user.email),
);

export const selectBoatSelectedSubscription = createSelector(
  (state: RootState) => state.boat,
  (boat: BoatState) => boat.subscriptions[boat.boatId],
);

export const selectAvailableSubscriptions = createSelector(
  (state: RootState) => state.boat,
  (boat: BoatState) => boat.availableSubscriptions,
);

export const selectForkBoatSuccess = (state: RootState) => state.boat.forkBoatSuccess;
