import { createAction, props } from "@ngrx/store";
import { ComponentDto, CreateComponentDto, ForkComponentDto, UpdateComponentDto } from "src/app/services/api";

export const create = createAction("[Component] Create", props<{ component: CreateComponentDto }>());

export const createSuccess = createAction("[Component] Create Success", props<{ component: ComponentDto }>());

export const createFailure = createAction("[Component] Create Failure");

export const loadAll = createAction("[Component] Load All");

export const loadAllSuccess = createAction("[Component] Load All Success", props<{ components: ComponentDto[] }>());

export const loadAllFailure = createAction("[Component] Load All Failure");

export const loadOne = createAction("[Component] Load One", props<{ id: string }>());

export const loadOneSuccess = createAction("[Component] Load One Success", props<{ component: ComponentDto }>());

export const loadOneFailure = createAction("[Component] Load One Failure");

export const update = createAction("[Component] Update", props<{ id: string; component: UpdateComponentDto }>());

export const updateSuccess = createAction("[Component] Update Success", props<{ component: ComponentDto }>());

export const updateFailure = createAction("[Component] Update Failure");

export const remove = createAction("[Component] Remove", props<{ id: string }>());

export const removeSuccess = createAction("[Component] Remove Success", props<{ id: string }>());

export const removeFailure = createAction("[Component] Remove Failure");

export const updateSearchTerm = createAction("[Component] Update Search Term", props<{ searchTerm: string }>());

export const setLoading = createAction("[Component] Set Loading", props<{ loading: boolean }>());

export const duplicate = createAction("[Component] Duplicate", props<{ componentId: string; forkComponent: ForkComponentDto }>());

export const duplicateSuccess = createAction("[Component] Duplicate Success", props<{ component: ComponentDto }>());

export const duplicateFailure = createAction("[Component] Duplicate Failure", props<{ error: string }>());

export const duplicateRemoveError = createAction("[Component] Duplicate Remove Error");

export const search = createAction("[Component] Search", props<{ body: any; limit?: number; event?: any }>());

export const searchSuccess = createAction(
  "[Component] Search Success",
  props<{ components: ComponentDto[]; searchHasNextPage: boolean; searchTotalDocs: number }>(),
);

export const searchFailure = createAction("[Component] Search Failure");

export const fork = createAction("[Component] Fork", props<{ boatId: string; componentId: string; forkComponent: ForkComponentDto }>());

export const forkSuccess = createAction("[Component] Fork Success", props<{ component: ComponentDto }>());

export const forkFailure = createAction("[Component] Fork Failure", props<{ error: string }>());

export const emptySearch = createAction("[Component] Empty Search");
