import { createSelector } from "@ngrx/store";
import { RootState } from "..";
import { selectBoatId } from "../boat/selectors";

export const selectLanguage = (state: RootState) => state.app.language;

export const selectLoading = (state: RootState) => state.app.loading;

export const selectOnline = (state: RootState) => state.app.online;

export const selectOfflineSupportEnabled = (state: RootState) => state.app.offlineSupportEnabled;

export const selectOfflineSupportEnabledByBoat = createSelector(
  selectBoatId,
  selectOfflineSupportEnabled,
  (boatId: string, offlineSupportEnabled: string[]) => offlineSupportEnabled.includes(boatId),
);

export const selectTheme = (state: RootState) => state.app.theme;

export const selectDarkModeCompatible = (state: RootState) => state.app.darkModeCompatible;

export const selectSideMenuOpened = (state: RootState) => state.app.sideMenuOpened;

export const selectHelpSlidersShowed = (state: RootState) => state.app.helpSlidersShowed;

export const selectUnreadCrispChatMesssages = (state: RootState) => state.app.unreadCrispChatMessages;
