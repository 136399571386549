import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as UserActions from "./actions";

export const userReducer = createReducer(
  initialState,

  on(
    UserActions.login,
    UserActions.register,
    UserActions.logout,
    UserActions.loadUser,
    UserActions.updateUser,
    UserActions.recoverPassword,
    UserActions.changeUserPassword,
    UserActions.requestRecoverPasswordLink,
    UserActions.sendActivationEmail,
    state => ({ ...state, loading: true }),
  ),

  on(
    UserActions.logoutSuccess,
    UserActions.logoutFailure,
    UserActions.loadUserFailure,
    UserActions.updateUserFailure,
    UserActions.autoLoginFailure,
    UserActions.autoLoginSuccess,
    UserActions.changeUserPasswordFailure,
    UserActions.changeUserPasswordSuccess,
    UserActions.requestRecoverPasswordLinkSuccess,
    UserActions.recoverPasswordSuccess,
    UserActions.sendActivationEmailSuccess,
    UserActions.sendActivationEmailFailure,
    state => ({ ...state, loading: false }),
  ),

  on(UserActions.registerSuccess, UserActions.refreshFailure, state => ({ ...state, loading: false })),

  on(UserActions.loginSuccess, (state, { tokens }) => ({
    ...state,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
    loading: false,
  })),

  on(UserActions.refreshSuccess, (state, { tokens }) => ({
    ...state,
    accessToken: tokens.accessToken,
    loading: false,
  })),

  on(UserActions.loadUserSuccess, UserActions.updateUserSuccess, (state, { user }) => ({ ...state, user, loading: false })),

  on(
    UserActions.loginFailure,
    UserActions.requestRecoverPasswordLinkFailure,
    UserActions.recoverPasswordFailure,
    UserActions.registerFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
  ),

  on(UserActions.removeLoginError, state => ({ ...state, error: undefined })),
);
