import { Component, Input } from "@angular/core";

@Component({
  selector: "app-side-menu-item",
  templateUrl: "./side-menu-item.component.html",
  styleUrls: ["./side-menu-item.component.scss"],
})
export class SideMenuItemComponent {
  @Input() public text: string;
  @Input() public icon: string;
  @Input() public path: string[];

  constructor() {}
}
