import { IonicModule } from "@ionic/angular";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SuccessPage } from "./success.page";
import { EmptyAnimationComponentModule } from "src/app/components/empty-animation/empty-animation.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [IonicModule, CommonModule, EmptyAnimationComponentModule, TranslateModule],
  declarations: [SuccessPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SuccessPageModule {}
