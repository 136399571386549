<ion-header class="success__header" [translucent]="false">
  <ion-toolbar>
    <ion-title [innerHTML]="headerTitle | translate"></ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="modalController.dismiss()" [innerHTML]="backButtonText | translate"></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false" [fullscreen]="true">
  <div class="success">
    <app-empty-animation
      class="success__lottie"
      [options]="{ path: '/assets/lotties/success.json', loop: 0 }"
      animationWidth="230px"
      animationHeight="230px"
    ></app-empty-animation>
    <h1 *ngIf="title" class="success__title" [innerHTML]="title | translate"></h1>
    <p *ngIf="text" class="success__text" [innerHTML]="text | translate"></p>
    <ion-button class="success__button" [innerHTML]="buttonText | translate" (click)="modalController.dismiss()"></ion-button>
  </div>
</ion-content>
