import { ActionReducerMap } from "@ngrx/store";
import { BoatEffects } from "./boat/effects";
import { boatReducer } from "./boat/reducer";
import { BoatState, initialState as boat } from "./boat/state";
import { calendarReducer } from "./calendar/reducer";
import { CalendarState, initialState as calendar } from "./calendar/state";
import { ComponentEffects } from "./component/effects";
import { componentReducer } from "./component/reducer";
import { ComponentState, initialState as component } from "./component/state";
import { AppEffects } from "./app/effects";
import { appReducer } from "./app/reducer";
import { AppState, initialState as app } from "./app/state";
import { filterReducer } from "./filter/reducer";
import { FilterState, initialState as filter } from "./filter/state";
import { MeterEffects } from "./meter/effects";
import { meterReducer } from "./meter/reducer";
import { MeterState, initialState as meter } from "./meter/state";
import { PeriodicTaskEffects } from "./periodic-task/effects";
import { periodicTaskReducer } from "./periodic-task/reducer";
import { PeriodicTaskState, initialState as periodicTask } from "./periodic-task/state";
import { UserEffects } from "./user/effects";
import { userReducer } from "./user/reducer";
import { UserState, initialState as user } from "./user/state";
import { TaskEventState, initialState as taskEvent } from "./task-event/state";
import { taskEventReducer } from "./task-event/reducer";
import { TaskEffects } from "./task-event/effects";
import { ResourceState, initialState as resource } from "./resource/state";
import { resourceReducer } from "./resource/reducer";
import { ResourceEffects } from "./resource/effects";
import { TaskNoteState, initialState as taskNote } from "./task-note/state";
import { taskNoteReducer } from "./task-note/reducer";
import { FilterEffects } from "./filter/effects";

export interface RootState {
  calendar: CalendarState;
  boat: BoatState;
  component: ComponentState;
  meter: MeterState;
  filter: FilterState;
  app: AppState;
  user: UserState;
  periodicTask: PeriodicTaskState;
  taskEvent: TaskEventState;
  resource: ResourceState;
  taskNote: TaskNoteState;
}

export const reducers: ActionReducerMap<RootState> = {
  calendar: calendarReducer,
  boat: boatReducer,
  component: componentReducer,
  meter: meterReducer,
  filter: filterReducer,
  app: appReducer,
  user: userReducer,
  periodicTask: periodicTaskReducer,
  taskEvent: taskEventReducer,
  resource: resourceReducer,
  taskNote: taskNoteReducer,
};

export const initialRootState: RootState = {
  boat,
  calendar,
  component,
  app,
  meter,
  user,
  filter,
  periodicTask,
  taskEvent,
  resource,
  taskNote,
};

export const rootEffects = [
  BoatEffects,
  ComponentEffects,
  MeterEffects,
  AppEffects,
  UserEffects,
  PeriodicTaskEffects,
  TaskEffects,
  ResourceEffects,
  FilterEffects,
];
