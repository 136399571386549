/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateUpmDto { 
    /**
     * UPM type.
     */
    type?: CreateUpmDto.TypeEnum;
    /**
     * Task title.
     */
    title: string;
    /**
     * UPM description.
     */
    description: string;
    /**
     * Task execution date, if the task was executed already.
     */
    executionDate?: string;
    /**
     * Task execution date, for future execution. Setting this value will generate a single-execution task.
     */
    scheduleDate?: string;
    /**
     * Task notes
     */
    notes: Array<string>;
}
export namespace CreateUpmDto {
    export type TypeEnum = 'BREAKDOWN' | 'MODIFICATION' | 'WARRANTY_CLAIM';
    export const TypeEnum = {
        Breakdown: 'BREAKDOWN' as TypeEnum,
        Modification: 'MODIFICATION' as TypeEnum,
        WarrantyClaim: 'WARRANTY_CLAIM' as TypeEnum
    };
}


