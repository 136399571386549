import { createSelector } from "@ngrx/store";
import { RootState } from "..";
import { selectBoatId } from "../boat/selectors";
import { Components } from "../component/state";
import { TaskEventCompletedDto, TaskEvents } from "./state";
import { selectFeature as selectAllComponents } from "../component/selectors";

export const selectLoading = (state: RootState) => state.taskEvent.loading;

export const selectFeature = (state: RootState) => state.taskEvent.taskEvents;

export const selectAll = createSelector(selectFeature, selectBoatId, (taskEvents: TaskEvents, boatId: string) =>
  Object.values(taskEvents).filter(taskEvent => taskEvent.payload.boat === boatId),
);

export const selectByComponent = createSelector(selectFeature, (taskEvents: TaskEvents, componentId: string) =>
  Object.values(taskEvents).filter(taskEvent => taskEvent.payload.component === componentId),
);

export const selectByTask = createSelector(selectFeature, (taskEvents: TaskEvents, taskId: string) =>
  Object.values(taskEvents).filter(taskEvent => taskEvent.payload._id === taskId),
);

export const selectBySystems = createSelector(
  selectAll,
  selectAllComponents,
  (taskEvents: TaskEventCompletedDto[], components: Components, systems: string[]) =>
    taskEvents.filter(taskEvent => systems.includes(components[taskEvent.payload.component]?.system)),
);

export const selectHasNextPage = (state: RootState) => state.taskEvent.hasNextPage;

export const selectTotalDocs = (state: RootState) => state.taskEvent.totalDocs;

export const selectPage = (state: RootState) => state.taskEvent.page;
