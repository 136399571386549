/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CurrentUserDto { 
    /**
     * User admin roles.
     */
    adminRoles?: CurrentUserDto.AdminRolesEnum;
    /**
     * User ID.
     */
    _id?: string;
    /**
     * User email address.
     */
    email: string;
    /**
     * User full name.
     */
    name: string;
    /**
     * User preferred language.
     */
    language: CurrentUserDto.LanguageEnum;
}
export namespace CurrentUserDto {
    export type AdminRolesEnum = 'GLOBAL';
    export const AdminRolesEnum = {
        Global: 'GLOBAL' as AdminRolesEnum
    };
    export type LanguageEnum = 'en' | 'en-US' | 'en-NZ' | 'es';
    export const LanguageEnum = {
        En: 'en' as LanguageEnum,
        EnUs: 'en-US' as LanguageEnum,
        EnNz: 'en-NZ' as LanguageEnum,
        Es: 'es' as LanguageEnum
    };
}


