import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BoatDto } from "src/app/services/api";

@Component({
  selector: "app-boat-list",
  templateUrl: "./boat-list.component.html",
  styleUrls: ["./boat-list.component.scss"],
})
export class BoatListComponent {
  @Input() public boats: BoatDto[];
  @Output() public selectBoat: EventEmitter<BoatDto> = new EventEmitter();

  constructor() {}

  public trackBy(boat: BoatDto, index: number): string {
    return boat._id || String(index);
  }
}
