/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

import { BoatUserDto, ComponentDto, MeterDto, ResourceDto, UserDto } from "../services/api";
import { EnvironmentName } from "../interfaces/global.interface";
import { Pm8PeriodicTaskDto, Pm8TriggerDto } from "src/app/store/periodic-task/state";
import { enNZ, enUS, enGB as en, es, Locale } from "date-fns/locale";
import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en-GB";
import localeEnNZ from "@angular/common/locales/en-NZ";
import localeEnUS from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";

registerLocaleData(localeEn);
registerLocaleData(localeEnNZ);
registerLocaleData(localeEnUS);
registerLocaleData(localeEs);

export const EnvironmentEnum = {
  local: "local" as EnvironmentName,
  qa: "qa" as EnvironmentName,
  staging: "staging" as EnvironmentName,
  production: "production" as EnvironmentName,
};

export const DEFAULT_LANGUAGE = UserDto.LanguageEnum.En;

export enum SharedRoutes {
  components = "components",
  component = "component",
  componentId = "componentId",
  planning = "planning",
  upcomingTask = "upcoming-task",
  upcomingTaskId = "upcomingTaskId",
  offlineConfig = "offline-config",
  settings = "settings",
  meters = "meters",
  login = "login",
  forgotPassword = "forgot-password",
  profile = "profile",
  changePassword = "change-password",
  activateAccount = "activate-account",
  passwordRecovery = "password-recovery",
  code = "code",
  generalConfiguration = "general-configuration",
  generalDocuments = "general-documents",
  signUp = "sign-up",
  userManagement = "user-management",
  history = "history",
  autoLogin = "auto-login",
  refreshToken = "refreshToken",
  createBoat = "create-boat",
  subscription = "subscription",
  successBillingPortal = "success-billing-portal",
  report = "report",
}

export const NoInterceptorRoutes: SharedRoutes[] = [SharedRoutes.login];

export const NoInterceptorRequests: string[] = ["refresh"];

export enum ComponentTabs {
  general = "general",
  periodicTasks = "periodic-tasks",
  upcomingTasks = "upcoming-tasks",
  history = "history",
  relatedComponents = "related-components",
}

export const ComponentTabsWithUPMButton = [
  ComponentTabs.general,
  ComponentTabs.upcomingTasks,
  ComponentTabs.history,
  ComponentTabs.relatedComponents,
];

export type ThemeColorSchemeEnum = "light" | "dark" | "auto";
export const ThemeColorSchemeEnum = {
  auto: "auto" as ThemeColorSchemeEnum,
  light: "light" as ThemeColorSchemeEnum,
  dark: "dark" as ThemeColorSchemeEnum,
};

export type selectHelpSlidersEnum = "introduction" | "component" | "plannedTask";
export const selectHelpSlidersEnum = {
  introduction: "introduction" as selectHelpSlidersEnum,
  component: "component" as selectHelpSlidersEnum,
  plannedTask: "plannedTask" as selectHelpSlidersEnum,
};

export const Systems = Object.values(ComponentDto.SystemEnum);
export const Units = Object.values(MeterDto.UnitEnum);
export const Priorities = Object.values(Pm8PeriodicTaskDto.PriorityEnum);
export const ResourceTypes = Object.values(ResourceDto.ResourceTypeEnum);
export const ResourceTags = Object.values(ResourceDto.TagsEnum);
export const TriggerTypes = Object.values(Pm8TriggerDto.TypeEnum);
export const Languages = Object.values(UserDto.LanguageEnum);
export const Themes = Object.keys(ThemeColorSchemeEnum);
export const Roles = Object.values(BoatUserDto.RolesEnum);

// All the icons listed below are cached by render them in a diplay: none div inside the tabs.page.html
export const PreRenderIcons: string[] = [
  "add-circle-outline",
  "add-circle",
  "add",
  "calendar-clear-outline",
  "alert-circle-outline",
  "checkmark-sharp",
  "chevron-down-outline",
  "chevron-forward-outline",
  "chevron-up-outline",
  "cloud-offline-outline",
  "cog-outline",
  "download",
  "ellipsis-vertical-outline",
  "filter-circle-outline",
  "language-outline",
  "layers",
  "people-circle-outline",
  "refresh-circle",
  "remove",
  "speedometer-outline",
  "swap-horizontal-outline",
  "trash-outline",
  "chatbubble-outline",
  "book-outline",
];

export const STORAGE = {
  DATA_FOLDER: "pm8-data",
};

export const RESOURCES_TO_FETCH_PARALLEL = 5;

export const locales: { [key in UserDto.LanguageEnum]: Locale } = { en, "en-NZ": enNZ, "en-US": enUS, es };

export enum UnplannedTaskStatus {
  alreadySolved = "alreadySolved",
  scheduleTask = "scheduleTask",
}

export const POST_HOG_KEY = "phc_1LI5jS7IFtqA05WjJJ8pBzeV6Ah0V2G55h6rWp1ijk7";

export const supportURL = {
  general: "https://planm8.io/support?utc_source=app",
  planning: "https://planm8.io/support/planning?utc_source=app",
  components: "https://planm8.io/support/components?utc_source=app",
  component: "https://planm8.io/support/components?utc_source=app",
  meters: "https://planm8.io/support/meters?utc_source=app",
  history: "https://planm8.io/support/history?utc_source=app",
  userManagment: "https://planm8.io/support/user-managment?utc_source=app",
  pricing: "https://planm8.io/support/pricing?utc_source=app",
  upm: "https://planm8.io/support/unplanned-tasks?utc_source=app",
};

export const pricingURL = "https://planm8.io/pricing?utc_source=app";
