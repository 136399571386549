import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as TaskEventActions from "./actions";

export const taskEventReducer = createReducer(
  initialState,

  on(TaskEventActions.loadHistory, TaskEventActions.loadHistoryByComponent, TaskEventActions.loadHistoryByTask, state => ({
    ...state,
    loading: true,
  })),

  on(
    TaskEventActions.loadHistoryFailure,
    TaskEventActions.loadHistoryByComponentFailure,
    TaskEventActions.loadHistoryByTaskFailure,
    state => ({ ...state, loading: false }),
  ),

  on(TaskEventActions.loadHistorySuccess, (state, { taskEvents, hasNextPage, totalDocs }) => {
    const newTaskEvents = {};
    taskEvents.forEach(taskEvent => (newTaskEvents[taskEvent._id] = taskEvent));

    return {
      ...state,
      taskEvents: {
        ...state.taskEvents,
        ...newTaskEvents,
      },
      page: state.page + 1,
      hasNextPage,
      totalDocs,
      loading: false,
    };
  }),

  on(TaskEventActions.loadHistoryByComponentSuccess, TaskEventActions.loadHistoryByTaskSuccess, (state, { taskEvents }) => {
    const newTaskEvents = {};
    taskEvents.forEach(taskEvent => (newTaskEvents[taskEvent._id] = taskEvent));

    return {
      ...state,
      taskEvents: {
        ...state.taskEvents,
        ...newTaskEvents,
      },
      loading: false,
    };
  }),

  on(TaskEventActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),

  on(TaskEventActions.emptyHistory, state => ({
    ...state,
    taskEvents: {},
    page: 1,
    hasNextPage: true,
    totalDocs: undefined,
  })),
);
