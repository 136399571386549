import { Inject, Injectable } from "@angular/core";
import { Observable, interval } from "rxjs";
import { distinctUntilKeyChanged, filter, first, map, switchMap, take, tap, timeout } from "rxjs/operators";
import { EnvService } from "src/app/services/env/env.service";
import { BoatDto, ComponentDto, ResourceDto, TaskDto, UserDto } from "../api";
import { Platform } from "@ionic/angular";
import * as FromUser from "src/app/store/user/selectors";
import * as FromBoat from "src/app/store/boat/selectors";
import { RootState } from "src/app/store";
import { Store } from "@ngrx/store";
import { POST_HOG_KEY } from "src/app/constants";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class PosthogService {
  public activeAnalytics: boolean;
  public user$: Observable<UserDto> = this.store.select(FromUser.selectUser);
  public boatSelected$: Observable<BoatDto> = this.store.select(FromBoat.selectBoatSelected);

  constructor(
    private readonly environmentService: EnvService,
    public readonly store: Store<RootState>,
    private readonly platform: Platform,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.activeAnalytics = ["production"].includes(this.environmentService.getVariable("name"));
    if (this.activeAnalytics) {
      this.document.defaultView["posthog"].init(POST_HOG_KEY, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        api_host: "https://eu.posthog.com",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        capture_pageview: false,
        persistence: "localStorage",
      });
      this.initialize();
    }
  }

  public sendEvent(
    name: "createResource" | "executeTask" | "unplannedTask" | "register" | "login" | "inviteUser" | "loadApp",
    attributes: {
      type?: "resource" | "createComponent" | "task" | "meter";
      taskType?: TaskDto.TypeEnum;
      resourceType?: ResourceDto.ResourceTypeEnum;
      email?: string;
      system?: ComponentDto.SystemEnum;
      forked?: boolean;
      leadOrigin?: string;
    } = {},
  ): void {
    if (this.activeAnalytics) this.document.defaultView["posthog"].capture(name, attributes);
  }

  public trackPage(): void {
    if (this.activeAnalytics) this.document.defaultView["posthog"].capture("$pageview");
  }

  public logOut(): void {
    if (this.activeAnalytics) this.document.defaultView["posthog"].reset();
  }

  public identifyWithEmail(email: string, leadOrigin: string): void {
    if (this.activeAnalytics) this.document.defaultView["posthog"].identify(email, { leadOrigin });
  }

  private getPlatform(): string {
    const posiblePaltforms = this.platform.platforms().filter(platform => this.platform.is(platform as any));
    if (posiblePaltforms.includes("mobileweb")) return "mobileweb";
    else if (posiblePaltforms.includes("pwa")) return "pwa";
    else if (posiblePaltforms.includes("hybrid")) return "hybrid";
    else if (posiblePaltforms.includes("desktop")) return "desktop";
    else return posiblePaltforms.shift();
  }

  private identifyUser(): void {
    if (this.activeAnalytics) {
      this.user$
        .pipe(
          first(user => !!user?._id),
          tap(({ _id, email, language, name }) => {
            this.document.defaultView["posthog"].identify(email, {
              email,
              name,
              language,
            });
            this.document.defaultView["posthog"].alias({ distinctId: email, alias: _id });
          }),
        )
        .subscribe();
    }
  }

  private identifyBoat(): void {
    if (this.activeAnalytics) {
      this.boatSelected$
        .pipe(
          filter(boat => !!boat?._id),
          distinctUntilKeyChanged("_id"),
          tap(({ _id, name, priceId }) =>
            this.document.defaultView["posthog"].register({
              boatId: _id,
              boatName: name,
              priceId,
            }),
          ),
        )
        .subscribe();
    }
  }

  private initialize(): void {
    new Observable(observer => {
      interval(1000)
        .pipe(
          map(() => this.document.defaultView["posthog"]),
          filter(posthog => !!posthog && typeof posthog.capture === "function"),
          take(1),
          timeout(5000),
        )
        .subscribe({
          next: () => {
            this.document.defaultView["posthog"].register({
              release: this.environmentService.getVariable<string>("release").split("#").shift(),
              platform: this.getPlatform(),
              application: "app",
            });
            this.trackPage();
            this.sendEvent("loadApp");
            this.identifyUser();
            this.identifyBoat();
            observer.next();
          },
          error: e => observer.error(e),
          complete: () => observer.complete(),
        });
    }).subscribe();
  }
}
