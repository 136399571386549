/* eslint-disable no-bitwise */
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RootState } from "src/app/store";
import { ComponentDto, ComponentService } from "../api";
import * as FromBoat from "src/app/store/boat/selectors";
import { first } from "rxjs/operators";
import { STORAGE } from "src/app/constants";
import { Directory, Filesystem } from "@capacitor/filesystem";

@Injectable({ providedIn: "root" })
export class UtilsService {
  private boatId$: Observable<string> = this.store.select(FromBoat.selectBoatId);

  constructor(private store: Store<RootState>, private readonly componentService: ComponentService) {}

  public async getComponentObjectId(): Promise<string> {
    let objectId: string;
    let exists: ComponentDto;

    return new Promise(resolve => {
      this.boatId$.pipe(first()).subscribe(async boatId => {
        while (exists) {
          objectId = this.generateObjectId();
          exists = await this.componentService
            .componentControllerFindOne({ boat: boatId, component: objectId })
            .toPromise()
            .catch(() => null);
        }

        return resolve(objectId);
      });
    });
  }

  public sleep(milis: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), milis));
  }

  public b64toBlob(b64Data: string, contentType: string = "", sliceSize: number = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public blobToB64(blob: Blob): Observable<string> {
    return new Observable(observer => {
      const reader = this.getFileReader();

      reader.onload = () => {
        const dataUrl = reader.result as string;
        const base64 = dataUrl.split(",")[1];
        observer.next(base64);
      };

      reader.onerror = err => observer.error(err);
      reader.readAsDataURL(blob);
    });
  }

  public createFolderIfNotExists(): Observable<any> {
    return new Observable(observer => {
      Filesystem.readdir({ path: STORAGE.DATA_FOLDER, directory: Directory.Data })
        .then(() => observer.next())
        .catch(() =>
          Filesystem.mkdir({ path: STORAGE.DATA_FOLDER, directory: Directory.Data, recursive: true }).then(() => observer.next()),
        );
    });
  }

  public removeFolder(): Observable<any> {
    return new Observable(observer => {
      Filesystem.rmdir({ path: STORAGE.DATA_FOLDER, directory: Directory.Data, recursive: true })
        .then(() => observer.next())
        .catch(() => observer.next());
    });
  }

  public writeFileToDisk(data: string, path: string): Observable<any> {
    return new Observable(observer => {
      Filesystem.writeFile({ data, path, directory: Directory.Data, recursive: true })
        .then(() => observer.next())
        .catch(err => observer.error(err));
    });
  }

  public removeFileFromDisk(path: string): Observable<any> {
    return new Observable(observer => {
      Filesystem.deleteFile({ path, directory: Directory.Data })
        .then(() => observer.next())
        .catch(err => observer.error(err));
    });
  }

  public generateObjectId(): string {
    const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return timestamp + "xxxxxxxxxxxxxxxx".replace(/[x]/g, _ => ((Math.random() * 16) | 0).toString(16)).toLowerCase();
  }

  private getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
    return zoneOriginalInstance || fileReader;
  }
}
