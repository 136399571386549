import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SideMenuComponent } from "./side-menu.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { SettingsItemModule } from "../settings-item/settings-item.module";
import { SideMenuItemModule } from "../side-menu-item/side-menu-item.module";
import { ReleasifyPipeModule } from "src/app/pipes/releasify/releasify.pipe.module";
import { RouterModule } from "@angular/router";
import { HideForRolesBelowDirectiveModule } from "src/app/directives/hidde-for-roles-below/hidde-for-roles-below.directive.module";
import { IsGobalAdminRoleModule } from "src/app/pipes/is-global-admin-role/is-global-admin-role.module";

@NgModule({
  declarations: [SideMenuComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule,
    SettingsItemModule,
    SideMenuItemModule,
    ReleasifyPipeModule,
    HideForRolesBelowDirectiveModule,
    IsGobalAdminRoleModule,
  ],
  exports: [SideMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SideMenuModule {}
