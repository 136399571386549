import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { TaskEventDto, TaskEventsService } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as TaskEventActions from "src/app/store/task-event/actions";
import * as FromTaskEvent from "src/app/store/task-event/selectors";
import * as FromBoat from "src/app/store/boat/selectors";

export const PAGE_LIMIT = 10;

@Injectable()
export class TaskEffects {
  public loadHistory$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskEventActions.loadHistory),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      withLatestFrom(this.store.select(FromTaskEvent.selectHasNextPage)),
      withLatestFrom(this.store.select(FromTaskEvent.selectPage)),
      filter(([[, hasNextPage]]) => hasNextPage),
      mergeMap(([[[params, boatId]], page]) =>
        this.taskService.taskHistoryControllerFilterByBoat({ ...params, boat: boatId, page }).pipe(
          map(response =>
            TaskEventActions.loadHistorySuccess({
              taskEvents: response.docs,
              hasNextPage: response["hasNextPage"],
              totalDocs: response["totalDocs"],
            }),
          ),
          catchError(() => of(TaskEventActions.loadHistoryFailure())),
        ),
      ),
    ),
  );

  public loadHistoryByComponent$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskEventActions.loadHistoryByComponent),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ componentId, page }, boatId]) =>
        this.taskService
          .taskHistoryControllerFilterByBoatComponent({
            boat: boatId,
            component: componentId,
            eventType: [TaskEventDto.EventTypeEnum.Completed],
            sortByMostRecent: "true",
            page: page || 1,
            limit: page ? PAGE_LIMIT : PAGE_LIMIT * 5,
          })
          .pipe(
            map(response => TaskEventActions.loadHistoryByComponentSuccess({ taskEvents: response.docs })),
            catchError(() => of(TaskEventActions.loadHistoryByComponentFailure())),
          ),
      ),
    ),
  );

  public loadHistoryByTask$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(TaskEventActions.loadHistoryByTask),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ componentId, taskId }, boatId]) =>
        this.taskService
          .taskHistoryControllerFilterByBoatComponentTask({
            boat: boatId,
            component: componentId,
            task: taskId,
            eventType: [TaskEventDto.EventTypeEnum.Completed],
            sortByMostRecent: "true",
          })
          .pipe(
            map(response => TaskEventActions.loadHistoryByTaskSuccess({ taskEvents: response.docs })),
            catchError(() => of(TaskEventActions.loadHistoryByTaskFailure())),
          ),
      ),
    ),
  );

  constructor(private actions$: Actions, private store: Store<RootState>, private taskService: TaskEventsService) {}
}
