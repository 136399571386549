import { createAction, props } from "@ngrx/store";
import { selectHelpSlidersEnum, ThemeColorSchemeEnum } from "src/app/constants";
import { UserDto } from "src/app/services/api";

export const changeLanguage = createAction("[App] Change Language", props<{ language: UserDto.LanguageEnum }>());

export const changeLanguageSuccess = createAction("[App] Change Language Success", props<{ language: UserDto.LanguageEnum }>());

export const showLoader = createAction("[App] Show Loader");

export const hideLoader = createAction("[App] Hide Loader");

export const updateNetworkConnection = createAction("[App] Update Network Connection", props<{ online: boolean }>());

export const fetchOfflineData = createAction("[App] Fetch Offline Data");

export const enableOfflineSupport = createAction("[App] Enable Offline Support", props<{ boatId: string }>());

export const disableOfflineSupport = createAction("[App] Disable Offline Support", props<{ boatId: string }>());

export const setTheme = createAction("[App] Set Theme", props<{ theme: ThemeColorSchemeEnum }>());

export const setThemeSuccess = createAction("[App] Set Theme Success", props<{ theme: ThemeColorSchemeEnum }>());

export const setDarkModeCompatibility = createAction("[App] Set Dark Mode Compatibility", props<{ isCompatible: boolean }>());

export const setSideMenuState = createAction("[App] Set Side Menu State", props<{ isOpen: boolean }>());

export const setHelpSliders = createAction("[App] Set Help Sliders showed", props<{ helpSlides: selectHelpSlidersEnum[] }>());

export const openCrispChat = createAction("[App] Open Crisp Chat");

export const openCrispChatSuccess = createAction("[App] Open Crisp Chat succesfully");

export const setunreadCrispChatMesssages = createAction(
  "[App] Load Unread Messages of Crisp Chat",
  props<{ unreadCrispChatMesssages: number }>(),
);
