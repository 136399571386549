import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Subscription } from "rxjs";
import { Environment, EnvService } from "src/app/services/env/env.service";

@Injectable({
  providedIn: "root",
})
export class SentryService extends ErrorHandler {
  public environment$: Subscription;

  constructor(private env: EnvService) {
    super();
    this.environment$ = this.env.get().subscribe(this.updateSentry.bind(this));
  }

  public handleError(error: any): void {
    super.handleError(error);
    try {
      if (/Loading chunk [\d]+ failed/.test(error.message)) window.location.reload();
      else Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
    console.error(error);
  }

  private updateSentry(env: Environment): void {
    const enabled = env.name !== "local";

    if (enabled) {
      Sentry.init({
        release: `planm8-app@${env.release}`,
        dsn: "https://a6f688e3f7c94cbb85b3a738d25e3ebf@o146990.ingest.sentry.io/5566856",
        environment: env.name,
        attachStacktrace: true,
        autoSessionTracking: true,
        integrations: [
          Sentry.browserTracingIntegration(),
        ],
        tracePropagationTargets: ["localhost"],
        tracesSampleRate: 1.0,
      });
    }
  }
}

