import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Observable } from "rxjs";
import { SharedRoutes } from "src/app/constants";
import { BoatDto, ResourceDto, UserDto } from "src/app/services/api";
import { Environment } from "src/app/services/env/env.service";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent {
  @Input() public avatar$: Observable<string>;
  @Input() public user$: Observable<UserDto>;
  @Input() public environment$: Observable<Environment>;
  @Input() public boatSelected$: Observable<BoatDto>;
  @Input() public loadingBoats$: Observable<boolean>;
  @Input() public logo$: Observable<ResourceDto>;
  @Input() public unreadMessages$: Observable<number>;

  @Output() public selectBoat: EventEmitter<void> = new EventEmitter();
  @Output() public deleteBoat: EventEmitter<void> = new EventEmitter();
  @Output() public createBoat: EventEmitter<void> = new EventEmitter();
  @Output() public forkBoat: EventEmitter<void> = new EventEmitter();
  @Output() public logout: EventEmitter<void> = new EventEmitter();
  @Output() public openSupport: EventEmitter<void> = new EventEmitter();
  @Output() public openChat: EventEmitter<void> = new EventEmitter();

  public routes: typeof SharedRoutes = SharedRoutes;
}
