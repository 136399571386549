import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as ComponentActions from "./actions";

export const componentReducer = createReducer(
  initialState,

  on(
    ComponentActions.create,
    ComponentActions.loadAll,
    ComponentActions.loadOne,
    ComponentActions.update,
    ComponentActions.remove,
    ComponentActions.search,
    ComponentActions.fork,
    state => ({ ...state, loading: true }),
  ),

  on(
    ComponentActions.loadAllFailure,
    ComponentActions.loadOneFailure,
    ComponentActions.createFailure,
    ComponentActions.updateFailure,
    ComponentActions.removeFailure,
    ComponentActions.searchFailure,
    state => ({ ...state, loading: false }),
  ),

  on(ComponentActions.duplicate, state => ({ ...state, loading: true, forkError: null })),

  on(ComponentActions.duplicateFailure, (state, { error }) => ({ ...state, loading: false, forkError: error })),

  on(ComponentActions.duplicateRemoveError, state => ({ ...state, forkError: null })),

  on(ComponentActions.loadAllSuccess, (state, { components }) => {
    const newComponents = {};
    components.forEach(component => (newComponents[component._id] = component));
    return {
      ...state,
      components: newComponents,
      loading: false,
    };
  }),

  on(ComponentActions.removeSuccess, (state, { id }) => {
    const components = Object.values(state.components).filter(component => component._id !== id);
    const newComponents = {};
    components.forEach(component => (newComponents[component._id] = component));

    return {
      ...state,
      components: newComponents,
      loading: false,
    };
  }),

  on(
    ComponentActions.createSuccess,
    ComponentActions.loadOneSuccess,
    ComponentActions.updateSuccess,
    ComponentActions.duplicateSuccess,
    ComponentActions.forkSuccess,
    (state, { component }) => ({
      ...state,
      components: {
        ...state.components,
        [component._id]: component,
      },
      loading: false,
    }),
  ),

  on(ComponentActions.updateSearchTerm, (state, { searchTerm }) => ({
    ...state,
    searchTerm,
  })),

  on(ComponentActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),

  on(ComponentActions.searchSuccess, (state, { components, searchHasNextPage, searchTotalDocs }) => {
    const newComponents = {};
    components.forEach(component => (newComponents[component._id] = component));

    return {
      ...state,
      searchComponents: {
        ...state.searchComponents,
        ...newComponents,
      },
      searchPage: state.searchPage + 1,
      searchHasNextPage,
      searchTotalDocs,
      loading: false,
    };
  }),

  on(ComponentActions.forkFailure, (state, { error }) => ({
    ...state,
    forkError: error,
    loading: false,
  })),

  on(ComponentActions.emptySearch, state => ({
    ...state,
    searchComponents: {},
    searchPage: 1,
    searchHasNextPage: true,
    searchTotalDocs: undefined,
  })),
);
