import { BoatDto, BoatUserDto, SubscriptionDto, SubscriptionPreviewDto } from "src/app/services/api";
import { BoatSubscription } from "./actions";

export const persistedKeys = ["boats", "boatId", "users"];

export interface Boats {
  [id: string]: BoatDto;
}

export interface Users {
  [id: string]: BoatUserDto;
}

export interface Subscriptions {
  [id: string]: BoatSubscription;
}

export interface BoatState {
  boats: Boats;
  users: Users;
  subscriptions: Subscriptions;
  availableSubscriptions: SubscriptionPreviewDto[];
  boatId: string;
  inviteUserError?: string;
  updateUserRoleError?: string;
  createBoatSuccess: boolean;
  forkBoatSuccess: boolean;
  removeUserError?: string;
  loading: boolean;
}

export const initialState: BoatState = {
  boats: {},
  users: {},
  subscriptions: {},
  availableSubscriptions: [],
  boatId: "",
  inviteUserError: null,
  createBoatSuccess: false,
  forkBoatSuccess: false,
  updateUserRoleError: null,
  removeUserError: null,
  loading: false,
};
