import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { SettingsItemComponent } from "./settings-item.component";

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule, RouterModule],
  declarations: [SettingsItemComponent],
  exports: [SettingsItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsItemModule {}
