import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as AppActions from "./actions";

export const appReducer = createReducer(
  initialState,

  on(AppActions.changeLanguageSuccess, (state, { language }) => ({ ...state, language })),

  on(AppActions.showLoader, state => ({ ...state, loading: true })),

  on(AppActions.hideLoader, state => ({ ...state, loading: false })),

  on(AppActions.updateNetworkConnection, (state, { online }) => ({ ...state, online })),

  on(AppActions.enableOfflineSupport, (state, { boatId }) => ({
    ...state,
    offlineSupportEnabled: [...new Set([...state.offlineSupportEnabled, boatId])],
  })),

  on(AppActions.disableOfflineSupport, (state, { boatId }) => ({
    ...state,
    offlineSupportEnabled: state.offlineSupportEnabled.filter(boat => boat !== boatId),
  })),

  on(AppActions.updateNetworkConnection, (state, { online }) => ({ ...state, online })),

  on(AppActions.setTheme, (state, { theme }) => ({ ...state, theme })),

  on(AppActions.setDarkModeCompatibility, (state, { isCompatible }) => ({ ...state, darkModeCompatible: isCompatible })),

  on(AppActions.setSideMenuState, (state, { isOpen }) => ({ ...state, sideMenuOpened: isOpen })),

  on(AppActions.setHelpSliders, (state, { helpSlides }) => ({
    ...state,
    helpSlidersShowed: [...new Set([...state.helpSlidersShowed, ...helpSlides])],
  })),

  on(AppActions.setunreadCrispChatMesssages, (state, { unreadCrispChatMesssages }) => ({
    ...state,
    unreadCrispChatMessages: unreadCrispChatMesssages,
  })),
);
