import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-settings-item",
  templateUrl: "./settings-item.component.html",
  styleUrls: ["./settings-item.component.scss"],
})
export class SettingsItemComponent {
  @Input() public textColor: "regular" | "warning" | "gray" | "danger" = "regular";
  @Input() public buttonText: string;
  @Input() public iconName: string;
  @Input() public path?: string[];
  @Input() public detailText: string;
  @Input() public arrow: boolean;
  @Input() public disabled: boolean;
  @Input() public outlined: boolean;
  @Input() public compressed: boolean;
  @Input() public badgeNumber: number;

  @Output() public clickEvent: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
