import { createAction, props } from "@ngrx/store";
import { AccessTokenDto, CurrentUserDto, LoginDto, RefreshAccessTokenDto, UpdatePasswordDto, UpdateUserDto } from "src/app/services/api";

export const login = createAction("[User] Login", props<{ payload: LoginDto }>());

export const loginSuccess = createAction("[User] Login Success", props<{ tokens: AccessTokenDto }>());

export const register = createAction(
  "[User] Register",
  props<{ code: string; password: string; email: string; name: string; origin: string; phone: string }>(),
);

export const registerSuccess = createAction(
  "[User] Register Success",
  props<{ email: string; origin: string; name: string; language: string; phone: string }>(),
);

export const registerFailure = createAction("[User] Register Failure", props<{ error: string }>());

export const refresh = createAction("[User] Refresh");

export const refreshSuccess = createAction("[User] Refresh Success", props<{ tokens: RefreshAccessTokenDto }>());

export const refreshFailure = createAction("[User] Refresh Failure");

export const autoLogin = createAction("[User] Auto login", props<{ refreshToken: string }>());

export const autoLoginFailure = createAction("[User] Auto login Failure");

export const autoLoginSuccess = createAction("[User] Auto login Success");

export const loginFailure = createAction("[User] Login Failure", props<{ error: string }>());

export const logout = createAction("[User] Logout", props<{ shouldNavigate: boolean }>());

export const logoutSuccess = createAction("[User] Logout Success");

export const logoutFailure = createAction("[User] Logout Failure");

export const loadUser = createAction("[User] Load User");

export const loadUserSuccess = createAction("[User] Load User Success", props<{ user: CurrentUserDto }>());

export const loadUserFailure = createAction("[User] Load User Failure");

export const updateUser = createAction("[User] Update User", props<{ user: UpdateUserDto }>());

export const updateUserSuccess = createAction("[User] Update User Success", props<{ user: CurrentUserDto }>());

export const updateUserFailure = createAction("[User] Update User Failure");

export const changeUserPassword = createAction("[User] Change User Password", props<{ payload: UpdatePasswordDto }>());

export const changeUserPasswordSuccess = createAction("[User] Change User Password Success");

export const changeUserPasswordFailure = createAction("[User] Change User Password Failure");

export const removeLoginError = createAction("[User] Remove Login Error");

export const requestRecoverPasswordLink = createAction("[User] Request Recover Password Link", props<{ email: string }>());

export const requestRecoverPasswordLinkSuccess = createAction("[User] Request Recover Password Link Success");

export const requestRecoverPasswordLinkFailure = createAction("[User] Request Recover Password Link Failure", props<{ error: string }>());

export const recoverPassword = createAction("[User] Recover Password", props<{ code: string; password: string }>());

export const recoverPasswordSuccess = createAction("[User] Recover Password Success");

export const recoverPasswordFailure = createAction("[User] Recover Password Failure", props<{ error: string }>());

export const sendActivationEmail = createAction("[User] Send Activation Email", props<{ email: string }>());

export const sendActivationEmailSuccess = createAction("[User] Send Activation Email Success");

export const sendActivationEmailFailure = createAction("[User] Send Activation Email Failure");
