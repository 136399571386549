import { Component, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable, pipe } from "rxjs";
import { SharedRoutes, pricingURL } from "src/app/constants";
import { RootState } from "src/app/store";
import * as FromBoat from "src/app/store/boat/selectors";
import * as BoatActions from "src/app/store/boat/actions";
import { CreateBoatDto, SubscriptionPreviewDto } from "src/app/services/api";
import { SentryService } from "src/app/services/sentry/sentry.service";
import { first, tap } from "rxjs/operators";
import { Browser } from "@capacitor/browser";

@Component({
  selector: "app-create-boat",
  templateUrl: "create-boat.page.html",
  styleUrls: ["create-boat.page.scss"],
})
export class CreateBoatPage implements OnInit, OnDestroy {
  public routes: typeof SharedRoutes = SharedRoutes;
  public boatForm: FormGroup;
  public loading$: Observable<boolean> = this.store.select(FromBoat.selectLoading);
  public boatId$: Observable<string> = this.store.select(FromBoat.selectBoatId);
  public success$: Observable<boolean> = this.store.select(FromBoat.selectcreateBoatSuccess);
  public kindEnum: CreateBoatDto.KindEnum[] = Object.values(CreateBoatDto.KindEnum);
  public availableSubscriptions$: Observable<SubscriptionPreviewDto[]> = this.store.select(FromBoat.selectAvailableSubscriptions);

  constructor(private store: Store<RootState>, private modalControler: ModalController, private sentry: SentryService) {}

  public ngOnInit(): void {
    this.boatForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      brand: new FormControl(null, [Validators.required]),
      model: new FormControl(null, [Validators.required]),
      kind: new FormControl(null, [Validators.required]),
      length: new FormControl(null, [Validators.required]),
      priceId: new FormControl("", [Validators.required]),
    });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(BoatActions.createSuccessReset());
  }

  public ionViewDidLeave(): void {
    this.boatForm.reset();
  }

  public createBoat(): void {
    const { name, brand, model, kind, length, priceId } = this.boatForm.value;
    this.boatId$
      .pipe(
        first(),
        tap(boatId =>
          this.store.dispatch(
            BoatActions.create({
              boat: { name, brand, model, kind, priceId, length: Math.floor(+length), avoidDefaultFork: !!boatId },
            }),
          ),
        ),
      )
      .subscribe();
  }

  public async dismissModal(): Promise<void> {
    try {
      this.boatForm.reset();
      const modal = (await this.modalControler.getTop())?.dismiss();
    } catch (e) {
      this.sentry.handleError(e);
    }
  }

  public async openPricing(): Promise<void> {
    await Browser.open({ url: pricingURL });
  }

  public fetchPricing(event: CustomEvent): void {
    const length = event.detail.value || 0;
    if (length > 0) this.store.dispatch(BoatActions.fetchAvailableSubscriptions({ length }));
  }

  public selectSubscription(priceId: string): void {
    this.boatForm.controls.priceId.patchValue(priceId);
  }
}
