import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './api/auth.service';
import { BoatService } from './api/boat.service';
import { BoatReportService } from './api/boat-report.service';
import { BoatSubscriptionService } from './api/boat-subscription.service';
import { CallbackService } from './api/callback.service';
import { ComponentService } from './api/component.service';
import { ComponentEventsService } from './api/component-events.service';
import { DefaultService } from './api/default.service';
import { HealthService } from './api/health.service';
import { MeterService } from './api/meter.service';
import { PlannedTaskService } from './api/planned-task.service';
import { ResourceService } from './api/resource.service';
import { SearchService } from './api/search.service';
import { SubscriptionService } from './api/subscription.service';
import { TaskEventsService } from './api/task-events.service';
import { TriggerService } from './api/trigger.service';
import { UPMService } from './api/upm.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
