/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TriggerDto } from './trigger-dto';
import { TaskParentDto } from './task-parent-dto';


export interface PlannedTaskDto { 
    /**
     * Linked triggers
     */
    triggers: Array<TriggerDto> | Array<string>;
    /**
     * Task id.
     */
    _id?: string;
    /**
     * Boat id
     */
    boat: string;
    /**
     * Task description.
     */
    title: string;
    /**
     * Task description.
     */
    description: string;
    /**
     * Task priority.
     */
    priority: PlannedTaskDto.PriorityEnum;
    /**
     * Task priority.
     */
    type: PlannedTaskDto.TypeEnum;
    /**
     * Component id.
     */
    component: string;
    /**
     * The identifier of the action that generates the task.
     */
    parent?: TaskParentDto;
    /**
     * When true, the task will be deleted after execution.
     */
    deleteOnExec?: boolean;
}
export namespace PlannedTaskDto {
    export type PriorityEnum = 'CRITICAL' | 'HIGH' | 'MODERATE';
    export const PriorityEnum = {
        Critical: 'CRITICAL' as PriorityEnum,
        High: 'HIGH' as PriorityEnum,
        Moderate: 'MODERATE' as PriorityEnum
    };
    export type TypeEnum = 'WARRANTY_CLAIM' | 'MODIFICATION' | 'BREAKDOWN' | 'PLANNED';
    export const TypeEnum = {
        WarrantyClaim: 'WARRANTY_CLAIM' as TypeEnum,
        Modification: 'MODIFICATION' as TypeEnum,
        Breakdown: 'BREAKDOWN' as TypeEnum,
        Planned: 'PLANNED' as TypeEnum
    };
}


