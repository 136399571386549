import { ChangeDetectionStrategy, Component, NgZone } from "@angular/core";
import { ActionSheetController, AlertController, ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { RootState } from "./store";
import * as AppActions from "src/app/store/app/actions";
import { Location } from "@angular/common";
import { selectHelpSlidersEnum, SharedRoutes, supportURL, ThemeColorSchemeEnum } from "./constants";
import * as BoatActions from "src/app/store/boat/actions";
import * as UserActions from "src/app/store/user/actions";
import * as qs from "qs";
import { BehaviorSubject, Observable } from "rxjs";
import { BoatDto, ResourceDto, UserDto } from "./services/api";
import * as FromUser from "src/app/store/user/selectors";
import * as FromBoat from "src/app/store/boat/selectors";
import { Environment, EnvService } from "./services/env/env.service";
import { TranslateService } from "@ngx-translate/core";
import { filter, first, map, tap, withLatestFrom } from "rxjs/operators";
import { ActivationEnd, Router } from "@angular/router";
import * as FromApp from "src/app/store/app/selectors";
import * as FromResource from "src/app/store/resource/selectors";
import { CreateBoatPage } from "./pages/create-boat/create-boat.page";
import { HelpSliderPage } from "./modals/help-sliders/help-sliders.page";
import { SplashScreen } from "@capacitor/splash-screen";
import { Network } from "@capacitor/network";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { SwitchYachtPage, SwitchYachtPageInputs } from "./modals/switch-yacht/switch-yacht.page";
import { ForkBoatPage } from "./pages/fork-boat/fork-boat.page";
import { Browser } from "@capacitor/browser";
import { register } from "swiper/element/bundle";

register();
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public routes: typeof SharedRoutes = SharedRoutes;
  public user$: Observable<UserDto> = this.store.select(FromUser.selectUser);
  public avatar$: Observable<string> = this.store.select(FromUser.selectUserAvatar);
  public boatSelected$: Observable<BoatDto> = this.store.select(FromBoat.selectBoatSelected);
  public environment$: BehaviorSubject<Environment> = this.envService.get();
  public loadingBoats$: Observable<boolean> = this.store.select(FromBoat.selectLoading);
  public selecthelpSliders$: Observable<selectHelpSlidersEnum[]> = this.store.select(FromApp.selectHelpSlidersShowed);
  public unreadMessages$: Observable<number> = this.store.select(FromApp.selectUnreadCrispChatMesssages);
  public theme$: Observable<ThemeColorSchemeEnum> = this.store.select(FromApp.selectTheme);
  public logo$: Observable<ResourceDto>;
  public hideSideMenu: boolean;

  private boats$: Observable<BoatDto[]> = this.store.select(FromBoat.selectAll);

  constructor(
    private readonly store: Store<RootState>,
    private readonly platform: Platform,
    private readonly navController: NavController,
    private readonly zone: NgZone,
    private readonly location: Location,
    private readonly envService: EnvService,
    private readonly alertController: AlertController,
    private readonly actionSheetController: ActionSheetController,
    private readonly t: TranslateService,
    private readonly router: Router,
    private readonly modalController: ModalController,
  ) {
    this.initializeApp();
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.hideSideMenu = event.snapshot.data.hideSideMenu;
      }
    });
  }

  public selectBoat(): void {
    this.store.dispatch(BoatActions.refreshBoats());

    const loadingSubscribtion$ = this.loadingBoats$
      .pipe(
        filter(loading => !loading),
        tap(() => {
          const boatsSubscribtion$ = this.boats$
            .pipe(
              filter(boats => !!boats.length),
              withLatestFrom(this.store.select(FromBoat.selectBoatId)),
              map(async ([boats, boatId]) => {
                if (boats.length > 5) await this.openModal(boats, boatId);
                else await this.openActionSheet(boats, boatId);
                boatsSubscribtion$.unsubscribe();
                loadingSubscribtion$.unsubscribe();
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  public async createBoat(): Promise<void> {
    const modal = await this.modalController.create({
      component: CreateBoatPage,
      backdropDismiss: true,
    });
    await modal.present();
  }

  public async forkBoat(): Promise<void> {
    this.boatSelected$
      .pipe(
        first(),
        tap(async boatToFork => {
          const modal = await this.modalController.create({
            component: ForkBoatPage,
            backdropDismiss: true,
            componentProps: { boatToFork },
          });
          await modal.present();
        }),
      )
      .subscribe();
  }

  public async deleteBoat(): Promise<void> {
    this.boatSelected$
      .pipe(
        first(),
        tap(boat => this.store.dispatch(BoatActions.remove({ boat }))),
      )
      .subscribe();
  }

  public splitPaneVisibleChanged(isOpen: boolean): void {
    this.store.dispatch(AppActions.setSideMenuState({ isOpen }));
  }

  public async logout(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.t.instant("SETTINGS.logoutHeader"),
      message: this.t.instant("SETTINGS.logoutMessage"),
      buttons: [
        {
          text: this.t.instant("SETTINGS.dismiss"),
          role: "cancel",
        },
        {
          text: this.t.instant("SETTINGS.logout"),
          cssClass: ["alert-danger-button"],
          handler: () => this.store.dispatch(UserActions.logout({ shouldNavigate: true })),
        },
      ],
    });

    await alert.present();
  }

  public async openSupport(): Promise<void> {
    await Browser.open({ url: supportURL.general });
  }

  public openChat(): void {
    this.store.dispatch(AppActions.openCrispChat());
  }

  private async openActionSheet(boats: BoatDto[], boatId: string): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      header: this.t.instant("SETTINGS.switchYacht"),
      mode: "ios",
      buttons: [
        ...boats.map(boat => ({
          text: boat.name,
          role: boat._id === boatId ? "selected" : undefined,
          handler: () => {
            this.store.dispatch(BoatActions.setBoatId({ boatId: boat._id }));
            const parts = this.router.url.slice(1).split("/");
            if (parts.length > 1) {
              this.navController.navigateRoot(parts.shift());
            }
          },
        })),
        {
          text: this.t.instant("SETTINGS.dismiss"),
          role: "cancel",
        },
      ],
    });
    await actionSheet.present();
  }

  private async openModal(boats: BoatDto[], boatId: string): Promise<void> {
    const componentProps: SwitchYachtPageInputs = {
      boats,
      boatId,
    };
    const modal = await this.modalController.create({
      component: SwitchYachtPage,
      presentingElement: await this.modalController.getTop(),
      backdropDismiss: true,
      componentProps,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) this.store.dispatch(BoatActions.setBoatId({ boatId: data }));
  }

  private initializeApp(): void {
    const webUrl = new URL(window.location.toString());
    const boatId = webUrl.searchParams.get("boat-id");
    if (boatId) this.store.dispatch(BoatActions.setBoatIdWithRefresh({ boatId }));

    this.platform.ready().then(() => {
      App.addListener("appUrlOpen", (data: URLOpenListenerEvent) =>
        this.zone.run(() => {
          const { pathname, search } = new URL(data.url);
          this.navController.navigateRoot(pathname, { queryParams: qs.parse(search) });
        }),
      );

      App.addListener("backButton", () => {
        const path = this.location.path();
        if (
          [`/${SharedRoutes.login}`, `/${SharedRoutes.components}`, `/${SharedRoutes.planning}`, `/${SharedRoutes.settings}`].includes(path)
        ) {
          App.exitApp();
        }
      });

      this.selecthelpSliders$.pipe(first(), withLatestFrom(this.store.select(FromUser.selectUser))).subscribe(async ([slides, user]) => {
        if (!user?._id && !slides?.includes(selectHelpSlidersEnum.introduction)) {
          const modal = await this.modalController.create({
            component: HelpSliderPage,
            backdropDismiss: true,
            componentProps: { slidesToShow: selectHelpSlidersEnum.introduction },
          });
          await modal.present();
        }
      });
      SplashScreen.hide();
    });

    Network.addListener("networkStatusChange", ({ connected }) =>
      this.store.dispatch(AppActions.updateNetworkConnection({ online: connected })),
    );

    this.theme$.pipe().subscribe(theme => (this.logo$ = this.store.select(FromResource.selectLogo, theme)));
  }
}
