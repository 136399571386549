import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { InfoBannerComponent } from "./info-banner.component";

@NgModule({
  imports: [CommonModule, IonicModule, TranslateModule],
  declarations: [InfoBannerComponent],
  exports: [InfoBannerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InfoBannerComponentModule {}
