/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReportFilters { 
    /**
     * Return only historical data after this date
     */
    startDate: string;
    /**
     * Return only historical data before this date
     */
    endDate: string;
    /**
     * Exclude selected historical data.
     */
    excludeHistories?: Array<ReportFilters.ExcludeHistoriesEnum>;
    /**
     * Exclude selected data.
     */
    excludeDescriptions?: Array<ReportFilters.ExcludeDescriptionsEnum>;
    /**
     * Exclude components and meters for selected systems.
     */
    excludeSystems?: Array<ReportFilters.ExcludeSystemsEnum>;
    /**
     * Hide executor name.
     */
    anonymizeExecutor?: boolean;
    /**
     * When true, only components with task executions will be shown.
     */
    onlyComponentWithActivity?: boolean;
}
export namespace ReportFilters {
    export type ExcludeHistoriesEnum = 'PLANNED_TASK' | 'PLANNED_TASK_MONTHLY' | 'PLANNED_TASK_DAILY' | 'UNPLANNED_TASK' | 'METERS';
    export const ExcludeHistoriesEnum = {
        PlannedTask: 'PLANNED_TASK' as ExcludeHistoriesEnum,
        PlannedTaskMonthly: 'PLANNED_TASK_MONTHLY' as ExcludeHistoriesEnum,
        PlannedTaskDaily: 'PLANNED_TASK_DAILY' as ExcludeHistoriesEnum,
        UnplannedTask: 'UNPLANNED_TASK' as ExcludeHistoriesEnum,
        Meters: 'METERS' as ExcludeHistoriesEnum
    };
    export type ExcludeDescriptionsEnum = 'PLANNED_TASK';
    export const ExcludeDescriptionsEnum = {
        PlannedTask: 'PLANNED_TASK' as ExcludeDescriptionsEnum
    };
    export type ExcludeSystemsEnum = 'UNCATEGORIZED' | 'HULL_SUPERSTRUCTURE' | 'MAIN_ENGINES_GEARBOXES' | 'GENERATORS' | 'DRIVELINE' | 'STEERING' | 'HYDRAULICS' | 'FUEL_SYSTEM' | 'FIRE_SYSTEM' | 'HEATING_AC' | 'PNEUMATICS' | 'BILGE_SYSTEM' | 'FRIDGES_FREEZERS' | 'FRESH_WATER' | 'GREY_WATER' | 'BLACK_WATER' | 'VENTILATION' | 'LUBE_WASTE_OIL' | 'ELECTRICAL' | 'DC_ELECTRICAL' | 'AC_ELECTRICAL_APPLIANCES' | 'APPLIANCES' | 'NAVIGATION_COMMUNICATION' | 'ANCHORING_LIFTING_GEAR' | 'DECK_HARDWARE_FITTINGS' | 'SAFETY_LIFE_SAVING_EQUIPMENT' | 'TENDERS_&_TOYS' | 'FIXED_RIGGING' | 'RUNNING_RIGGING' | 'SALLS' | 'MISCELLANEOUS';
    export const ExcludeSystemsEnum = {
        Uncategorized: 'UNCATEGORIZED' as ExcludeSystemsEnum,
        HullSuperstructure: 'HULL_SUPERSTRUCTURE' as ExcludeSystemsEnum,
        MainEnginesGearboxes: 'MAIN_ENGINES_GEARBOXES' as ExcludeSystemsEnum,
        Generators: 'GENERATORS' as ExcludeSystemsEnum,
        Driveline: 'DRIVELINE' as ExcludeSystemsEnum,
        Steering: 'STEERING' as ExcludeSystemsEnum,
        Hydraulics: 'HYDRAULICS' as ExcludeSystemsEnum,
        FuelSystem: 'FUEL_SYSTEM' as ExcludeSystemsEnum,
        FireSystem: 'FIRE_SYSTEM' as ExcludeSystemsEnum,
        HeatingAc: 'HEATING_AC' as ExcludeSystemsEnum,
        Pneumatics: 'PNEUMATICS' as ExcludeSystemsEnum,
        BilgeSystem: 'BILGE_SYSTEM' as ExcludeSystemsEnum,
        FridgesFreezers: 'FRIDGES_FREEZERS' as ExcludeSystemsEnum,
        FreshWater: 'FRESH_WATER' as ExcludeSystemsEnum,
        GreyWater: 'GREY_WATER' as ExcludeSystemsEnum,
        BlackWater: 'BLACK_WATER' as ExcludeSystemsEnum,
        Ventilation: 'VENTILATION' as ExcludeSystemsEnum,
        LubeWasteOil: 'LUBE_WASTE_OIL' as ExcludeSystemsEnum,
        Electrical: 'ELECTRICAL' as ExcludeSystemsEnum,
        DcElectrical: 'DC_ELECTRICAL' as ExcludeSystemsEnum,
        AcElectricalAppliances: 'AC_ELECTRICAL_APPLIANCES' as ExcludeSystemsEnum,
        Appliances: 'APPLIANCES' as ExcludeSystemsEnum,
        NavigationCommunication: 'NAVIGATION_COMMUNICATION' as ExcludeSystemsEnum,
        AnchoringLiftingGear: 'ANCHORING_LIFTING_GEAR' as ExcludeSystemsEnum,
        DeckHardwareFittings: 'DECK_HARDWARE_FITTINGS' as ExcludeSystemsEnum,
        SafetyLifeSavingEquipment: 'SAFETY_LIFE_SAVING_EQUIPMENT' as ExcludeSystemsEnum,
        TendersToys: 'TENDERS_&_TOYS' as ExcludeSystemsEnum,
        FixedRigging: 'FIXED_RIGGING' as ExcludeSystemsEnum,
        RunningRigging: 'RUNNING_RIGGING' as ExcludeSystemsEnum,
        Salls: 'SALLS' as ExcludeSystemsEnum,
        Miscellaneous: 'MISCELLANEOUS' as ExcludeSystemsEnum
    };
}


