import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BoatDto } from "src/app/services/api";

@Component({
  selector: "app-boat-list-search",
  templateUrl: "./boat-list-search.component.html",
  styleUrls: ["./boat-list-search.component.scss"],
})
export class BoatListSearchComponent {
  @Input() public boats: BoatDto[];
  @Input() public searchTerm: string;
  @Output() public selectBoat: EventEmitter<BoatDto> = new EventEmitter();

  constructor() {}

  public trackBy(boat: BoatDto, index: number): string {
    return boat._id || String(index);
  }
}
