/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ResourceDto { 
    /**
     * The resource id
     */
    _id: string;
    /**
     * The boat id
     */
    boat: string;
    /**
     * The name for this resource
     */
    name?: string;
    /**
     * If this resource is linked to an entity, this will be the id for the linked entity
     */
    reference?: string;
    /**
     * If this resource is linked to an entity, this will be the type for the linked entity
     */
    referenceType?: ResourceDto.ReferenceTypeEnum;
    /**
     * If this resource is linked to an entity, this will be the type for the linked entity
     */
    resourceType: ResourceDto.ResourceTypeEnum;
    /**
     * This contains the resource data, being this field a link to access the resource or a payload containing the text
     */
    value?: string;
    /**
     * A list with the defined resource\'s tags
     */
    tags: Array<ResourceDto.TagsEnum>;
    /**
     * This will hold the resource\'s MimeType
     */
    format: string;
    /**
     * An object with no defined structure containing the user-defined metadata for this resource
     */
    metadata?: object;
}
export namespace ResourceDto {
    export type ReferenceTypeEnum = 'AUTH' | 'BOAT' | 'COMPONENT' | 'TASK' | 'EXECUTION' | 'UPM' | 'TRIGGER' | 'RESOURCE' | 'METER' | 'EMAIL' | 'USER' | 'CRON';
    export const ReferenceTypeEnum = {
        Auth: 'AUTH' as ReferenceTypeEnum,
        Boat: 'BOAT' as ReferenceTypeEnum,
        Component: 'COMPONENT' as ReferenceTypeEnum,
        Task: 'TASK' as ReferenceTypeEnum,
        Execution: 'EXECUTION' as ReferenceTypeEnum,
        Upm: 'UPM' as ReferenceTypeEnum,
        Trigger: 'TRIGGER' as ReferenceTypeEnum,
        Resource: 'RESOURCE' as ReferenceTypeEnum,
        Meter: 'METER' as ReferenceTypeEnum,
        Email: 'EMAIL' as ReferenceTypeEnum,
        User: 'USER' as ReferenceTypeEnum,
        Cron: 'CRON' as ReferenceTypeEnum
    };
    export type ResourceTypeEnum = 'IMAGE' | 'FILE' | 'URL' | 'SERIALNO';
    export const ResourceTypeEnum = {
        Image: 'IMAGE' as ResourceTypeEnum,
        File: 'FILE' as ResourceTypeEnum,
        Url: 'URL' as ResourceTypeEnum,
        Serialno: 'SERIALNO' as ResourceTypeEnum
    };
    export type TagsEnum = 'LOGO' | 'LOCATION' | 'ATTACHMENT' | 'ASYNC';
    export const TagsEnum = {
        Logo: 'LOGO' as TagsEnum,
        Location: 'LOCATION' as TagsEnum,
        Attachment: 'ATTACHMENT' as TagsEnum,
        Async: 'ASYNC' as TagsEnum
    };
}


