import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { IsGobalAdminRole } from "./is-global-admin-role.pipe";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [IsGobalAdminRole],
  exports: [IsGobalAdminRole],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IsGobalAdminRoleModule {}
