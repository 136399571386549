<ion-header class="create-boat__header ion-no-border" [translucent]="false">
    <ion-toolbar class="ion-no-border">
        <ion-buttons slot="end">
            <ion-button *ngIf="boatId$ | async" [innerHTML]="'CREATE_BOAT.dismiss' | translate" (click)="dismissModal()"></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="create-boat__content">
    <div class="create-boat__content__form-wrapper">
        <form
            *ngIf="!(success$ | async)"
            class="create-boat__content__form-wrapper__form"
            [formGroup]="boatForm"
            (keyup.enter)="boatForm.valid && createBoat()"
        >
            <ion-title class="big" [innerHTML]="'CREATE_BOAT.newBoat' | translate"></ion-title>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.name' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="next"
                    formControlName="name"
                    inputmode="text"
                    required="true"
                    type="text"
                ></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.kind' | translate"></ion-label>
                <ion-select
                    enterkeyhint="next"
                    formControlName="kind"
                    required="true"
                    [label]="'CREATE_BOAT.kind' | translate"
                    [placeholder]="'CREATE_BOAT.kindPlaceholder' | translate"
                >
                    <ion-select-option
                        *ngFor="let kind of kindEnum"
                        [innerHTML]="'CREATE_BOAT.' + kind | translate"
                        [value]="kind"
                    ></ion-select-option>
                </ion-select>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.brand' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="next"
                    formControlName="brand"
                    inputmode="text"
                    required="true"
                    type="text"
                ></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.model' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="next"
                    formControlName="model"
                    inputmode="text"
                    required="true"
                    type="text"
                ></ion-input>
            </ion-item>
            <ion-item>
                <ion-label class="label" position="stacked" [innerHTML]="'CREATE_BOAT.length' | translate"></ion-label>
                <ion-input
                    class="input"
                    enterkeyhint="send"
                    formControlName="length"
                    inputmode="numeric"
                    required="true"
                    type="number"
                    [max]="100"
                    [min]="5"
                    (ionChange)="fetchPricing($event)"
                ></ion-input>
            </ion-item>

            <div class="create-boat__content__pricings">
                <ion-title class="small" [innerHTML]="'FORK_BOAT.chooseSubscription' | translate"></ion-title>
                <ion-spinner *ngIf="loading$ | async" class="create-boat__content__pricings__spinner"></ion-spinner>
                <span
                    *ngIf="(availableSubscriptions$ | async).length === 0 && !(loading$ | async)"
                    class="create-boat__content__pricings__empty-message"
                    [innerHTML]="'FORK_BOAT.chooseLenght' | translate"
                ></span>
                <app-pricing-card
                    *ngFor="let subscription of availableSubscriptions$ | async"
                    [isSelected]="subscription.priceId === boatForm.value.priceId"
                    [subscription]="subscription"
                    (selectSubscription)="selectSubscription($event.priceId)"
                ></app-pricing-card>

                <a class="create-boat__content__pricings__link" (click)="openPricing()">
                    {{ "CREATE_BOAT.moreInfo" | translate }}
                    <ion-icon name="open-outline"></ion-icon>
                </a>
            </div>
            <ion-button
                class="create-boat__content__form-wrapper__button"
                [disabled]="boatForm.invalid || (loading$ | async)"
                [innerHTML]="'CREATE_BOAT.createButton' | translate"
                (click)="createBoat()"
            ></ion-button>
            <ion-button
                *ngIf="boatId$ | async"
                class="create-boat__content__form-wrapper__button --text"
                routerDirection="forward"
                [innerHTML]="'CREATE_BOAT.skipForm' | translate"
                [routerLink]="['/']"
            ></ion-button>
        </form>
    </div>

    <div *ngIf="success$ | async" class="create-boat__content__success">
        <app-empty-animation
            animationHeight="230px"
            animationWidth="230px"
            class="create-boat__content__success__lottie"
            [options]="{ path: '/assets/lotties/success.json', loop: 0 }"
        ></app-empty-animation>
        <span class="create-boat__content__success__text" [innerHTML]="'CREATE_BOAT.messageSuccessAlert' | translate"></span>
        <ion-button
            class="create-boat__content__success__button"
            [innerHTML]="'CREATE_BOAT.buttonSuccessAlert' | translate"
            (click)="dismissModal()"
        ></ion-button>
    </div>
</ion-content>
