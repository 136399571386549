import { Pipe, PipeTransform } from "@angular/core";
import { BoatDto } from "src/app/services/api";

@Pipe({
  name: "sortBy",
})
export class SortByPipe implements PipeTransform {
  public transform(boats: BoatDto[] = []): BoatDto[] {
    return (boats || []).sort((boatA: BoatDto, boatB: BoatDto) => boatA.name.localeCompare(boatB.name));
  }
}
