import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as CalendarActions from "./actions";
import { startOfDay } from "date-fns";

export const calendarReducer = createReducer(
  initialState,

  on(CalendarActions.setDate, (state, { date }) => ({
    ...state,
    date: date < Date.now() ? startOfDay(new Date()).getTime() : startOfDay(new Date(date)).getTime(),
  })),
);
