import { DEFAULT_LANGUAGE, selectHelpSlidersEnum, ThemeColorSchemeEnum } from "src/app/constants";
import { UserDto } from "src/app/services/api";

export const persistedKeys = ["language", "online", "offlineSupportEnabled", "theme", "helpSlidersShowed"];

export interface AppState {
  language: UserDto.LanguageEnum;
  loading: boolean;
  online: boolean;
  offlineSupportEnabled: string[];
  theme: ThemeColorSchemeEnum;
  darkModeCompatible: boolean;
  sideMenuOpened: boolean;
  helpSlidersShowed: selectHelpSlidersEnum[];
  unreadCrispChatMessages: number;
}

export const initialState: AppState = {
  language: DEFAULT_LANGUAGE,
  loading: false,
  online: true,
  offlineSupportEnabled: [],
  theme: ThemeColorSchemeEnum.auto,
  darkModeCompatible: true,
  sideMenuOpened: false,
  helpSlidersShowed: [],
  unreadCrispChatMessages: 0,
};
