import { createAction, props } from "@ngrx/store";
import { MeterDto } from "src/app/services/api";

export const create = createAction("[Meter] Create", props<{ meter: MeterDto }>());

export const createSuccess = createAction("[Meter] Create Success", props<{ meter: MeterDto }>());

export const createFailure = createAction("[Meter] Create Failure");

export const loadAll = createAction("[Meter] Load All");

export const loadAllSuccess = createAction("[Meter] Load All Success", props<{ meters: MeterDto[] }>());

export const loadAllFailure = createAction("[Meter] Load All Failure");

export const loadOne = createAction("[Meter] Load One", props<{ id: string }>());

export const loadOneSuccess = createAction("[Meter] Load One Success", props<{ meter: MeterDto }>());

export const loadOneFailure = createAction("[Meter] Load One Failure");

export const update = createAction("[Meter] Update", props<{ id: string; meter: MeterDto }>());

export const updateSuccess = createAction("[Meter] Update Success", props<{ meter: MeterDto }>());

export const updateFailure = createAction("[Meter] Update Failure");

export const updateCurrentValue = createAction("[Meter] Update Current Value", props<{ id: string; currentValue: number }>());

export const updateCurrentValueSuccess = createAction(
  "[Meter] Update Current Value Success",
  props<{ id: string; currentValue: number; lastReadDate: string }>(),
);

export const updateCurrentValueFailure = createAction("[Meter] Update Current Value Failure");

export const remove = createAction("[Meter] Remove", props<{ id: string }>());

export const removeSuccess = createAction("[Meter] Remove Success", props<{ id: string }>());

export const removeFailure = createAction("[Meter] Remove Failure");
