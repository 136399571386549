import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SubscriptionPreviewDto } from "src/app/services/api";

@Component({
  selector: "app-pricing-card",
  templateUrl: "./pricing-card.component.html",
  styleUrls: ["./pricing-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingCardComponent {
  @Input() public subscription: SubscriptionPreviewDto;
  @Input() public isSelected: boolean;

  @Output() public selectSubscription: EventEmitter<SubscriptionPreviewDto> = new EventEmitter();
}
