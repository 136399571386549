import { Store } from "@ngrx/store";
import { map, tap } from "rxjs/operators";
import * as AppActions from "src/app/store/app/actions";
import * as FromApp from "src/app/store/app/selectors";
import * as FromBoat from "src/app/store/boat/selectors";
import { SwUpdateService } from "../sw-updater/sw-updater.service";

export function initApplication(_: any, store: Store, swUpdater: SwUpdateService): Function {
  return () => {
    store
      .select(FromApp.selectLanguage)
      .pipe(
        map(language => store.dispatch(AppActions.changeLanguage({ language }))),
        tap(() => swUpdater.checkForUpdate()),
      )
      .subscribe();

    store
      .select(FromApp.selectTheme)
      .pipe(map(theme => store.dispatch(AppActions.setTheme({ theme }))))
      .subscribe();

    store.select(FromBoat.selectBoatId).subscribe(boatId => {
      if (boatId) store.dispatch(AppActions.fetchOfflineData());
    });
  };
}
