import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { RootState } from "../store";
import * as FromUser from "src/app/store/user/selectors";
import { first, switchMap } from "rxjs/operators";
import { SharedRoutes } from "../constants";

@Injectable({ providedIn: "root" })
export class LoginGuard implements CanActivate {
  public routes: typeof SharedRoutes = SharedRoutes;

  constructor(private store: Store<RootState>, private router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(FromUser.selectAccessToken).pipe(
      first(),
      switchMap(accessToken => {
        if (accessToken) return of(true);
        return of(this.router.parseUrl(this.routes.login));
      }),
    );
  }
}
