import { RootState } from "..";

export const selectLoading = (state: RootState) => state.user.loading;

export const selectUser = (state: RootState) => state.user.user;

export const selectUserAvatar = (state: RootState) => {
  const splitter = state.user.user?.name?.includes("@") ? "@" : " ";
  const nameParts = state.user.user?.name?.split(splitter);
  const initials = nameParts?.map(part => part[0]);
  return initials?.slice(0, 2).join("").toUpperCase();
};

export const selectAccessToken = (state: RootState) => state.user.accessToken;

export const selectRefreshToken = (state: RootState) => state.user.refreshToken;

export const selectError = (state: RootState) => state.user.error;
