import { createReducer, on } from "@ngrx/store";
import { initialState, PeriodicTaskState } from "./state";
import * as PeriodicTasksActions from "./actions";
import { Pm8TriggerDto } from "src/app/store/periodic-task/state";
import { RRule, rrulestr } from "rrule";

export const periodicTaskReducer = createReducer(
  initialState,

  on(
    PeriodicTasksActions.loadAll,
    PeriodicTasksActions.loadAllByComponent,
    PeriodicTasksActions.loadOneByComponent,
    (state: PeriodicTaskState) => ({ ...state, getLoading: true }),
  ),

  on(
    PeriodicTasksActions.create,
    PeriodicTasksActions.update,
    PeriodicTasksActions.remove,
    PeriodicTasksActions.createUnplannedTask,
    PeriodicTasksActions.execute,
    PeriodicTasksActions.removeTrigger,
    PeriodicTasksActions.postpone,
    (state: PeriodicTaskState) => ({ ...state, postLoading: true }),
  ),

  on(
    PeriodicTasksActions.loadAllFailure,
    PeriodicTasksActions.loadAllByComponentFailure,
    PeriodicTasksActions.loadOneByComponentFailure,
    (state: PeriodicTaskState) => ({ ...state, getLoading: false }),
  ),

  on(
    PeriodicTasksActions.createFailure,
    PeriodicTasksActions.updateFailure,
    PeriodicTasksActions.removeFailure,
    PeriodicTasksActions.createUnplannedTaskSuccess,
    PeriodicTasksActions.createUnplannedTaskFailure,
    PeriodicTasksActions.executeFailure,
    PeriodicTasksActions.createTriggerFailure,
    PeriodicTasksActions.updateTriggerFailure,
    PeriodicTasksActions.removeTriggerFailure,
    PeriodicTasksActions.postponeSuccess,
    PeriodicTasksActions.postponeFailure,
    (state: PeriodicTaskState) => ({ ...state, postLoading: false }),
  ),

  on(PeriodicTasksActions.executeSuccess, (state: PeriodicTaskState, { id }) => {
    const triggers = [...state.periodicTasks[id].triggers];

    return {
      ...state,
      periodicTasks: {
        ...state.periodicTasks,
        [id]: {
          ...state.periodicTasks[id],
          triggers: triggers.map(trigger => {
            let nextExecution: string | number;
            if (trigger.type === "DATE") {
              const dtstart = new Date();
              const recurrence = rrulestr(trigger.recurrence.toString());
              const rrule = new RRule({ dtstart, freq: recurrence.options.freq, interval: recurrence.options.interval });
              nextExecution = rrule.after(dtstart).toISOString();
            } else {
              nextExecution = Number(trigger.nextExecution) + Number(trigger.recurrence);
            }
            return { ...trigger, nextExecution };
          }),
        },
      },
      postLoading: false,
    };
  }),

  on(PeriodicTasksActions.loadAllSuccess, (state: PeriodicTaskState, { periodicTasks }) => {
    const newPeriodicTasks = {};
    periodicTasks.forEach(periodicTask => (newPeriodicTasks[periodicTask._id] = periodicTask));
    return {
      ...state,
      periodicTasks: newPeriodicTasks,
      getLoading: false,
    };
  }),

  on(PeriodicTasksActions.loadAllByComponentSuccess, (state: PeriodicTaskState, { periodicTasks }) => {
    const newPeriodicTasks = {};
    periodicTasks.forEach(periodicTask => (newPeriodicTasks[periodicTask._id] = periodicTask));
    return {
      ...state,
      periodicTasks: {
        ...state.periodicTasks,
        ...newPeriodicTasks,
      },
      getLoading: false,
    };
  }),

  on(PeriodicTasksActions.removeSuccess, (state: PeriodicTaskState, { id }) => {
    const periodicTasks = Object.values(state.periodicTasks).filter(periodicTask => periodicTask._id !== id);
    const newPeriodicTasks = {};
    periodicTasks.forEach(periodicTask => (newPeriodicTasks[periodicTask._id] = periodicTask));

    return {
      ...state,
      periodicTasks: newPeriodicTasks,
      postLoading: false,
    };
  }),

  on(PeriodicTasksActions.loadOneByComponentSuccess, (state: PeriodicTaskState, { periodicTask }) => ({
    ...state,
    periodicTasks: {
      ...state.periodicTasks,
      [periodicTask._id]: {
        ...state.periodicTasks[periodicTask._id],
        _id: periodicTask._id,
        title: periodicTask.title,
        description: periodicTask.description,
        priority: periodicTask.priority,
        boat: periodicTask.boat,
        component: periodicTask.component,
      },
    },
    getLoading: false,
  })),

  on(PeriodicTasksActions.createSuccess, PeriodicTasksActions.updateSuccess, (state: PeriodicTaskState, { periodicTask }) => ({
    ...state,
    periodicTasks: {
      ...state.periodicTasks,
      [periodicTask._id]: {
        ...state.periodicTasks[periodicTask._id],
        _id: periodicTask._id,
        title: periodicTask.title,
        description: periodicTask.description,
        priority: periodicTask.priority,
        boat: periodicTask.boat,
        component: periodicTask.component,
      },
    },
    postLoading: false,
  })),

  on(PeriodicTasksActions.setGetLoading, (state: PeriodicTaskState, { getLoading }) => ({
    ...state,
    getLoading,
  })),

  on(PeriodicTasksActions.createTriggerSuccess, PeriodicTasksActions.updateTriggerSuccess, (state: PeriodicTaskState, { trigger }) => {
    let triggers: Pm8TriggerDto[] = [];

    const periodicTask = state.periodicTasks[trigger.task];

    if (periodicTask && periodicTask.triggers) triggers = [...periodicTask.triggers];

    const index = triggers.findIndex(t => t._id === trigger._id);
    if (index !== -1) triggers.splice(index, 1, trigger);
    else triggers = [...triggers, trigger];

    return {
      ...state,
      periodicTasks: {
        ...state.periodicTasks,
        [trigger.task]: {
          ...state.periodicTasks[trigger.task],
          triggers,
        },
      },
      postLoading: false,
    };
  }),

  on(PeriodicTasksActions.removeTriggerSuccess, (state: PeriodicTaskState, { id, periodicTaskId }) => ({
    ...state,
    periodicTasks: {
      ...state.periodicTasks,
      [periodicTaskId]: {
        ...state.periodicTasks[periodicTaskId],
        triggers: state.periodicTasks[periodicTaskId].triggers?.filter(t => t._id !== id),
      },
    },
    postLoading: false,
  })),

  on(PeriodicTasksActions.updateMeterCurrentValue, (state: PeriodicTaskState, { id, currentValue, lastReadDate }) => {
    const periodicTask = Object.values(state.periodicTasks).find(p => !!p.triggers.find(t => t.meter && t.meter._id === id));
    if (periodicTask) {
      const triggers = [...state.periodicTasks[periodicTask._id].triggers];
      return {
        ...state,
        periodicTasks: {
          ...state.periodicTasks,
          [periodicTask._id]: {
            ...state.periodicTasks[periodicTask._id],
            triggers: triggers.map(trigger => {
              if (trigger.meter && trigger.meter._id === id) {
                return { ...trigger, meter: { ...trigger.meter, currentValue, lastReadDate } };
              }
              return trigger;
            }),
          },
        },
      };
    } else {
      return state;
    }
  }),
);
