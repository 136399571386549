/**
 * PlanM8 staging API
 * PlanM8 API description
 *
 * The version of the OpenAPI document: v0.0.813
 * Contact: contact@coara.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ResourceDto } from '../model/models';
import { UpdateResourceDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    ResourceServiceInterface,
    ResourceControllerCreateRequestParams,
    ResourceControllerCreateFromExistingRequestParams,
    ResourceControllerFindAllRequestParams,
    ResourceControllerFindOneRequestParams,
    ResourceControllerRemoveRequestParams,
    ResourceControllerResolveOneRequestParams,
    ResourceControllerUpdateRequestParams,
    ResourceControllerUpdateAllRequestParams
} from './resource.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class ResourceService implements ResourceServiceInterface {

    protected basePath = 'https://apistaging.planm8.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Creates a new resource from uploaded file
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerCreate(requestParameters: ResourceControllerCreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResourceDto>;
    public resourceControllerCreate(requestParameters: ResourceControllerCreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResourceDto>>;
    public resourceControllerCreate(requestParameters: ResourceControllerCreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResourceDto>>;
    public resourceControllerCreate(requestParameters: ResourceControllerCreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const file = requestParameters.file;
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling resourceControllerCreate.');
        }
        const resourceType = requestParameters.resourceType;
        if (resourceType === null || resourceType === undefined) {
            throw new Error('Required parameter resourceType was null or undefined when calling resourceControllerCreate.');
        }
        const tags = requestParameters.tags;
        if (tags === null || tags === undefined) {
            throw new Error('Required parameter tags was null or undefined when calling resourceControllerCreate.');
        }
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerCreate.');
        }
        const metadata = requestParameters.metadata;
        const name = requestParameters.name;
        const referenceId = requestParameters.referenceId;
        const referenceType = requestParameters.referenceType;

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (file) {
            if (useForm) {
                file.forEach((element) => {
                    formParams = formParams.append('file', <any>element) as any || formParams;
            })
            } else {
                formParams = formParams.append('file', file.join(COLLECTION_FORMATS['csv'])) as any || formParams;
            }
        }
        if (metadata !== undefined) {
            formParams = formParams.append('metadata', <any>metadata) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('name', <any>name) as any || formParams;
        }
        if (resourceType !== undefined) {
            formParams = formParams.append('resourceType', <any>resourceType) as any || formParams;
        }
        if (referenceId !== undefined) {
            formParams = formParams.append('referenceId', <any>referenceId) as any || formParams;
        }
        if (referenceType !== undefined) {
            formParams = formParams.append('referenceType', <any>referenceType) as any || formParams;
        }
        if (tags) {
            if (useForm) {
                tags.forEach((element) => {
                    formParams = formParams.append('tags', <any>element) as any || formParams;
            })
            } else {
                formParams = formParams.append('tags', tags.join(COLLECTION_FORMATS['csv'])) as any || formParams;
            }
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResourceDto>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new resource from an already existing resource
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerCreateFromExisting(requestParameters: ResourceControllerCreateFromExistingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResourceDto>;
    public resourceControllerCreateFromExisting(requestParameters: ResourceControllerCreateFromExistingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResourceDto>>;
    public resourceControllerCreateFromExisting(requestParameters: ResourceControllerCreateFromExistingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResourceDto>>;
    public resourceControllerCreateFromExisting(requestParameters: ResourceControllerCreateFromExistingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const tags = requestParameters.tags;
        if (tags === null || tags === undefined) {
            throw new Error('Required parameter tags was null or undefined when calling resourceControllerCreateFromExisting.');
        }
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerCreateFromExisting.');
        }
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling resourceControllerCreateFromExisting.');
        }
        const metadata = requestParameters.metadata;
        const name = requestParameters.name;
        const referenceId = requestParameters.referenceId;
        const referenceType = requestParameters.referenceType;

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (metadata !== undefined) {
            formParams = formParams.append('metadata', <any>metadata) as any || formParams;
        }
        if (name !== undefined) {
            formParams = formParams.append('name', <any>name) as any || formParams;
        }
        if (referenceId !== undefined) {
            formParams = formParams.append('referenceId', <any>referenceId) as any || formParams;
        }
        if (referenceType !== undefined) {
            formParams = formParams.append('referenceType', <any>referenceType) as any || formParams;
        }
        if (tags) {
            if (useForm) {
                tags.forEach((element) => {
                    formParams = formParams.append('tags', <any>element) as any || formParams;
            })
            } else {
                formParams = formParams.append('tags', tags.join(COLLECTION_FORMATS['csv'])) as any || formParams;
            }
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResourceDto>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource/${encodeURIComponent(String(resource))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves all resources that matches the specified conditions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerFindAll(requestParameters: ResourceControllerFindAllRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ResourceDto>>;
    public resourceControllerFindAll(requestParameters: ResourceControllerFindAllRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ResourceDto>>>;
    public resourceControllerFindAll(requestParameters: ResourceControllerFindAllRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ResourceDto>>>;
    public resourceControllerFindAll(requestParameters: ResourceControllerFindAllRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerFindAll.');
        }
        const tags = requestParameters.tags;
        const reference = requestParameters.reference;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (tags) {
            tags.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'tags');
            })
        }
        if (reference !== undefined && reference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reference, 'reference');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<ResourceDto>>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves information related to the specified resource by ID
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerFindOne(requestParameters: ResourceControllerFindOneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResourceDto>;
    public resourceControllerFindOne(requestParameters: ResourceControllerFindOneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResourceDto>>;
    public resourceControllerFindOne(requestParameters: ResourceControllerFindOneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResourceDto>>;
    public resourceControllerFindOne(requestParameters: ResourceControllerFindOneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerFindOne.');
        }
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling resourceControllerFindOne.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResourceDto>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource/${encodeURIComponent(String(resource))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes the resource association
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerRemove(requestParameters: ResourceControllerRemoveRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public resourceControllerRemove(requestParameters: ResourceControllerRemoveRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public resourceControllerRemove(requestParameters: ResourceControllerRemoveRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public resourceControllerRemove(requestParameters: ResourceControllerRemoveRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerRemove.');
        }
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling resourceControllerRemove.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource/${encodeURIComponent(String(resource))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a binary buffer for the requested resource
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerResolveOne(requestParameters: ResourceControllerResolveOneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public resourceControllerResolveOne(requestParameters: ResourceControllerResolveOneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public resourceControllerResolveOne(requestParameters: ResourceControllerResolveOneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public resourceControllerResolveOne(requestParameters: ResourceControllerResolveOneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerResolveOne.');
        }
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling resourceControllerResolveOne.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource/${encodeURIComponent(String(resource))}/resolve`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the requested resource by ID
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerUpdate(requestParameters: ResourceControllerUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResourceDto>;
    public resourceControllerUpdate(requestParameters: ResourceControllerUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResourceDto>>;
    public resourceControllerUpdate(requestParameters: ResourceControllerUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResourceDto>>;
    public resourceControllerUpdate(requestParameters: ResourceControllerUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateResourceDto = requestParameters.updateResourceDto;
        if (updateResourceDto === null || updateResourceDto === undefined) {
            throw new Error('Required parameter updateResourceDto was null or undefined when calling resourceControllerUpdate.');
        }
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerUpdate.');
        }
        const resource = requestParameters.resource;
        if (resource === null || resource === undefined) {
            throw new Error('Required parameter resource was null or undefined when calling resourceControllerUpdate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<ResourceDto>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource/${encodeURIComponent(String(resource))}`,
            updateResourceDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates all resources matching specified conditions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceControllerUpdateAll(requestParameters: ResourceControllerUpdateAllRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ResourceDto>>;
    public resourceControllerUpdateAll(requestParameters: ResourceControllerUpdateAllRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ResourceDto>>>;
    public resourceControllerUpdateAll(requestParameters: ResourceControllerUpdateAllRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ResourceDto>>>;
    public resourceControllerUpdateAll(requestParameters: ResourceControllerUpdateAllRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateResourceDto = requestParameters.updateResourceDto;
        if (updateResourceDto === null || updateResourceDto === undefined) {
            throw new Error('Required parameter updateResourceDto was null or undefined when calling resourceControllerUpdateAll.');
        }
        const boat = requestParameters.boat;
        if (boat === null || boat === undefined) {
            throw new Error('Required parameter boat was null or undefined when calling resourceControllerUpdateAll.');
        }
        const tags = requestParameters.tags;
        const reference = requestParameters.reference;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (tags) {
            tags.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'tags');
            })
        }
        if (reference !== undefined && reference !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reference, 'reference');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<Array<ResourceDto>>(`${this.configuration.basePath}/api/v1/boats/${encodeURIComponent(String(boat))}/resource`,
            updateResourceDto,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
