import { createAction, props } from "@ngrx/store";
import { CreatePlannedTaskDto, CreateUpmDto, PostponeTaskDto, UpdatePlannedTaskDto } from "src/app/services/api";
import { Pm8TriggerDto } from "src/app/store/periodic-task/state";
import { Pm8PeriodicTaskDto } from "src/app/store/periodic-task/state";

export const create = createAction(
  "[PeriodicTask] Create",
  props<{ componentId: string; periodicTask: CreatePlannedTaskDto; triggers: Pm8TriggerDto[] }>(),
);

export const createSuccess = createAction("[PeriodicTask] Create Success", props<{ periodicTask: Pm8PeriodicTaskDto }>());

export const createFailure = createAction("[PeriodicTask] Create Failure");

export const loadAllByComponent = createAction("[PeriodicTask] Load All By Component", props<{ componentId: string }>());

export const loadAllByComponentSuccess = createAction(
  "[PeriodicTask] Load All By Component Success",
  props<{ periodicTasks: Pm8PeriodicTaskDto[] }>(),
);

export const loadAllByComponentFailure = createAction("[PeriodicTask] Load All By Component Failure");

export const loadOneByComponent = createAction("[PeriodicTask] Load One By Component", props<{ id: string; componentId: string }>());

export const loadOneByComponentSuccess = createAction(
  "[PeriodicTask] Load One By Component Success",
  props<{ periodicTask: Pm8PeriodicTaskDto }>(),
);

export const loadOneByComponentFailure = createAction("[PeriodicTask] Load One By Component Failure");

export const update = createAction(
  "[PeriodicTask] Update",
  props<{
    id: string;
    componentId: string;
    updatePeriodicTaskDto: UpdatePlannedTaskDto;
    triggers: Pm8TriggerDto[];
  }>(),
);

export const updateSuccess = createAction("[PeriodicTask] Update Success", props<{ periodicTask: Pm8PeriodicTaskDto }>());

export const updateFailure = createAction("[PeriodicTask] Update Failure");

export const remove = createAction("[PeriodicTask] Remove", props<{ id: string; componentId: string }>());

export const removeSuccess = createAction("[PeriodicTask] Remove Success", props<{ id: string }>());

export const removeFailure = createAction("[PeriodicTask] Remove Failure");

export const loadAll = createAction("[PeriodicTask] Load All");

export const loadAllSuccess = createAction("[PeriodicTask] Load All Success", props<{ periodicTasks: Pm8PeriodicTaskDto[] }>());

export const loadAllFailure = createAction("[PeriodicTask] Load All Failure");

export const createUnplannedTask = createAction(
  "[PeriodicTask] Create Unplanned Task",
  props<{ componentId: string; createUpmDto: CreateUpmDto }>(),
);

export const createUnplannedTaskSuccess = createAction(
  "[PeriodicTask] Create Unplanned Task Success",
  props<{ createUpmDto: CreateUpmDto }>(),
);

export const createUnplannedTaskFailure = createAction("[PeriodicTask] Create Unplanned Task Failure");

export const execute = createAction("[PeriodicTask] Execute", props<{ componentId: string; id: string; notes: string }>());

export const executeSuccess = createAction("[PeriodicTask] Execute Success", props<{ id: string }>());

export const executeFailure = createAction("[PeriodicTask] Execute Failure");

export const setGetLoading = createAction("[PeriodicTask] Set Get Loading", props<{ getLoading: boolean }>());

export const createTriggerSuccess = createAction("[PeriodicTask] Create Trigger Success", props<{ trigger: Pm8TriggerDto }>());

export const createTriggerFailure = createAction("[PeriodicTask] Create Trigger Failure");

export const updateTriggerSuccess = createAction("[PeriodicTask] Update Trigger Success", props<{ trigger: Pm8TriggerDto }>());

export const updateTriggerFailure = createAction("[PeriodicTask] Update Trigger Failure");

export const removeTrigger = createAction(
  "[PeriodicTask] Remove Trigger",
  props<{ id: string; periodicTaskId: string; componentId: string }>(),
);

export const removeTriggerSuccess = createAction("[PeriodicTask] Remove Trigger Success", props<{ id: string; periodicTaskId: string }>());

export const removeTriggerFailure = createAction("[PeriodicTask] Remove Trigger Failure");

export const updateMeterCurrentValue = createAction(
  "[PeriodicTask] Update Meter Current Value",
  props<{ id: string; currentValue: number; lastReadDate: string }>(),
);

export const postpone = createAction(
  "[PeriodicTask] Postpone",
  props<{ componentId: string; periodicTaskId: string; triggerId: string; postponeTask: PostponeTaskDto }>(),
);

export const postponeSuccess = createAction("[PeriodicTask] Postpone Success", props<{ componentId: string }>());

export const postponeFailure = createAction("[PeriodicTask] Postpone Failure");
