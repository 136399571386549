import { Action, ActionReducer, INIT } from "@ngrx/store";
import { initialRootState } from "..";
import * as UserActions from "./actions";

export function logoutMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action) => {
    if (action.type === UserActions.logoutSuccess.type) {
      const {} = initialRootState;
      return reducer(
        { ...initialRootState, app: { ...initialRootState.app, helpSlidersShowed: state["app"]["helpSlidersShowed"] } } as any,
        { type: INIT },
      );
    }

    return reducer(state, action);
  };
}
