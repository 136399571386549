import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { HideForRolesBelowDirective } from "./hidde-for-roles-below.directive";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [HideForRolesBelowDirective],
  exports: [HideForRolesBelowDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HideForRolesBelowDirectiveModule {}
