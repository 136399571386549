import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { environment as production } from "src/environments/environment.production";
import { environment as staging } from "src/environments/environment.staging";
import { environment as qa } from "src/environments/environment.qa";
import { environment as local } from "src/environments/environment";
import { environment as deploy, EnvironmentDeploy } from "src/environments/environment.deploy";
import { BehaviorSubject } from "rxjs";
import { EnvironmentName, Environment as EnvironmentInterface, Merge } from "src/app/interfaces/global.interface";

export type EnvironmentVariable = "release" | "name" | "api";

export type Environment = Merge<EnvironmentInterface, EnvironmentDeploy>;

const ENVIRONMENT_NAME = "environmentName";

const environments: { [key: string]: Environment } = {
  local: { ...local, ...deploy },
  qa: { ...qa, ...deploy },
  staging: { ...staging, ...deploy },
  production: { ...production, ...deploy },
};

@Injectable({ providedIn: "root" })
export class EnvService {
  private selectedEnvironmentName: EnvironmentName;
  private environment$: BehaviorSubject<Environment>;

  constructor() {
    this.selectedEnvironmentName = (localStorage.getItem(ENVIRONMENT_NAME) as EnvironmentName) || environment.name;
    this.environment$ = new BehaviorSubject<Environment>(environments[this.selectedEnvironmentName]);
  }

  public get(): BehaviorSubject<Environment> {
    return this.environment$;
  }

  public set(value: EnvironmentName): void {
    this.selectedEnvironmentName = value;
    localStorage.setItem(ENVIRONMENT_NAME, value);
    this.environment$.next(environments[value]);
    window.location.reload();
  }

  public getVariable<T>(variable: EnvironmentVariable): T {
    return (environments[this.selectedEnvironmentName][variable] as unknown) as T;
  }
}
