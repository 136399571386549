import { Injectable } from "@angular/core";
import { AlertController, NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { SharedRoutes } from "src/app/constants";
import { CallbackService, ModelError, UserDto, UserService } from "src/app/services/api";
import { AuthService } from "src/app/services/api/api/auth.service";
import * as UserActions from "src/app/store/user/actions";
import * as AppActions from "src/app/store/app/actions";
import * as FromUser from "src/app/store/user/selectors";
import { Store } from "@ngrx/store";
import { RootState } from "..";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { UtilsService } from "src/app/services/utils/utils.service";
import { PosthogService } from "src/app/services/posthog/posthog.service";
import { CrispService } from "src/app/services/crisp/crisp.service";

@Injectable()
export class UserEffects {
  public routes: typeof SharedRoutes = SharedRoutes;

  public login$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      mergeMap(action =>
        this.authService.authControllerLogin({ loginDto: action.payload }).pipe(
          mergeMap(tokens => [UserActions.loginSuccess({ tokens }), UserActions.loadUser()]),
          tap(() => this.navController.navigateRoot([this.routes.planning])),
          catchError((err: HttpErrorResponse) => {
            if (err.error.i18n === ModelError.I18nEnum.AuthnotActiveOrBlocked) {
              this.store.dispatch(UserActions.sendActivationEmail({ email: action.payload.email }));
            }
            return of(UserActions.loginFailure({ error: err.error.i18n || err.error.message.toString() }));
          }),
        ),
      ),
    ),
  );

  public loginSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        tap(() => this.posthogService.sendEvent("login")),
      ),
    { dispatch: false },
  );

  public register$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.register),
      mergeMap(({ code, password, email, origin, name, phone }) => {
        const browserLanguage = this.translate.getBrowserLang() || navigator.language;
        const matchedLang = Object.entries(UserDto.LanguageEnum).find(([, value]) => value.toString() === browserLanguage);
        const [, language] = matchedLang || [, UserDto.LanguageEnum.En];
        const registerCallback = code
          ? this.callbackService.callbacksControllerAcceptInvitation({ code, registerFromInvitationDto: { language, password } })
          : this.authService.authControllerRegister({ registerDto: { email, password, language, name } });
        return registerCallback.pipe(
          map(() => UserActions.registerSuccess({ email, origin, name, phone, language })),
          tap(() => this.showSuccessSignUpProcess(email)),
          catchError((error: HttpErrorResponse) =>
            of(UserActions.registerFailure({ error: error.error.i18n || error.error.message.toString() })),
          ),
        );
      }),
    ),
  );

  public registerSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.registerSuccess),
        tap(async ({ email, name, origin, language, phone }) => {
          this.posthogService.sendEvent("register", { email, leadOrigin: origin });
          this.posthogService.identifyWithEmail(email, origin);
          await this.http
            .post(
              "https://hook.eu1.make.com/m6fsygdg7eio7ienxsfba8ewg5c5l9z7",
              {
                email,
                name,
                language,
                phone,
                leadOrigin: origin,
                whereWork: "Unknown",
              },
              { responseType: "text" },
            )
            .toPromise()
            .catch();
        }),
      ),
    { dispatch: false },
  );

  public refresh$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.refresh),
      withLatestFrom(this.store.select(FromUser.selectRefreshToken)),
      mergeMap(([, refreshToken]) =>
        this.authService.authControllerRefresh({ refreshDto: { refreshToken } }).pipe(
          map(tokens => UserActions.refreshSuccess({ tokens })),
          catchError(() => of(UserActions.refreshFailure())),
        ),
      ),
    ),
  );

  public autoLogin$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.autoLogin),
      mergeMap(({ refreshToken }) =>
        this.utilsService.removeFolder().pipe(
          mergeMap(() => {
            localStorage.clear();
            return this.authService.authControllerRefresh({ refreshDto: { refreshToken } }).pipe(
              switchMap(tokens => [UserActions.refreshSuccess({ tokens }), UserActions.loadUser(), UserActions.autoLoginSuccess()]),
              tap(() => this.navController.navigateRoot([this.routes.planning])),
              catchError(() => of(UserActions.autoLoginFailure())),
            );
          }),
          catchError(() => of(UserActions.autoLoginFailure())),
        ),
      ),
    ),
  );

  public autoLoginSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.autoLoginSuccess),
        tap(() => this.posthogService.sendEvent("login")),
      ),
    { dispatch: false },
  );

  public loadUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      mergeMap(() =>
        this.userService.usersControllerGetAuthenticatedUser().pipe(
          mergeMap(user => [UserActions.loadUserSuccess({ user }), AppActions.changeLanguage({ language: user.language })]),
          catchError(() => of(UserActions.loadUserFailure())),
        ),
      ),
    ),
  );

  public updateUser$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      withLatestFrom(this.store.select(FromUser.selectUser)),
      mergeMap(([action, user]) =>
        this.userService.usersControllerUpdate({ user: user._id, updateUserDto: { ...user, ...action.user } }).pipe(
          map(updatedUser => UserActions.updateUserSuccess({ user: { ...user, ...updatedUser } })),
          catchError(() => of(UserActions.updateUserFailure())),
        ),
      ),
    ),
  );

  public changeUserPassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changeUserPassword),
      withLatestFrom(this.store.select(FromUser.selectUser)),
      mergeMap(([action, user]) =>
        this.authService.authControllerPassword({ auth: user._id, updatePasswordDto: { ...action.payload } }).pipe(
          map(() => UserActions.changeUserPasswordSuccess()),
          tap(() => this.navController.navigateBack([this.routes.settings, this.routes.profile])),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 409) this.showInvalidPasswordAlert();
            return of(UserActions.changeUserPasswordFailure());
          }),
        ),
      ),
    ),
  );

  public requestRecoverPassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.requestRecoverPasswordLink),
      mergeMap(action =>
        this.authService.authControllerSendRecoveryRequest({ recoveryPasswordRequestDto: { email: action.email } }).pipe(
          map(() => UserActions.requestRecoverPasswordLinkSuccess()),
          tap(() => this.showSuccessRecoverLinkRequestedAlert(action.email)),
          catchError(() => of(UserActions.requestRecoverPasswordLinkFailure({ error: "requestRecoverLinkError" }))),
        ),
      ),
    ),
  );

  public recoverPassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.recoverPassword),
      mergeMap(action =>
        this.callbackService
          .callbacksControllerPasswordRecoveryFromCode({
            code: action.code,
            recoveryPasswordDto: { password: action.password },
          })
          .pipe(
            map(() => UserActions.recoverPasswordSuccess()),
            tap(() => this.showSuccessRecoverPasswordAlert()),
            catchError(() => of(UserActions.recoverPasswordFailure({ error: "recoverError" }))),
          ),
      ),
    ),
  );

  public logout$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      mergeMap(({ shouldNavigate }) =>
        this.utilsService.removeFolder().pipe(
          tap(() => {
            this.posthogService.logOut();
            this.crispService.logout();
            localStorage.clear();
            if (shouldNavigate) this.navController.navigateForward([this.routes.login]);
          }),
          map(() => UserActions.logoutSuccess()),
        ),
      ),
    ),
  );

  public sendActivationEmail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.sendActivationEmail),
      mergeMap(({ email }) =>
        this.authService.authControllerSendActivationEmail({ activationEmailRequestDto: { email } }).pipe(
          map(() => UserActions.sendActivationEmailSuccess()),
          catchError(() => of(UserActions.sendActivationEmailFailure())),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly navController: NavController,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly callbackService: CallbackService,
    private readonly alertController: AlertController,
    private readonly translate: TranslateService,
    private readonly utilsService: UtilsService,
    private readonly posthogService: PosthogService,
    private readonly crispService: CrispService,
    private readonly http: HttpClient,
  ) {}

  private async showInvalidPasswordAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant("CHANGE_PASSWORD.invalidPassword"),
      message: this.translate.instant("CHANGE_PASSWORD.invalidPasswordExplain"),
      buttons: [{ text: this.translate.instant("CHANGE_PASSWORD.understood") }],
      backdropDismiss: false,
    });

    await alert.present();
  }

  private async showSuccessRecoverLinkRequestedAlert(email: string): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant("FORGOT_PASSWORD.recoverLinkRequested"),
      message: this.translate.instant("FORGOT_PASSWORD.recoverLinkRequestedMessage"),
      buttons: [
        {
          text: this.translate.instant("FORGOT_PASSWORD.understood"),
          handler: () => this.navController.navigateRoot([this.routes.login], { queryParams: { email } }),
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  private async showSuccessRecoverPasswordAlert(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant("FORGOT_PASSWORD.recoverPasswordTitle"),
      message: this.translate.instant("FORGOT_PASSWORD.recoverPasswordMessage"),
      buttons: [
        {
          text: this.translate.instant("FORGOT_PASSWORD.understood"),
          handler: () => this.navController.navigateRoot([this.routes.login]),
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  private async showSuccessSignUpProcess(email: string): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant("SIGN_UP.accountCreated"),
      message: this.translate.instant("SIGN_UP.reviseYourEmail"),
      buttons: [
        {
          text: this.translate.instant("SIGN_UP.understood"),
          handler: () => this.navController.navigateRoot([this.routes.login], { queryParams: { email } }),
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }
}
