import { createReducer, on } from "@ngrx/store";
import { DEFAULT_FILTERS, initialState } from "./state";
import * as FilterActions from "./actions";
import { AreArrayEqualsPipe } from "src/app/pipes/filter/are-array-equals/are-array-equals.pipe";

const areArrayEqual = new AreArrayEqualsPipe();

export const filterReducer = createReducer(
  initialState,

  on(FilterActions.resetFilters, state => ({
    ...state,
    filters: DEFAULT_FILTERS,
  })),

  on(FilterActions.resetFilter, (state, { page }) => ({
    ...state,
    filters: {
      ...state.filters,
      [page]: DEFAULT_FILTERS[page],
    },
  })),

  on(FilterActions.setFilter, (state, { page, filter }) => ({
    ...state,
    filters: {
      ...state.filters,
      [page]: {
        systems: filter.systems || state.filters[page].systems,
        types: filter.types || state.filters[page].types,
        sort: filter.sort || state.filters[page].sort,
        filtersChanged: !areArrayEqual.transform(initialState.filters[page].systems, filter.systems || state.filters[page].systems),
      },
    },
  })),
);
