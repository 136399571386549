import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, delay, filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { ComponentDto, ComponentService, SearchService } from "src/app/services/api";
import { RootState } from "..";
import * as ComponentActions from "./actions";
import * as FromBoat from "src/app/store/boat/selectors";
import * as FromComponent from "src/app/store/component/selectors";
import { ModalController, NavController } from "@ionic/angular";
import { SharedRoutes } from "src/app/constants";
import { HttpErrorResponse } from "@angular/common/http";
import { PosthogService } from "src/app/services/posthog/posthog.service";

@Injectable()
export class ComponentEffects {
  public routes: typeof SharedRoutes = SharedRoutes;

  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentActions.loadAll),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([, boatId]) =>
        this.componentService.componentControllerFindAll({ boat: boatId }).pipe(
          map(components => ComponentActions.loadAllSuccess({ components })),
          catchError(() => of(ComponentActions.loadAllFailure())),
        ),
      ),
    ),
  );

  public loadOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentActions.loadOne),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ id }, boatId]) =>
        this.componentService.componentControllerFindOne({ boat: boatId, component: id }).pipe(
          map(component => ComponentActions.loadOneSuccess({ component })),
          catchError(() => of(ComponentActions.loadOneFailure())),
        ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentActions.create),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.componentService.componentControllerCreate({ boat: boatId, createComponentDto: action.component }).pipe(
          map(component => ComponentActions.createSuccess({ component })),
          tap(() => this.modalController.dismiss()),
          catchError(() => of(ComponentActions.createFailure())),
        ),
      ),
    ),
  );

  public createSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComponentActions.createSuccess),
        withLatestFrom(this.store.select(FromBoat.selectBoatId)),
        tap(([{ component: { system } }]) =>
          this.posthogService.sendEvent("createResource", { type: "createComponent", system, forked: false }),
        ),
      ),
    { dispatch: false },
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentActions.update),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.componentService.componentControllerUpdate({ boat: boatId, component: action.id, updateComponentDto: action.component }).pipe(
          map(component => ComponentActions.updateSuccess({ component })),
          tap(() => this.modalController.dismiss()),
          catchError(() => of(ComponentActions.updateFailure())),
        ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentActions.remove),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ id }, boatId]) =>
        this.componentService.componentControllerRemove({ boat: boatId, component: id }).pipe(
          map(() => ComponentActions.removeSuccess({ id })),
          tap(() => this.navController.navigateBack([this.routes.components])),
          catchError(() => of(ComponentActions.createFailure())),
        ),
      ),
    ),
  );

  public fork$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentActions.fork),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ boatId, componentId, forkComponent }, boat]) =>
        this.componentService
          .componentControllerFork({
            boat: boatId,
            component: componentId,
            forkComponentDto: {
              ...forkComponent,
              boat,
            },
          })
          .pipe(
            map(component => ComponentActions.forkSuccess({ component })),
            catchError((err: HttpErrorResponse) => of(ComponentActions.forkFailure({ error: err.error.i18n }))),
          ),
      ),
    ),
  );

  public forkSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComponentActions.forkSuccess),
        tap(({ component: { system } }) =>
          this.posthogService.sendEvent("createResource", { type: "createComponent", system, forked: true }),
        ),
      ),
    { dispatch: false },
  );

  public duplicate$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComponentActions.duplicate),
        withLatestFrom(this.store.select(FromBoat.selectBoatId)),
        mergeMap(([{ componentId, forkComponent }, boatId]) =>
          this.componentService.componentControllerFork({ boat: boatId, component: componentId, forkComponentDto: forkComponent }).pipe(
            mergeMap(() =>
              this.componentService.componentControllerFindOne({ boat: boatId, component: componentId }).pipe(
                delay(3000),
                map(() => this.store.dispatch(ComponentActions.duplicateSuccess({ component: forkComponent as ComponentDto }))),
                catchError(() => {
                  this.store.dispatch(ComponentActions.loadAllFailure());
                  return of();
                }),
              ),
            ),
            catchError((err: HttpErrorResponse) => {
              this.store.dispatch(ComponentActions.duplicateFailure({ error: err.error.i18n }));
              return of();
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  public search$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentActions.search),
      withLatestFrom(this.store.select(FromComponent.selectSearchHasNextPage)),
      withLatestFrom(this.store.select(FromComponent.selectSearchPage)),
      filter(([[, hasNextPage]]) => hasNextPage),
      mergeMap(([[{ limit, body, event }], page]) =>
        this.searchService.searchControllerSearch({ page, limit, domain: "COMPONENT", body }).pipe(
          map(result => {
            if (event) event.target.complete();
            return ComponentActions.searchSuccess({
              components: result.docs as ComponentDto[],
              searchHasNextPage: result["hasNextPage"],
              searchTotalDocs: result["totalPages"],
            });
          }),
          catchError(() => of(ComponentActions.searchFailure())),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly componentService: ComponentService,
    private readonly searchService: SearchService,
    private readonly store: Store<RootState>,
    private readonly navController: NavController,
    private readonly modalController: ModalController,
    private readonly posthogService: PosthogService,
  ) {}
}
