import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { MeterService } from "src/app/services/api";
import { RootState } from "..";
import * as MeterActions from "./actions";
import * as FromBoat from "src/app/store/boat/selectors";
import * as PeriodicTaskActions from "src/app/store/periodic-task/actions";
import { PosthogService } from "src/app/services/posthog/posthog.service";

@Injectable()
export class MeterEffects {
  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.loadAll),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([, boatId]) =>
        this.meterService.metersControllerFindAll({ boat: boatId }).pipe(
          map(meters => MeterActions.loadAllSuccess({ meters })),
          catchError(() => of(MeterActions.loadAllFailure())),
        ),
      ),
    ),
  );

  public loadOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.loadOne),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ id }, boatId]) =>
        this.meterService.metersControllerFindOne({ boat: boatId, meter: id }).pipe(
          map(meter => MeterActions.loadOneSuccess({ meter })),
          catchError(() => of(MeterActions.loadOneFailure())),
        ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.create),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.meterService.metersControllerCreate({ boat: boatId, createMeterDto: action.meter }).pipe(
          mergeMap(meter => [
            MeterActions.createSuccess({ meter }),
            MeterActions.updateCurrentValue({ id: meter._id, currentValue: action.meter.currentValue }),
          ]),
          catchError(() => of(MeterActions.createFailure())),
        ),
      ),
    ),
  );

  public createSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterActions.createSuccess),
        tap(({ meter: { system } }) => this.posthogService.sendEvent("createResource", { type: "meter", system })),
      ),
    { dispatch: false },
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.update),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.meterService.metersControllerUpdate({ boat: boatId, meter: action.id, updateMeterDto: action.meter }).pipe(
          mergeMap(meter => [
            MeterActions.updateSuccess({ meter }),
            MeterActions.updateCurrentValue({ id: meter._id, currentValue: action.meter.currentValue }),
          ]),
          catchError(() => of(MeterActions.updateFailure())),
        ),
      ),
    ),
  );

  public updateCurrentValue$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.updateCurrentValue),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([action, boatId]) =>
        this.meterService
          .metersControllerUpdateCurrentValue({
            boat: boatId,
            meter: action.id,
            updateMeterValueDto: { currentValue: action.currentValue, readDate: new Date().toISOString() },
          })
          .pipe(
            mergeMap(meter => [
              MeterActions.updateCurrentValueSuccess({ id: meter._id, currentValue: meter.currentValue, lastReadDate: meter.lastReadDate }),
              PeriodicTaskActions.updateMeterCurrentValue({
                id: action.id,
                currentValue: action.currentValue,
                lastReadDate: meter.lastReadDate,
              }),
            ]),
            catchError(() => of(MeterActions.updateCurrentValueFailure())),
          ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterActions.remove),
      withLatestFrom(this.store.select(FromBoat.selectBoatId)),
      mergeMap(([{ id }, boatId]) =>
        this.meterService.metersControllerRemove({ boat: boatId, meter: id }).pipe(
          map(() => MeterActions.removeSuccess({ id })),
          catchError(() => of(MeterActions.createFailure())),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly meterService: MeterService,
    private readonly store: Store<RootState>,
    private readonly posthogService: PosthogService,
  ) {}
}
