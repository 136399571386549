import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class SwUpdateService {
  constructor(private swUpdate: SwUpdate, private alert: AlertController, private t: TranslateService) {
    const versionUpdates$ = this.swUpdate.versionUpdates.subscribe((event) => {
      if (event.type === "VERSION_READY") {
        versionUpdates$.unsubscribe();
        this.updateToLatest();
      }
    });
  }

  public async checkForUpdate(): Promise<void> {
    if (this.swUpdate.isEnabled) {
      await this.swUpdate.checkForUpdate();
    }
  }

  private async updateToLatest(): Promise<void> {
    const alert = await this.alert.create({
      backdropDismiss: false,
      header: this.t.instant("ALERTS.update_modal_title"),
      message: this.t.instant("ALERTS.update_modal_message"),
      buttons: [
        {
          role: "cancel",
          text: this.t.instant("ALERTS.update_modal_no"),
        },
        {
          text: this.t.instant("ALERTS.update_modal_yes"),
          handler: async () => {
            await this.swUpdate.activateUpdate();
            document.location.reload();
          },
        },
      ],
    });
    await alert.present();
  }
}
