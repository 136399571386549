import { ComponentDto } from "src/app/services/api";

export const persistedKeys = ["components", "searchTerm"];

export interface Components {
  [id: string]: ComponentDto;
}

export interface ComponentState {
  components: Components;
  searchComponents: Components;
  searchTerm: string;
  searchPage: number;
  searchTotalDocs: number;
  searchHasNextPage: boolean;
  loading: boolean;
  forkError: string;
}

export const initialState: ComponentState = {
  components: {},
  searchComponents: {},
  searchTerm: "",
  searchPage: 1,
  searchTotalDocs: undefined,
  searchHasNextPage: true,
  loading: false,
  forkError: null,
};
