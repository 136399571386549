import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { ReleasifyPipe } from "./releasify.pipe";

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ReleasifyPipe],
  exports: [ReleasifyPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReleasifyPipeModule {}
